import React, { useCallback, useContext, useEffect } from 'react';
import { CircularProgress, Paper, Stack, Typography } from '@mui/material';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { useAdminStore, useShowModalOptions } from '../../../Store/Store';
import TableComp from '../../../TableComp/TableComp';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
    addCommentBeforeDeleteOrg,
    blockOrganization,
    unblockOrganization,
    getApprovedOrganizations,
} from '../../../../services/admin';
import CommentForm from './CommentForm';
import { approvedOrgTableInfo } from './approvedOrgTableInfo';
import { FeedbackDispatch } from '../../../Store/Feedback/FeedbackContext';
import ConfirmDelete from './ConfirmDelete';
import { deleteUserOrganization } from '../../../../services/organizations/organizations';

const UpdateIcon = org => {
    if (!org?.block) return <BlockIcon />;
    return <LockOpenIcon />;
};

const ApprovedOrganizationsTable = ({ handleGetUserOrg, isLoading }) => {
    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch();
    const { doFetch: blockOrg, error: blockError } = useFetch();
    const { doFetch: unblockOrg, error: unblockError } = useFetch();

    const dispatch = useContext(FeedbackDispatch);

    const showMessage = ({ msg, type }) => {
        dispatch({
            type: 'setMessage',
            message: { open: true, type, msg },
        });
    };

    const approvedOrganization = useAdminStore(state => state.approvedOrganization);
    const setApprovedOrganization = useAdminStore(state => state.setApprovedOrganization);
    const updateApprovedOrganization = useAdminStore(
        state => state.updateApprovedOrganization
    );

    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleClose = useCallback(() => {
        setShowModal({ show: false, Comp: '' });
    }, [setShowModal]);

    const handleDeleteOrganization = async ({ comment }, id) => {
        [
            {
                func: addCommentBeforeDeleteOrg,
                info: [access, { delete_commentt: comment }, id],
                id: 'comment',
            },
            { func: deleteUserOrganization, info: [access, id], id: 'delete' },
        ]
            .reduce((promiseChain, currentFunc) => {
                return promiseChain.then(() => {
                    const { func, info, id } = currentFunc;

                    return func(...info).then(res => {
                        if (!res.ok && id === 'delete') {
                            return Promise.reject(
                                'Došlo je do greške prilikom brisanja udruge'
                            );
                        }
                        return Promise.resolve();
                    });
                });
            }, Promise.resolve())
            .then(() => {
                return doFetch([
                    { func: getApprovedOrganizations, info: [access] },
                    { func: data => setApprovedOrganization(data) },
                    { func: () => handleGetUserOrg() },
                    { func: () => handleClose() },
                ]);
            })
            .catch(err => {
                showMessage({ msg: err, type: 'error' });
                handleClose();
            });
    };

    const handleBlockOrganization = (data, id) => {
        if (data?.comment) {
            return blockOrg([
                {
                    func: blockOrganization,
                    info: [
                        access,
                        { block: true, udruga: id, block_comment: data.comment },
                    ],
                },
                {
                    func: () => {
                        updateApprovedOrganization(id, true);
                        handleClose();
                        showMessage({
                            type: 'success',
                            msg: 'Uspješno ste blokirali udrugu.',
                        });
                    },
                },
            ]);
        }

        unblockOrg([
            {
                func: unblockOrganization,
                info: [access, { id }],
            },
            {
                func: () => {
                    updateApprovedOrganization(id, false);
                    handleClose();
                    showMessage({
                        type: 'success',
                        msg: 'Uspješno ste odblokirali udrugu.',
                    });
                },
            },
        ]);
    };

    const btnsArr = [
        {
            name: 'block',
            onClick: org => {
                return setShowModal({
                    show: true,
                    Comp: CommentForm,
                    dialogSize: 'xs',
                    info: {
                        handleSubmit: handleBlockOrganization,
                        submitParams: [org.id],
                        addForm: !org?.block,
                        title: `Jeste li sigurni da želite ${
                            org?.block ? 'odblokirati' : 'blokirati'
                        } udrugu ?`,
                    },
                });
            },
            IconComp: UpdateIcon,
        },
        {
            name: 'delete',
            onClick: org =>
                setShowModal({
                    show: true,
                    Comp: ConfirmDelete,
                    dialogSize: 'sm',
                    info: {
                        org,
                        handleSubmit: handleDeleteOrganization,
                        submitParams: [org.id],
                        title: 'Jeste li sigurni da želite obrisati udrugu ?',
                    },
                }),
            icon: <DeleteIcon color='error' />,
        },
    ];

    useEffect(() => {
        if (blockError.error || unblockError.error) {
            new Promise(res => setTimeout(res, 500)).then(() => handleClose());
        }
    }, [handleClose, blockError.error, unblockError.error]);

    if (isLoading) {
        return (
            <Stack direction='row' justifyContent='center' my={6}>
                <CircularProgress size={60} />
            </Stack>
        );
    }

    return approvedOrganization.length !== 0 ? (
        <Paper sx={{ margin: '1rem 3vw' }}>
            <TableComp
                head={approvedOrgTableInfo}
                body={approvedOrganization}
                btnsArr={btnsArr}
                btns
            />
        </Paper>
    ) : (
        <Typography variant='h5' align='center'>
            Nema odobrenih udruga
        </Typography>
    );
};

export default ApprovedOrganizationsTable;
