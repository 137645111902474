import { BASE_URL } from '../baseUrl';

export const editOrganizationAddress = (token, id, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_ORG_ADDRESS}${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};

export const editOrganizationContact = (token, id, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_ORG_CONTACT}${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};

export const editOrganizationInfo = (token, id, data, signal) => {
    let body = Object.keys(data).reduce((formData, key) => {
        formData.append(key, data[key]);
        return formData;
    }, new FormData());

    return fetch(`${BASE_URL}/udruga/update-info/${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            /* 'Content-Type': 'application/json', */
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body,
        signal,
    });
};
