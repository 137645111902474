export const memberRankRules = [
    {
        name: 'cinovi_pocetak',
        label: 'Početak',
        input: 'date',
        defaultValue: 'cinovi_pocetak',
    },
    {
        name: 'cinovi_kraj',
        label: 'Kraj',
        input: 'date',
        defaultValue: 'cinovi_kraj',
    },
];
