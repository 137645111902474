import React, { useState } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import LinkButton from '../../../../FormComp/LinkButton';
import TableComp from '../../../../TableComp/TableComp';
import DeleteForever from '@mui/icons-material/DeleteForever';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const UploadFileAddForm = ({ options: { handlePrevStep, handleNext, finalData } }) => {
    const [selectedFile, setSelectedFile] = useState(finalData.selectedFile || []);

    const handleChange = ({ target }) => {
        setSelectedFile([...selectedFile, ...target.files]);
    };

    const btnsArr = [
        {
            name: 'obriši',
            onClick: file =>
                setSelectedFile(prevFiles =>
                    prevFiles.filter(
                        ({ name, size }) => name !== file.name && size !== file.size
                    )
                ),
            icon: <DeleteForever />,
        },
    ];

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '1rem',
                    margin: '2rem 0',
                }}
            >
                <Button variant='outlined' component='label'>
                    <Typography variant='body2' mr={1}>
                        Dodaj datoteku
                    </Typography>
                    <UploadFileIcon />
                    <input
                        accept='.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        multiple
                        onChange={handleChange}
                        type='file'
                        hidden
                    />
                </Button>
            </div>
            {selectedFile.length !== 0 && (
                <TableComp
                    head={[
                        { id: 'name', label: 'Ime datoteke', type: 'string' },
                        { id: 'obriši', label: 'Obriši', btn: true },
                    ]}
                    body={selectedFile}
                    btns
                    btnsArr={btnsArr}
                />
            )}
            <Divider />
            <div
                style={{
                    margin: '1rem',
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <LinkButton linkFuncGoBack btnMessage='Odustani' />
                <Button color='error' onClick={handlePrevStep} variant='outlined'>
                    Natrag
                </Button>
                <Button onClick={() => handleNext({ selectedFile })} variant='contained'>
                    Dalje
                </Button>
            </div>
        </>
    );
};

export default UploadFileAddForm;
