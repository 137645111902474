import { assemblyStatusType } from '../../../../../helpers/options';

export const assemblyEditRules = [
    {
        name: 'naziv',
        label: 'Naziv skupštine',
        type: 'text',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 240,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'naziv',
    },
    {
        name: 'status',
        label: 'Status',
        input: 'select',
        data: {
            data: assemblyStatusType,
            value: 'pk',
            label: 'naziv',
        },
        rules: { required: 'Ovo polje je obavezno' },
        defaultValue: 'status',
    },
    {
        name: 'datum_odrzavanja',
        label: 'Datum održavanja',
        input: 'date',
        defaultValue: 'datum_odrzavanja',
    },
];
