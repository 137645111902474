const formatDateDmy = (date, time = false) => {
    if (!date) return null;
    if (date.toString().length === 10 || date instanceof Date) {
        function pad(s) {
            return s < 10 ? '0' + s : s;
        }

        let d = new Date(date);
        let timeStr = '';
        let hours = (d.getHours() < 10 ? '0' : '') + d.getHours();
        let mins = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        //let secs = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();

        if (time) {
            timeStr = `, ${[hours, mins].join(':')}`;
        }

        return (
            [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/') + timeStr
        );
    } else {
        return date;
    }
};

export default formatDateDmy;
