export const editMemberRules = [
    {
        name: 'ime',
        label: 'Ime',
        rules: {
            maxLength: {
                value: 150,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'ime',
    },
    {
        name: 'prezime',
        label: 'Prezime',
        rules: {
            maxLength: {
                value: 50,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'prezime',
    },
    {
        name: 'oib',
        label: 'OIB člana',
        rules: {
            maxLength: {
                value: 11,
            },
            minLength: {
                value: 11,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'oib',
    },
    {
        name: 'broj_putovnice',
        label: 'Broj putovnice',
        rules: {
            maxLength: {
                value: 30,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'broj_putovnice',
    },
    {
        name: 'datum_rodenja',
        label: 'Datum rođenja',
        input: 'date',
        rules: {},
        defaultValue: 'datum_rodenja',
    },
    {
        label: 'Spol',
        name: 'spol',
        input: 'select',
        data: {
            data: [
                { pk: 'm', naziv: 'Muško' },
                { pk: 'ž', naziv: 'Žensko' },
            ],
            value: 'pk',
            label: 'naziv',
        },
        defaultValue: 'spol',
    },
    {
        name: 'grupna_obavijest',
        label: 'Grupno obavještavanje',
        input: 'checkbox',
        defaultValue: 'grupna_obavijest',
    },
    {
        name: 'napomena',
        label: 'Napomena',
        rules: {
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'napomena',
    },
];
