export const updateMemberRanks = (newRanks, memberId, members) => {
    const memberIndex = members.findIndex(m => +m.id === +memberId);
    const cinovi = newRanks.map(r => r.cinovi);

    if (memberIndex !== -1) {
        members[memberIndex] = { ...members[memberIndex], cinovi };
    }

    return members;
};
