import React, { useEffect } from 'react';
import { Autocomplete, Divider, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMembersStore, useOrganizationStore } from '../../Store/Store';
import { filterFieldArr } from './filterFieldArr';

const FilterMembers = ({ filterBy, setFilterBy }) => {
    const setMembers = useMembersStore(state => state.setMembers);
    const membersCopy = useMembersStore(state => state.membersCopy);

    const currentOrganization = useOrganizationStore(state => state.currentOrganization);

    const handleChange = (id, value, input) => {
        setFilterBy(prevState => ({ ...prevState, [id]: { value, input } }));
    };

    useEffect(() => {
        if (Object.keys(filterBy).length !== 0) {
            setMembers(
                Object.keys(filterBy).reduce((prev, curr) => {
                    return prev.filter(m => {
                        if (
                            typeof m[curr] === 'object' &&
                            Object.keys(m[curr]).length !== 0
                        ) {
                            return m[curr][Object.keys(m[curr])[0]].includes(
                                filterBy[curr].value
                            );
                        }

                        if (typeof m[curr] === 'string') {
                            if (typeof filterBy[curr].value !== 'string') return null;

                            let memberData = m[curr].toLowerCase();
                            let filter = filterBy[curr].value.toLowerCase();

                            if (filterBy[curr].input === 'select') {
                                memberData = m[curr];
                                filter = filterBy[curr].value;
                            }

                            return memberData.includes(filter);
                        } else if (typeof m[curr] === 'boolean') {
                            if (filterBy[curr].value === '') return m;
                            return m[curr] === filterBy[curr].value;
                        }
                        return null;
                    });
                }, membersCopy)
            );
        }
    }, [filterBy, membersCopy, setMembers]);

    return (
        <>
            <Divider style={{ width: '100%' }} />
            <div
                style={{
                    display: 'grid',
                    gridTemplateRows: '1fr 1fr',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gap: '1rem',
                    padding: '1rem',
                    width: '100%',
                    backgroundColor: grey[50],
                }}
            >
                {(currentOrganization.ljecnicki_pregled
                    ? [
                          ...filterFieldArr.slice(0, 8),
                          {
                              label: 'Liječnički pregled',
                              name: 'medical_status',
                              input: 'select',
                              data: [
                                  { value: true, label: 'Da' },
                                  { value: false, label: 'Ne' },
                              ],
                          },
                          ...filterFieldArr.slice(8),
                      ]
                    : filterFieldArr
                ).map(f => {
                    return (
                        <React.Fragment key={f.name}>
                            {f.input === 'select' && (
                                <Autocomplete
                                    size='small'
                                    options={f.data}
                                    renderInput={params => (
                                        <TextField {...params} label={f.label} />
                                    )}
                                    onChange={(e, newValue) =>
                                        handleChange(
                                            f.name,
                                            newValue?.value !== undefined
                                                ? newValue?.value
                                                : '',
                                            f.input
                                        )
                                    }
                                />
                            )}
                            {f.input !== 'select' && (
                                <TextField
                                    size='small'
                                    value={filterBy[f.name]?.value || ''}
                                    label={f.label}
                                    onChange={({ target }) =>
                                        handleChange(f.name, target.value, f.input)
                                    }
                                />
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </>
    );
};

export default FilterMembers;
