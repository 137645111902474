import { Button, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { useShowModalOptions } from '../Store/Store';

const ConfirmDeleteModal = props => {
    const { handleDelete } = props;
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleClose = () => setShowModal({ show: false, Comp: '' });

    return (
        <>
            <DialogTitle id='alert-dialog-title'>
                Jeste li sigurni da želite izbrisati ?
            </DialogTitle>
            <DialogActions>
                <Button variant='outlined' color='error' onClick={handleClose}>
                    Odustani
                </Button>
                <Button
                    variant='contained'
                    onClick={() => handleDelete({ ...props })}
                    autoFocus
                >
                    Prihvati
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmDeleteModal;
