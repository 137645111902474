const formatDate = fullDate => {
    try {
        let date = new Date(fullDate);
        let offset = date.getTimezoneOffset();
        date = new Date(date.getTime() - offset * 60 * 1000);

        return date.toISOString().split('T')[0];
    } catch (err) {
        return false;
    }
};

export default formatDate;
