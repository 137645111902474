import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import useFetch from '../../../hooks/useFetch';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { getMembers } from '../../../services/members/members';
import { useMembersStore, useOrganizationStore } from '../../Store/Store';
import AddMembersForm from './AddMembersForm/AddMembersForm';
import EditMember from './EditMembers/EditMember';
import MembersTable from './MembersTable';
import { useHistory, useParams } from 'react-router-dom';
import { getAllMemberFunction } from '../../../services/members/function';
import { getMembershipFee } from '../../../services/organizations/membershipFee';
import { getOrganizationRanks } from '../../../services/organizations/ranks';

const Members = () => {
    const { id } = useParams();
    const { path, url } = useRouteMatch();

    const [{ access }] = useLocalStorage('tokens');
    const history = useHistory();

    const { doFetch } = useFetch({ loading: true });

    const setMembers = useMembersStore(state => state.setMembers);
    const setMembersCopy = useMembersStore(state => state.setMembersCopy);
    const setAllMemberFunction = useMembersStore(state => state.setAllMemberFunction);

    const { naziv } = useOrganizationStore(state => state.currentOrganization);
    const setMembershipFee = useOrganizationStore(state => state.setMembershipFee);
    const setRanks = useOrganizationStore(state => state.setRanks);

    const { doFetch: getFeeTransfer } = useFetch();

    const handleGetAllMemberFunction = useCallback(() => {
        getFeeTransfer([
            { func: getAllMemberFunction, info: [access, id] },
            { func: data => setAllMemberFunction(data) },
            { func: getMembershipFee, info: [access, id] },
            { func: data => setMembershipFee(data) },
        ]);
    }, [access, getFeeTransfer, id, setAllMemberFunction, setMembershipFee]);

    const handleGetMembers = useCallback(() => {
        naziv &&
            doFetch([
                { func: getMembers, info: [access, naziv] },
                {
                    func: data => {
                        setMembers(data);
                        setMembersCopy(data);
                        if (data.length === 0) {
                            setAllMemberFunction([]);
                            history.push(`${url}/add-member`);
                        } else {
                            handleGetAllMemberFunction();
                        }
                    },
                },
                {
                    func: getOrganizationRanks,
                    info: [access, id],
                },
                { func: data => setRanks(data) },
            ]);
    }, [
        access,
        doFetch,
        naziv,
        setMembers,
        history,
        url,
        handleGetAllMemberFunction,
        setMembersCopy,
        setAllMemberFunction,
        setRanks,
        id,
    ]);

    useEffect(() => handleGetMembers(), [handleGetMembers]);

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <MembersTable />
            </Route>
            <Route exact path={`${path}/add-member`}>
                <AddMembersForm handleGetMembers={handleGetMembers} />
            </Route>
            <Route exact path={`${path}/edit-member/:member_id`}>
                <EditMember />
            </Route>
        </Switch>
    );
};

export default Members;
