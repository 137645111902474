import React, { useCallback, useEffect, useState } from 'react';
import { Button, Collapse, Paper, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import {
    deleteFile,
    getSharedFolders,
} from '../../../../services/organizations/sharedFolder';
import TableComp from '../../../TableComp/TableComp';
import { AddFileField } from './AddFileField';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ImageIcon from '@mui/icons-material/Image';
import DeleteForever from '@mui/icons-material/DeleteForever';
import formatDateDmy from '../../../../helpers/formatDateDmy';
import { useShowModalOptions } from '../../../Store/Store';
import ConfirmDeleteModal from '../../ConfirmDeleteModal';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import ImagePreviewTooltip from './ImagePreviewTooltip';
import getFileExtension from '../../../../helpers/getFileExtension';

const sharedTableHead = [
    { id: 'name', label: 'Ime datoteke', type: 'string' },
    {
        id: 'date_added',
        label: 'Datum dodavanja',
        type: 'function',
        info: date => formatDateDmy(new Date(date), true),
    },
    { id: 'file', btn: true },
    { id: 'delete', btn: true },
];

const UpdateIcon = ({ name }) => {
    const fileExtension = getFileExtension(name).pop();

    if (fileExtension !== 'jpg' && fileExtension !== 'jpeg') {
        return <FilePresentIcon />;
    }

    return <ImageIcon />;
};

const SharedFolder = () => {
    const [openAddForm, setOpenAddForm] = useState(false);
    const [sharedFolders, setSharedFolders] = useState([]);

    const { id } = useParams();

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch: fetchShared } = useFetch({ loading: true });
    const {
        doFetch,
        error: { error },
    } = useFetch({
        message: {
            showErrMsg: true,
            errMsg: 'Došlo je do greške prilikom brisanja datoteke',
        },
    });
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleOpenAddFile = () => setOpenAddForm(prevState => !prevState);
    const handleClose = useCallback(() => {
        setShowModal({ show: false, Comp: '' });
    }, [setShowModal]);

    const handleDelete = ({ id }) => {
        doFetch([
            { func: deleteFile, info: [access, id] },
            {
                func: () => {
                    setSharedFolders(prevState => prevState.filter(f => f.id !== id));
                    handleClose();
                },
            },
        ]);
    };

    const btnsArr = [
        {
            name: 'file',
            onClick: ({ url }) => url && window.open(url, '_blank'),
            IconComp: UpdateIcon,
            tooltipTitle: true,
            TooltipTitleComp: ImagePreviewTooltip,
            tooltipOptions: {
                placement: 'bottom',
                enterDelay: 500,
            },
        },
        {
            name: 'delete',
            onClick: ({ id }) =>
                setShowModal({
                    show: true,
                    Comp: ConfirmDeleteModal,
                    dialogSize: 'xs',
                    info: { id, handleDelete },
                }),
            icon: <DeleteForever />,
        },
    ];

    useEffect(() => {
        fetchShared([
            { func: getSharedFolders, info: [access, id] },
            { func: data => setSharedFolders(data) },
        ]);
    }, [access, fetchShared, id]);

    useEffect(() => {
        if (error) {
            new Promise(res => setTimeout(res, 500)).then(() => handleClose());
        }
    }, [error, handleClose]);

    return (
        <Paper sx={{ maxWidth: '54rem', m: '1rem auto' }}>
            <Stack
                direction='row'
                justifyContent='space-around'
                alignItems='center'
                py={3}
            >
                <Typography variant='h5'>Zajedničke datoteke</Typography>
                <Button
                    color={openAddForm ? 'error' : 'primary'}
                    variant='outlined'
                    onClick={handleOpenAddFile}
                >
                    {openAddForm ? <CloseIcon /> : <AddIcon />}
                </Button>
            </Stack>
            <Collapse unmountOnExit in={openAddForm}>
                <Box sx={{ backgroundColor: grey['100'] }}>
                    <AddFileField
                        setOpenAddForm={setOpenAddForm}
                        setSharedFolders={setSharedFolders}
                    />
                </Box>
            </Collapse>
            {sharedFolders.length !== 0 && (
                <TableComp
                    body={sharedFolders}
                    head={sharedTableHead}
                    btns
                    btnsArr={btnsArr}
                />
            )}
        </Paper>
    );
};

export default SharedFolder;
