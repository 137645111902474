import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
} from '@mui/material';
import { useAdminStore } from '../../../Store/Store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableComp from '../../../TableComp/TableComp';
import { userActivityTableHead } from './userActivityTableHead';

const OrganizationMembers = () => {
    const statistic = useAdminStore(state => state.statistic);

    return (
        <Box sx={{ my: 2, mx: 2 }}>
            <Typography align='center' variant='h6' color='primary' mb={3}>
                Aktivni članovi po udruzi
            </Typography>
            {statistic.organizationMembersActivity.map(org => {
                return (
                    <Accordion key={org?.udruga}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                        >
                            <Typography>Naziv udruge: {org?.naziv}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableComp
                                head={userActivityTableHead}
                                body={org.user}
                                pagination
                            />
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
};

export default OrganizationMembers;
