import { createContext, useReducer } from 'react';
import FeedbackReducer from './FeedbackReducer';

export const FeedbackState = createContext();
export const FeedbackDispatch = createContext();

const initialState = {
    feedback: { loading: false, message: { open: false, type: 'info', msg: '' } },
};

export const FeedbackProvider = props => {
    const [state, dispatch] = useReducer(FeedbackReducer, initialState);

    return (
        <FeedbackState.Provider value={state}>
            <FeedbackDispatch.Provider value={dispatch}>
                {props.children}
            </FeedbackDispatch.Provider>
        </FeedbackState.Provider>
    );
};
