import React from 'react';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import UpdateForm from '../../../../FormComp/UpdateForm';
import { addMembershipFee } from '../../../../../services/organizations/membershipFee';
import { membershipFeeRules } from './membershipFeeRules';
import { useOrganizationStore } from '../../../../Store/Store';
import { getYear } from 'date-fns';

const AddForm = ({ handleGetMembershipFee, setExpanded }) => {
    const [{ access }] = useLocalStorage('tokens');

    const { id } = useOrganizationStore(state => state.currentOrganization);
    const { doFetch } = useFetch({ message: { showErrMsg: true } });

    const handleAddMembershipRules = data => {
        const { iznos_redovni, iznos_pridruzeni, iznos_pocasni, iznos_ostalo, godina } =
            data;

        doFetch([
            {
                func: addMembershipFee,
                info: [
                    access,
                    {
                        iznos_redovni: +iznos_redovni,
                        iznos_pridruzeni: +iznos_pridruzeni,
                        iznos_pocasni: +iznos_pocasni,
                        iznos_ostalo: +iznos_ostalo,
                        godina: +getYear(godina),
                        udruga: +id,
                    },
                ],
            },
            { func: handleGetMembershipFee },
            {
                func: () =>
                    typeof setExpanded === 'function' ? setExpanded(false) : null,
            },
        ]);
    };

    return (
        <UpdateForm
            updateInputs={membershipFeeRules}
            onSubmit={handleAddMembershipRules}
            btnMessage='Dodaj'
            styleContainer='updateForm__container updateForm__container-2c'
            clearFields
        />
    );
};

export default AddForm;
