import AddOrganizaionFinalStep from './AddOrganizaionFinalStep';
import AddOrganizationConfirmStep from './AddOrganizationConfirmStep';

export const organizationsRules = [
    {
        step: 'Osnovne informacije',
        name: 'naziv',
        label: 'Naziv',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 150,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'naziv',
    },
    {
        step: 'Osnovne informacije',
        name: 'skraceni',
        label: 'Kratki naziv',
        rules: {
            maxLength: {
                value: 50,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'skraceni',
    },
    {
        step: 'Osnovne informacije',
        name: 'reg_broj',
        label: 'Matični broj',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 9,
                message: 'Matični broj može imati najviše 9 znamenki',
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'reg_broj',
    },
    {
        step: 'Osnovne informacije',
        name: 'oib',
        label: 'OIB udruge',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 11,
            },
            minLength: {
                value: 11,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'oib',
    },
    {
        step: 'Osnovne informacije',
        name: 'iban',
        label: 'IBAN udruge',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 21,
            },
            minLength: {
                value: 21,
            },
        },
        defaultValue: 'iban',
    },
    {
        step: 'Osnovne informacije',
        name: 'ljecnicki_pregled',
        label: 'Liječnički pregled',
        input: 'checkbox',
        defaultValue: 'ljecnicki_pregled',
        helperText:
            'Ako članovi udruge moraju obavljati liječnički pregled, označite ovo polje kako biste mogli pratiti evidenciju liječničkih pregleda članova.',
    },
    {
        step: 'Adresa i kontakt',
        name: 'tel_broj',
        label: 'Telefonski broj',
        rules: {
            maxLength: {
                value: 9,
            },
            minLength: {
                value: 6,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'tel_broj',
    },
    {
        step: 'Adresa i kontakt',
        name: 'mobitel',
        label: 'Mobitel',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 11,
            },
            minLength: {
                value: 9,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'mobitel',
    },
    {
        step: 'Adresa i kontakt',
        name: 'email',
        label: 'E-mail adresa',
        rules: {
            maxLength: {
                value: 255,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^\S+@\S+$/,
                message: 'Pogrešna e-mail adresa',
            },
        },
        defaultValue: 'email',
    },
    {
        step: 'Adresa i kontakt',
        name: 'state',
        label: 'Država',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 55,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'state',
    },
    {
        step: 'Adresa i kontakt',
        name: 'postal_number',
        label: 'Poštanski broj',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 5,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'postal_number',
    },
    {
        step: 'Adresa i kontakt',
        name: 'city',
        label: 'Grad',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 55,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'city',
    },
    {
        step: 'Adresa i kontakt',
        name: 'street_name',
        label: 'Naziv ulice',
        rules: {
            maxLength: {
                value: 55,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'street_name',
    },
    {
        step: 'Adresa i kontakt',
        name: 'street_numb',
        label: 'Broj ulice',
        rules: {
            maxLength: {
                value: 5,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^[a-zA-Z0-9]*$/,
            },
        },
        defaultValue: 'street_numb',
    },
    {
        step: 'Potvrda',
        Comp: AddOrganizationConfirmStep,
    },
    {
        step: 'Kraj',
        Comp: AddOrganizaionFinalStep,
    },
];
