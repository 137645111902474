import React from 'react';
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Box } from '@mui/system';
import { v4 as uuidv4 } from 'uuid';
import { visuallyHidden } from '@mui/utils';
import TableCheckboxToolbar from './TableCheckboxToolbar';

const TableHeadComp = props => {
    const {
        order,
        orderBy,
        onRequestSort,
        headCells,
        numSelected,
        rowCount,
        onSelectAllClick,
        children,
    } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {children.filter(c => c.type === TableCheckboxToolbar).length !== 0 && (
                    <TableCell padding='checkbox'>
                        <Checkbox
                            color='primary'
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                )}
                {headCells.map(headCell =>
                    headCell.btn ? (
                        <TableCell key={uuidv4()} align='center'>
                            {headCell.label}
                        </TableCell>
                    ) : (
                        <TableCell
                            key={uuidv4()}
                            align='center'
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component='span' sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    )
                )}
            </TableRow>
        </TableHead>
    );
};

export default TableHeadComp;
