import React from 'react';
import { Divider, Paper, Typography } from '@mui/material';
import ListComp from '../../../ListComp/ListComp';
import { useMembersStore, useOrganizationStore } from '../../../Store/Store';
import PieGraph from './PieGraph';
import DebtorTable from './DebtorTable';

const InfoList = ({ data }) => {
    const { top5_duznika_clanarine: top5 } = data;

    const allMembersFunction = useMembersStore(state => state.allMembersFunction);
    const membershipFee = useOrganizationStore(state => state.membershipFee);

    const basicInfo = {
        title: 'Broj članova',
        data: [
            {
                desc: 'Ukupni broj članova',
                info: [data.broj_clanova_ukupno],
                text: true,
            },
            { desc: 'Aktivni članovi', info: [data.broj_clanova_aktivnih], text: true },
            {
                desc: 'Neaktivni članovi',
                info: [data.broj_clanova_neaktivnih],
                text: true,
            },
            {
                desc: 'Članovi sa funkcijom',
                info: [allMembersFunction.length],
                text: true,
            },
        ],
        showGraph: data.broj_clanova_ukupno !== 0,
        get graphData() {
            return [
                {
                    desc: 'Članove bez funkcije',
                    info: [data.broj_clanova_ukupno - allMembersFunction.length],
                },
                { desc: 'Članovi sa funkcijom', info: [allMembersFunction.length] },
            ];
        },
    };

    const byMembershipType = {
        title: 'Broj članova po vrsti članstva',
        data: [
            { desc: 'Redovni članovi', info: [data.broj_redovnih], text: true },
            { desc: 'Pridruženi članovi', info: [data.broj_pridruzenih], text: true },
            { desc: 'Počasni članovi', info: [data.broj_pocasnih], text: true },
            { desc: 'Ostali članovi', info: [data.broj_ostalih], text: true },
        ],
        showGraph: data.broj_clanova_ukupno !== 0,
        get graphData() {
            return [...this.data];
        },
    };

    const membership = {
        title: `Članarina za ${new Date().getFullYear()}`,
        data: [
            {
                desc: 'Neplaćene',
                info: [data.broj_clanarina_za_tekucu_god_neplacenih],
                text: true,
            },
            {
                desc: 'Plaćene',
                info: [data.broj_clanarina_za_tekucu_god_placenih],
                text: true,
            },
            {
                desc: 'Netočan iznos',
                info: [data.broj_clanarina_za_tekucu_god_netocan_iznos_uplate],
                text: true,
            },
        ],
        showGraph: membershipFee.length !== 0,
        get graphData() {
            return [...this.data];
        },
    };

    return (
        <div
            style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0 2rem 0' }}
        >
            <Paper sx={{ width: '44rem' }}>
                <Typography align='center' variant='h5' color='primary' my={3}>
                    Pregled udruge
                </Typography>
                <Divider />
                <div style={{ padding: '1rem 0 1rem 0' }}>
                    {[basicInfo, byMembershipType, membership].map(info => {
                        const { title, data, graphData, showGraph } = info;

                        return (
                            <div key={title} style={{ margin: '0.4rem 0 1rem 0' }}>
                                <Typography
                                    align='center'
                                    color='primary'
                                    variant='h6'
                                    mb={2}
                                >
                                    {title}
                                </Typography>
                                {showGraph ? (
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: '0 0 36%' }}>
                                            <ListComp
                                                listStyle={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '1fr',
                                                }}
                                                listItems={data}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <PieGraph data={graphData} />
                                        </div>
                                    </div>
                                ) : (
                                    <ListComp
                                        listStyle={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr',
                                        }}
                                        listItems={data}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
                <Divider />
                <DebtorTable top5={top5} />
            </Paper>
        </div>
    );
};

export default InfoList;
