import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import LoginForm from '../LoginForm/LoginForm';
import useFetch from '../../hooks/useFetch';
import { verifyEmail } from '../../services/user';

const ConfirmAcc = ({ handleGetUserData }) => {
    const location = useLocation();

    const {
        error: { error },
        doFetch,
    } = useFetch({
        message: {
            showErrMsg: true,
            errMsg: 'Došlo je do pogreške pri registraciji',
            showSuccessMsg: true,
            successMsg: 'E-mail adresa je uspješno aktivirana',
        },
    });

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        doFetch([{ func: verifyEmail, info: [query.get('token')] }]);
    }, [doFetch, location]);

    if (error) return <Redirect to='/' />;

    return <LoginForm handleGetUserData={handleGetUserData} />;
};

export default ConfirmAcc;
