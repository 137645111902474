import React from 'react';
import Close from '@mui/icons-material/Close';
import { DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import { getPayment } from '../../../../../services/members/payment';
import { useMembersStore, useShowModalOptions } from '../../../../Store/Store';
import AddForm from '../../../Organizations/MembershipFee/AddForm/AddForm';

const AddMembership = ({ member_id }) => {
    const { doFetch } = useFetch();
    const [{ access }] = useLocalStorage('tokens');

    const setShowModal = useShowModalOptions(state => state.setShowModal);
    const setMemberPayment = useMembersStore(state => state.setMemberPayment);

    const handleCloseModal = () => setShowModal({ show: false, Comp: '' });

    const handleGetMembershipFee = () => {
        doFetch([
            { func: getPayment, info: [access, member_id] },
            {
                func: data => {
                    setMemberPayment(data);
                    handleCloseModal();
                },
            },
        ]);
    };

    return (
        <>
            <DialogTitle
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant='h6'>Definiraj članarinu</Typography>
                <IconButton aria-label='close' onClick={handleCloseModal} color='primary'>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <AddForm handleGetMembershipFee={handleGetMembershipFee} />
        </>
    );
};

export default AddMembership;
