import React from 'react';
import { DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import Ranks from './Ranks';
import CloseIcon from '@mui/icons-material/Close';
import { useShowModalOptions } from '../../../Store/Store';

const RankDialog = () => {
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    return (
        <div>
            <DialogTitle
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant='h6'>
                    Definiraj razine osposobljenosti u udruzi
                </Typography>
                <IconButton
                    aria-label='close'
                    onClick={() =>
                        setShowModal({ show: false, Comp: '', dialogSize: 'sm' })
                    }
                    color='primary'
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Ranks />
        </div>
    );
};

export default RankDialog;
