export const roles = [
    {
        pk: 1,
        naziv: 'Administrator udruge',
        kratki_naziv: 'AU',
    },
    {
        pk: 2,
        naziv: 'Upravitelj udruge',
        kratki_naziv: 'UU',
    },
    /* {
        pk: 3,
        naziv: 'Korisnik udruge',
        kratki_naziv: 'KU',
    },
    {
        pk: 4,
        naziv: 'Član udruge',
        kratki_naziv: 'CU',
    }, */
];

export const membershipType = [
    {
        pk: 1,
        naziv: 'Počasni',
    },
    {
        pk: 2,
        naziv: 'Pridruženi',
    },
    {
        pk: 3,
        naziv: 'Redovni',
    },
    {
        pk: 4,
        naziv: 'Ostalo',
    },
];

export const assemblyStatusType = [
    {
        pk: 1,
        naziv: 'Redovna',
    },
    {
        pk: 2,
        naziv: 'Izvanredna',
    },
    {
        pk: 3,
        naziv: 'Izborna',
    },
    {
        pk: 4,
        naziv: 'Ostalo',
    },
];
