import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CssBaseline, Toolbar, Dialog, Slide } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';
import {
    useAdminStore,
    useDashboardTabsStore,
    useMembersStore,
    useOrganizationStore,
    useShowModalOptions,
    useUserStore,
} from '../Store/Store';
import useLocalStorage from '../../hooks/useLocalStorage';
import Routes from './Routes';
import LeftSideDrawer from './LeftSideDrawer';
import Navbar from './Navbar';
import useFetch from '../../hooks/useFetch';
import { getUserOrganization } from '../../services/organizations/organizations';
import { getApprovedOrganizations, getInactiveOrganization } from '../../services/admin';
import { getMembers } from '../../services/members/members';
import { getOrganizationRanks } from '../../services/organizations/ranks';
import { getLogo } from '../../services/organizations/logo';
import { drawerWidth } from './const';
import { StyledMain } from './StyledMain';
import { getAllMemberFunction } from '../../services/members/function';
import { getMemberStatus } from '../../services/members/status';
import { getMedicalList } from '../../services/members/medical';
import { getMemberRanks } from '../../services/members/ranks';
import { getPayment } from '../../services/members/payment';
import { getMemberFile } from '../../services/members/files';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const Dashboard = () => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const setValue = useDashboardTabsStore(state => state.setValue);

    const location = useLocation();

    const locationRef = useRef(location);

    const { doFetch } = useFetch({ loading: true });
    const { doFetch: getInactive } = useFetch({ loading: true });
    const { doFetch: getMemb } = useFetch({ loading: true });
    const [token] = useLocalStorage('tokens', '');

    const userData = useUserStore(state => state.userData);

    const userOrganizations = useOrganizationStore(state => state.userOrganizations);
    const setUserOrganization = useOrganizationStore(state => state.setUserOrganization);
    const setCurrentOrganization = useOrganizationStore(
        state => state.setCurrentOrganization
    );

    const setInactiveOrganization = useAdminStore(state => state.setInactiveOrganization);
    const setApprovedOrganization = useAdminStore(state => state.setApprovedOrganization);

    const { show, Comp, dialogSize, info } = useShowModalOptions(
        state => state.showModal
    );
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const setMembers = useMembersStore(state => state.setMembers);
    const setMembersCopy = useMembersStore(state => state.setMembersCopy);
    const setAllMemberFunction = useMembersStore(state => state.setAllMemberFunction);
    const setMemberStatus = useMembersStore(state => state.setMemberStatus);
    const setMemberMedical = useMembersStore(state => state.setMemberMedical);
    const setMemberRanks = useMembersStore(state => state.setMemberRanks);
    const setMemberPayment = useMembersStore(state => state.setMemberPayment);
    const setMemberFiles = useMembersStore(state => state.setMemberFiles);

    const setRanks = useOrganizationStore(state => state.setRanks);
    const setLogo = useOrganizationStore(state => state.setLogo);

    const handleDrawerOpen = () => setOpenDrawer(true);
    const handleDrawerClose = () => setOpenDrawer(false);

    const handleGetUserOrg = useCallback(
        cb => {
            doFetch([
                { func: getUserOrganization, info: [token.access] },
                {
                    func: data => {
                        if (Array.isArray(data) && data.length !== 0) {
                            setUserOrganization(data);
                            let id, id1, org;
                            let pathname = locationRef.current.pathname;

                            let idArr = (
                                pathname.match(/\d+\.\d+|\d+\b|\d+(?=\w)/g) || []
                            ).map(num => +num);

                            if (idArr.length === 1) id = idArr[0];
                            if (idArr.length === 2) {
                                id = idArr[0];
                                id1 = idArr[1];
                            }

                            org = id ? data.filter(d => d.id === +id)[0] : data[0];

                            let { ljecnicki_pregled } = org;
                            setCurrentOrganization(org);
                            setValue(data.indexOf(org));

                            getMemb([
                                ...((id &&
                                    pathname !== `/dashboard/organization/${id}` &&
                                    pathname !==
                                        `/dashboard/organization/${id}/edit-member/${id1}`) ||
                                pathname === '/dashboard' ||
                                pathname === '/dashboard/create'
                                    ? [
                                          {
                                              func: getMembers,
                                              info: [token.access, org.naziv],
                                          },
                                          {
                                              func: data => {
                                                  setMembers(data);
                                                  setMembersCopy(data);
                                              },
                                          },
                                      ]
                                    : []),
                                ...(id &&
                                pathname !== `/dashboard/members-function/${id}` &&
                                pathname !== `/dashboard/organization-info/${id}`
                                    ? [
                                          {
                                              func: getAllMemberFunction,
                                              info: [token.access, id],
                                          },
                                          { func: data => setAllMemberFunction(data) },
                                      ]
                                    : []),
                                {
                                    func: getOrganizationRanks,
                                    info: [token.access, org.id],
                                },
                                { func: data => setRanks(data) },
                                { func: getLogo, info: [token.access, org.id] },
                                { func: data => setLogo(data) },
                                ...(id &&
                                id1 &&
                                pathname ===
                                    `/dashboard/organization/${id}/edit-member/${id1}`
                                    ? [
                                          {
                                              func: getMemberStatus,
                                              info: [token.access, id1],
                                          },
                                          { func: data => setMemberStatus(data) },
                                          ...(ljecnicki_pregled
                                              ? [
                                                    {
                                                        func: getMedicalList,
                                                        info: [token.access, id1],
                                                    },
                                                    {
                                                        func: data =>
                                                            setMemberMedical(data),
                                                    },
                                                ]
                                              : []),
                                          {
                                              func: getMemberRanks,
                                              info: [token.access, id1],
                                          },
                                          { func: data => setMemberRanks(data) },
                                          { func: getPayment, info: [token.access, id1] },
                                          { func: data => setMemberPayment(data) },
                                          {
                                              func: getMemberFile,
                                              info: [token.access, id1],
                                          },
                                          { func: data => setMemberFiles(data) },
                                      ]
                                    : []),
                                { func: cb },
                            ]);
                        } else {
                            setUserOrganization([]);
                            setCurrentOrganization([]);
                            typeof cb === 'function' && cb();
                        }
                    },
                },
            ]);
        },
        [
            doFetch,
            token.access,
            setCurrentOrganization,
            setUserOrganization,
            getMemb,
            setMembers,
            setMembersCopy,
            setRanks,
            setLogo,
            setAllMemberFunction,
            setValue,
            setMemberFiles,
            setMemberMedical,
            setMemberPayment,
            setMemberRanks,
            setMemberStatus,
        ]
    );

    const handleGetInactiveOrg = useCallback(() => {
        getInactive([
            { func: getInactiveOrganization, info: [token.access] },
            { func: data => setInactiveOrganization(data) },
            { func: getApprovedOrganizations, info: [token.access] },
            { func: data => setApprovedOrganization(data) },
        ]);
    }, [token.access, getInactive, setInactiveOrganization, setApprovedOrganization]);

    useEffect(() => userData?.id && handleGetUserOrg(), [handleGetUserOrg, userData?.id]);

    useEffect(() => {
        if (userOrganizations.length !== 0 || userData?.is_superuser) {
            setOpenDrawer(true);
        }
    }, [userOrganizations, userData?.is_superuser]);

    return (
        <>
            <Box
                className='dashboard__background'
                sx={{
                    display: 'flex',
                    minHeight: '97.8vh',
                }}
            >
                <CssBaseline />
                <Navbar open={openDrawer} handleDrawerOpen={handleDrawerOpen} />
                <LeftSideDrawer
                    drawerWidth={drawerWidth}
                    open={openDrawer}
                    handleDrawerClose={handleDrawerClose}
                    handleGetUserOrg={handleGetUserOrg}
                />
                <StyledMain open={openDrawer}>
                    <Toolbar sx={{ height: '10vh' }} />
                    <Routes
                        handleGetInactiveOrg={handleGetInactiveOrg}
                        handleGetUserOrg={handleGetUserOrg}
                    />
                    {show && (
                        <Dialog
                            maxWidth={dialogSize}
                            fullWidth
                            open={show}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() =>
                                setShowModal({ show: false, Comp: '', dialogSize: 'sm' })
                            }
                            aria-describedby='alert-dialog-slide-description'
                        >
                            <Comp {...info} />
                        </Dialog>
                    )}
                </StyledMain>
            </Box>
        </>
    );
};

export default Dashboard;
