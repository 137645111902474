import React from 'react';
import { Button, DialogContent, DialogTitle, Typography } from '@mui/material';
import UpdateForm from '../../../FormComp/UpdateForm';
import { useShowModalOptions } from '../../../Store/Store';

const ConfirmDelete = ({ org, handleSubmit, submitParams, title }) => {
    const setShowModal = useShowModalOptions(state => state.setShowModal);
    const handleClose = () => setShowModal({ show: false, Comp: '' });

    return (
        <>
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <Typography pt={1} component='p'>
                    {`Ovu akciju nije moguće poništiti.    
                    Time ćete trajno izbrisati udrugu ${org.naziv}. 
                    Upišite komentar kako biste potvrdili.`}
                </Typography>
                <UpdateForm
                    updateInputs={[
                        {
                            name: 'comment',
                            label: 'Komentar',
                            multiline: true,
                            rows: 4,
                            rules: {
                                required: 'Ovo polje je obavezno',
                            },
                        },
                    ]}
                    onSubmit={data => handleSubmit(data, ...submitParams)}
                    btnMessage='Potvrdi'
                >
                    <Button onClick={handleClose} color='error' variant='outlined'>
                        Natrag
                    </Button>
                </UpdateForm>
            </DialogContent>
        </>
    );
};

export default ConfirmDelete;
