import { BASE_URL } from './baseUrl';

export const registerUser = (data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_REGISTER}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const resendEmail = (data, signal) => {
    return fetch(`${BASE_URL}/resend/email-activation/`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const verifyEmail = (token, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_VERIFY_EMAIL}${token}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
        signal,
    });
};

export const loginUser = (data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_LOGIN}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const refreshToken = (token, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ refresh: token }),
        signal,
    });
};

export const resetUserPass = (data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_RESET_USER_PASS}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const confirmResetEmail = (uidb64, token, signal) => {
    return fetch(
        `${BASE_URL}${process.env.REACT_APP_CONFIRM_RESET_EMAIL}${uidb64}/${token}/`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
            signal,
        }
    );
};

export const resetPassFinal = (data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_RESET_PASS_FINAL}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};

export const getUserData = (email, token, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_USER_DATA}${email}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};
