import React, { useEffect, useState } from 'react';
import {
    addDefaultValues,
    compareObjWithSameKeys,
    createDefaultValues,
    findCorrectFunc,
} from '../../../../../helpers/editHelpers';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import UpdateForm from '../../../../FormComp/UpdateForm';
import {
    editAddressMember,
    editContactMember,
} from '../../../../../services/members/edit';
import { editAddressAndContactRules } from './editAddressAndContactRules';

const EditAddressAndContactForm = ({ currentMember, filterMember }) => {
    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);
    const { doFetch } = useFetch({
        message: { showSuccessMsg: true, successMsg: 'Uspješno promjenjeno' },
    });

    const [{ access }] = useLocalStorage('tokens');

    const editContactMemberArr = ['tel_broj', 'mobitel', 'email'];
    const editAddressMemberArr = [
        'street_name',
        'street_numb',
        'city',
        'postal_number',
        'state',
    ];

    const keysObj = {
        contact: editContactMemberArr,
        address: editAddressMemberArr,
    };

    const handleEditMember = data => {
        const { tel_broj, mobitel, email } = data;
        const { street_name, street_numb, city, state } = data;

        let promisesArr = [];
        let changedValues = compareObjWithSameKeys(data, defaultValues);

        let keys = Object.keys(changedValues);
        let correctFunc = findCorrectFunc(keys, keysObj);

        if (correctFunc.length !== 0) {
            correctFunc.forEach(func => {
                if (func === 'contact') {
                    promisesArr.push({
                        func: editContactMember,
                        info: [
                            access,
                            currentMember.contact_id,
                            {
                                tel_broj,
                                mobitel,
                                email,
                            },
                        ],
                        id: 'contact',
                    });
                }
                if (func === 'address') {
                    promisesArr.push({
                        func: editAddressMember,
                        info: [
                            access,
                            currentMember.address_id,
                            {
                                street_name,
                                street_numb,
                                postal_number: 1000,
                                city,
                                state,
                            },
                        ],
                        id: 'address',
                    });
                }
            });
            doFetch([...promisesArr, { func: filterMember }]);
        }
    };

    useEffect(() => {
        setEditFields(addDefaultValues(editAddressAndContactRules, currentMember));
    }, [currentMember]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        editFields.length !== 0 &&
        defaultValues && (
            <UpdateForm
                updateInputs={editFields}
                onSubmit={handleEditMember}
                defaultValues={defaultValues}
                updateDisable={true}
                btnMessage='Promijeni'
                styleContainer='updateForm__container updateForm__container-4c'
            />
        )
    );
};

export default EditAddressAndContactForm;
