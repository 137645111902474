import { BASE_URL } from '../baseUrl';

export const getMemberFunction = (token, organizationName, signal) => {
    return fetch(
        `${BASE_URL}${process.env.REACT_APP_GET_MEMBER_FUNC}${organizationName}`,
        {
            credentials: 'include',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            signal,
        }
    );
};

export const getAllMemberFunction = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_GET_ALL_MEMBERS_FUNC}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const addFunction = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADD_MEMBER_FUNC}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const editFunction = (token, id, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_MEMBER_FUNC}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};

export const deleteFunctionMember = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_DELETE_MEMBER_FUNC}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
        signal,
    });
};
