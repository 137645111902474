import { BASE_URL } from '../baseUrl';

export const getMedicalList = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_GET_MEDICAL_LIST}${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const addMedicalMember = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADD_MEDICAL_MEMBER}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const editMedicalMember = (token, id, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_MEDICAL_MEMBER}${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};

export const deleteMedicalMember = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_DELETE_MEDICAL_MEMBER}${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
        signal,
    });
};
