import { Typography } from '@mui/material';
import React from 'react';
import { useOrganizationStore, useUserStore } from '../Store/Store';
import OrganizationForm from './Organizations/AddOrganzationForm/AddOrganizationForm';
import OrganizationInfo from './Organizations/OrganizationInfo/OrganizationInfo';

const Home = ({ handleGetInactiveOrg }) => {
    const userOrganizations = useOrganizationStore(state => state.userOrganizations);
    const userData = useUserStore(state => state.userData);

    return (
        <>
            <Typography color='primary' align='center' mt={4} variant='h3'>
                Dobrodošli {userOrganizations.length !== 0 && 'natrag'}{' '}
                {userData['first name: ']}
            </Typography>

            {userOrganizations.length === 0 ? (
                <OrganizationForm handleGetInactiveOrg={handleGetInactiveOrg} />
            ) : (
                <OrganizationInfo />
            )}
        </>
    );
};

export default Home;
