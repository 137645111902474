import React from 'react';
import logo from './../../images/itcs-logo.png';
import pdf from './../../images/Pravila_privatnosti-udruga.pdf';
import secondPdf from './../../images/Uvjeti korištenja-udruga.pdf';

const Footer = () => {
    return (
        <div>
            <div className='desktop'>
                <div
                    id={'footer'}
                    className={'index__footer'}
                    style={{
                        height: '6rem',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <a href='https://www.itcs.hr/'>
                            <img
                                src={logo}
                                alt=''
                                style={{
                                    width: '55%',
                                    marginTop: '10px',
                                    marginLeft: '20px',
                                }}
                            />{' '}
                        </a>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '-30px',
                            zIndex: '999',
                        }}
                    >
                        <a
                            rel='noreferrer'
                            href={pdf}
                            target={'_blank'}
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                                fontSize: '13px',
                            }}
                        >
                            Pravila privatnosti
                        </a>
                        <a
                            rel='noreferrer'
                            href={secondPdf}
                            target={'_blank'}
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                                fontSize: '13px',
                            }}
                        >
                            Uvjeti korištenja
                        </a>
                    </div>
                </div>
            </div>
            <div className='mobile'>
                <div
                    id={'footer'}
                    className={'index__footer'}
                    style={{
                        height: '6rem',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <a href='https://www.itcs.hr/'>
                            <img
                                src={logo}
                                alt=''
                                style={{
                                    width: '75%',
                                    marginTop: '10px',
                                    marginLeft: '-70px',
                                }}
                            />{' '}
                        </a>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '-30px',
                            zIndex: '999',
                        }}
                    >
                        <a
                            rel='noreferrer'
                            href={pdf}
                            target={'_blank'}
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                                fontSize: '13px',
                            }}
                        >
                            Pravila privatnosti
                        </a>
                        <a
                            rel='noreferrer'
                            href={secondPdf}
                            target={'_blank'}
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                                fontSize: '13px',
                            }}
                        >
                            Uvjeti korištenja
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
