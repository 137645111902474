const FeedbackReducer = (state, action) => {
    switch (action.type) {
        case 'setMessage':
            return {
                ...state,
                feedback: { ...state.feedback, message: action.message },
            };
        case 'startLoading':
            return {
                ...state,
                feedback: { ...state.feedback, loading: true },
            };
        case 'stopLoading':
            return {
                ...state,
                feedback: { ...state.feedback, loading: false },
            };
        default:
            return state;
    }
};

export default FeedbackReducer;
