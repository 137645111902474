import React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import YearPicker from '@mui/lab/YearPicker';
//import { hr } from 'date-fns/locale';

const DateYear = props => {
    const { value, onChange } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <YearPicker
                date={new Date().setFullYear(value)}
                isDateDisabled={() => false}
                maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
                onChange={newValue => {
                    onChange(newValue.getFullYear());
                }}
            />
        </LocalizationProvider>
    );
};

export default DateYear;
