import { BASE_URL } from '../baseUrl';

export const getOrganizationRanks = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_GET_ORG_RANKS}${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const addOrganizationRank = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADD_ORG_RANK}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        method: 'POST',
        signal,
    });
};

export const deleteOrganizationRank = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_DELETE_ORG_RANK}${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
        signal,
    });
};
