import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Home from './Home';
import Members from './Members/Members';
import OrganizationForm from './Organizations/AddOrganzationForm/AddOrganizationForm';
import EditOrganizationForm from './Organizations/EditOrganizationForm/EditOrganizationForm';
import UserRolesTable from './UserRoles/UserRolesTable';
import AssemblyTable from './Organizations/Assembly/AssemblyTable';
import MembershipFee from './Organizations/MembershipFee/MembershipFee';
import MembersWithFuncTable from './Organizations/MembersWithFunc/MembersWithFuncTable';
import OrganizationInfo from './Organizations/OrganizationInfo/OrganizationInfo';
import SharedFolder from './Organizations/SharedFolder/SharedFolder';
import Administration from './Admin/Administration/Administration';
import { useUserStore } from '../Store/Store';
import Statistic from './Admin/Statistic/Statistic';

const AdminProtectedRoute = ({ children, ...rest }) => {
    const userData = useUserStore(state => state.userData);

    return (
        <Route
            {...rest}
            render={() => {
                return userData?.is_superuser ? children : <Redirect to='/dashboard' />;
            }}
        />
    );
};

const Routes = ({ handleGetInactiveOrg, handleGetUserOrg }) => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <Home handleGetInactiveOrg={handleGetInactiveOrg} />
            </Route>
            <Route exact path={`${path}/create`}>
                <OrganizationForm handleGetInactiveOrg={handleGetInactiveOrg} />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <EditOrganizationForm />
            </Route>
            <Route path={`${path}/roles/:id`}>
                <UserRolesTable handleGetUserOrg={handleGetUserOrg} />
            </Route>
            <Route path={`${path}/organization/:id`}>
                <Members />
            </Route>
            <Route path={`${path}/membership-fee/:id`}>
                <MembershipFee />
            </Route>
            <Route path={`${path}/assembly/:id`}>
                <AssemblyTable />
            </Route>
            <Route path={`${path}/members-function/:id`}>
                <MembersWithFuncTable />
            </Route>
            <Route path={`${path}/organization-info/:id`}>
                <OrganizationInfo />
            </Route>
            <Route path={`${path}/shared-folder/:id`}>
                <SharedFolder />
            </Route>
            <AdminProtectedRoute>
                <Route path={`${path}/admin-organization`}>
                    <Administration
                        handleGetInactiveOrg={handleGetInactiveOrg}
                        handleGetUserOrg={handleGetUserOrg}
                    />
                </Route>
                <Route exact path={`${path}/statistic`}>
                    <Statistic />
                </Route>
            </AdminProtectedRoute>
        </Switch>
    );
};

export default Routes;
