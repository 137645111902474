import React from 'react';
import { Paper, Typography } from '@mui/material';
import UpdateForm from '../../../FormComp/UpdateForm';
import { addUsersWithRoleRules } from './addUserWithRoleRules';
import { useHistory, useParams } from 'react-router';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useFetch from '../../../../hooks/useFetch';
import formatDate from '../../../../helpers/formatDate';
import LinkButton from '../../../FormComp/LinkButton';
import { addUsersWithRole } from '../../../../services/organizations/roles';

const AddUserRoleForm = ({ handleGetUsersWithRole }) => {
    const history = useHistory();
    const { id } = useParams();

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch({ message: { showErrMsg: true } });

    const handleAddUsersWithRole = data => {
        const { email, role, kraj } = data;

        doFetch([
            {
                func: addUsersWithRole,
                info: [
                    access,
                    id,
                    {
                        email,
                        role,
                        od: formatDate(Date.now()),
                        do: formatDate(kraj),
                    },
                ],
            },
            { func: handleGetUsersWithRole },
            { func: () => history.goBack() },
        ]);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <Paper sx={{ width: '32rem' }}>
                <Typography variant='body1' align='center' mt={3} mx={4}>
                    Odabrani korisnik za ovlaštenje prethodno se mora samostalno
                    registrirati u sustav te ovdje navesti e-mail adresu koju je unio pri
                    registraciji.
                </Typography>
                <UpdateForm
                    updateInputs={addUsersWithRoleRules}
                    onSubmit={handleAddUsersWithRole}
                    btnMessage='Dodaj'
                >
                    <LinkButton linkFuncGoBack btnMessage='Natrag' />
                </UpdateForm>
            </Paper>
        </div>
    );
};

export default AddUserRoleForm;
