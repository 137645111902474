import React, { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { resetPassFinal } from '../../../services/user';
import UpdateForm from '../../FormComp/UpdateForm';

import { resetPassFinalRules } from './resetPassFinalRules';

const ResetPassFinalForm = props => {
    const { uidb64, token, handleNext } = props;
    const [confirmPass, setConfirmPass] = useState(true);
    const { doFetch } = useFetch();

    const handleResetPass = ({ password, passwordConfirm }) => {
        if (passwordConfirm === password) {
            doFetch([
                { func: resetPassFinal, info: [{ password, token, uidb64 }] },
                { func: () => handleNext() },
            ]);
        } else {
            setConfirmPass(false);
            setTimeout(() => setConfirmPass(true), 4000);
        }
    };

    return (
        <>
            <h4 className='form__titleReset'>Unesite novu šifru</h4>
            <UpdateForm
                updateInputs={resetPassFinalRules}
                onSubmit={handleResetPass}
                btnMessage='Dalje'
                confirmPass={confirmPass}
            />
        </>
    );
};

export default ResetPassFinalForm;
