export const resetPassRules = [
    {
        name: 'email',
        label: 'E-mail adresa',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 255,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^\S+@\S+$/,
                message: 'Pogrešna e-mail adresa',
            },
        },
    },
];
