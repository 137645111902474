import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableComp from '../../../../TableComp/TableComp';
import AddFunctionForm from './AddFunctionForm/AddFunctionForm';
import EditFunctionForm from './EditFunctionForm/EditFunctionForm';
import TableCollapse from '../../../../TableComp/TableCollapse';
import useFetch from '../../../../../hooks/useFetch';
import { deleteFunctionMember } from '../../../../../services/members/function';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { useMembersStore } from '../../../../Store/Store';

const medicalTableInfo = [
    { id: 'funkcija', label: 'Funkcija', type: 'string' },
    { id: 'pocetak_duznosti', label: 'Početak dužnosti', type: 'date' },
    { id: 'kraj_duznosti', label: 'Kraj dužnosti', type: 'date' },
];

const FunctionMember = ({ currentMember, filterMember }) => {
    const [{ access }] = useLocalStorage('tokens');

    const [tableInfo, setTableInfo] = useState([]);
    const [expanded, setExpanded] = useState(false);

    const { doFetch: deleteFunction } = useFetch();

    const { funkcija_povijest } = useMembersStore(state => state.currentMember);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const btnsArr = [
        {
            name: 'delete',
            onClick: ({ function_date_id }) =>
                deleteFunction([
                    { func: deleteFunctionMember, info: [access, function_date_id] },
                    { func: filterMember },
                ]),
            icon: <DeleteForever />,
        },
    ];

    useEffect(() => {
        if (currentMember['status(Aktivan/Neaktivan)'] === 'Aktivan') {
            setTableInfo([
                ...medicalTableInfo,
                { id: 'delete', label: '', btn: true },
                { id: '', btn: true, collapse: true },
            ]);
        } else {
            setTableInfo(medicalTableInfo);
        }
    }, [currentMember]);

    return funkcija_povijest.length > 0 && tableInfo.length > 0 ? (
        <>
            {currentMember['status(Aktivan/Neaktivan)'] === 'Aktivan' && (
                <div style={{ marginBottom: '2rem' }}>
                    <Accordion
                        expanded={expanded === 'panel1'}
                        onChange={handleChange('panel1')}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1bh-content'
                            id='panel1bh-header'
                        >
                            <Typography variant='body1'>Dodaj funkciju</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AddFunctionForm
                                currentMember={currentMember}
                                filterMember={filterMember}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}
            <TableComp
                maxTableHeight='40vh'
                head={tableInfo}
                body={funkcija_povijest}
                btns={currentMember['status(Aktivan/Neaktivan)'] === 'Aktivan'}
                btnsArr={
                    currentMember['status(Aktivan/Neaktivan)'] === 'Aktivan' && btnsArr
                }
                activeRow={{
                    func: () => {
                        let item = {};

                        funkcija_povijest.forEach(f => {
                            let date = new Date(f.kraj_duznosti);

                            if (date >= Date.now()) {
                                if (Object.keys(item).length === 0) {
                                    item = f;
                                } else if (new Date(item?.kraj_duznosti) <= date) {
                                    item = f;
                                }
                            }
                        });

                        return item.function_date_id;
                    },
                    id: 'function_date_id',
                }}
            >
                <TableCollapse>
                    <EditFunctionForm />
                </TableCollapse>
            </TableComp>
        </>
    ) : currentMember['status(Aktivan/Neaktivan)'] === 'Aktivan' ? (
        <AddFunctionForm currentMember={currentMember} filterMember={filterMember} />
    ) : (
        <Typography variant='h6' align='center' my={3}>
            Član nema prijašnjih funkcija.
        </Typography>
    );
};

export default FunctionMember;
