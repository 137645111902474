import About from './About';
import Footer from './Footer';
import IndexBanner from "../IndexBanner";

const Index = () => {
    return (
        <div style={{overflowX:"hidden", maxWidth:"100vw"}}>
            <div>
                <IndexBanner />
                <About />
            </div>
            <Footer />
        </div>
    );
};

export default Index;
