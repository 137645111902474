import React, { useState } from 'react';
import {
    Badge,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useDashboardTabsStore, useShowModalOptions } from '../../../Store/Store';
import DeleteOrganization from '../DeleteOrganization/DeleteOrganization';
import { Link, useRouteMatch } from 'react-router-dom';
import { Box } from '@mui/system';

const OptionsMenu = ({ handleGetUserOrg, id, handleOpenUpload, showDot }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const { url } = useRouteMatch();

    const setValue = useDashboardTabsStore(state => state.setValue);
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Badge
                {...(!showDot ? { color: 'primary' } : {})}
                variant='dot'
                overlap='circular'
            >
                <IconButton
                    id='basic-IconButton'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
            </Badge>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    component={Link}
                    to={`${url}/organization-info/${id}`}
                    onClick={handleClose}
                >
                    <ListItemIcon>
                        <InfoIcon fontSize='medium' />
                    </ListItemIcon>
                    Dodatne informacije o udruzi
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={`${url}/edit/${id}`}
                    sx={{
                        '& .MuiBadge-dot': { right: '-6.5rem' },
                        width: '100%',
                    }}
                    onClick={handleClose}
                >
                    <Badge {...(!showDot ? { color: 'primary' } : {})} variant='dot'>
                        <ListItemIcon>
                            <EditIcon fontSize='medium' />
                        </ListItemIcon>
                    </Badge>
                    Uredi udrugu
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleOpenUpload();
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <PhotoCamera fontSize='medium' />
                    </ListItemIcon>
                    Logo udruge
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setShowModal({
                            show: true,
                            Comp: DeleteOrganization,
                            dialogSize: 'sm',
                            info: { setValue, handleGetUserOrg },
                        });
                    }}
                >
                    <ListItemIcon>
                        <DeleteForeverIcon color='error' fontSize='medium' />
                    </ListItemIcon>
                    Obriši udrugu
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default OptionsMenu;
