import React, { useCallback } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { useOrganizationStore, useShowModalOptions } from '../../../Store/Store';
import useFetch from '../../../../hooks/useFetch';
import {
    addOrganizationRank,
    deleteOrganizationRank,
    getOrganizationRanks,
} from '../../../../services/organizations/ranks';
import UpdateForm from '../../../FormComp/UpdateForm';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import ConfirmDeleteModal from '../../ConfirmDeleteModal';
import { rankRules } from './rankRules';

const Ranks = () => {
    const [{ access }] = useLocalStorage('tokens');

    const { id } = useOrganizationStore(state => state.currentOrganization);
    const ranks = useOrganizationStore(state => state.ranks);
    const setRanks = useOrganizationStore(state => state.setRanks);
    const deleteRank = useOrganizationStore(state => state.deleteRank);

    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleClose = () => setShowModal({ show: false, Comp: '' });

    const { doFetch } = useFetch();
    const { doFetch: addRank } = useFetch();
    const { doFetch: fetchDeleteRank } = useFetch();

    const handleGetOrgRanks = useCallback(() => {
        id &&
            doFetch([
                { func: getOrganizationRanks, info: [access, id] },
                { func: data => setRanks(data) },
            ]);
    }, [access, doFetch, id, setRanks]);

    const handleDelete = ({ id }) => {
        fetchDeleteRank([
            { func: deleteOrganizationRank, info: [access, id] },
            { func: () => deleteRank(id) },
            { func: handleClose },
        ]);
    };

    const handleAddRank = ({ naziv_cina }) => {
        addRank([
            { func: addOrganizationRank, info: [access, { naziv_cina, udruga: id }] },
            { func: handleGetOrgRanks },
        ]);
    };

    const handleOpenModal = id => {
        return setShowModal({
            show: true,
            Comp: ConfirmDeleteModal,
            dialogSize: 'xs',
            info: { handleDelete, id },
        });
    };

    return (
        <>
            <UpdateForm
                updateInputs={rankRules}
                onSubmit={handleAddRank}
                btnMessage='Dodaj'
                clearFields
            />
            <Divider />
            {ranks?.length !== 0 && (
                <List>
                    {ranks.map(rank => (
                        <ListItem
                            key={`${rank?.naziv_cina}_${rank?.id}`}
                            divider
                            secondaryAction={
                                <Tooltip
                                    title={
                                        rank?.disabledDelete ? (
                                            <Typography variant='body2'>
                                                Razina osposobljenosti je dodijeljena
                                                najmanje jednom članu
                                            </Typography>
                                        ) : (
                                            ''
                                        )
                                    }
                                >
                                    <Box as='span'>
                                        <IconButton
                                            disabled={rank?.disabledDelete}
                                            onClick={() => handleOpenModal(rank.id)}
                                            edge='end'
                                            aria-label='delete'
                                            color='error'
                                        >
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                            }
                        >
                            <ListItemText primary={rank?.naziv_cina} />
                        </ListItem>
                    ))}
                </List>
            )}
        </>
    );
};

export default Ranks;
