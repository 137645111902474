import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Typography,
} from '@mui/material';
import Ranks from './Ranks';

const RankAccordion = () => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
            >
                <Typography variant='body1'>Razina osposobljenosti</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Divider />
                <Typography mt={2} variant='body1' align='center'>
                    Definiraj razine osposobljenosti u udruzi
                </Typography>
                <Ranks />
            </AccordionDetails>
        </Accordion>
    );
};

export default RankAccordion;
