export const membershipFeeRules = [
    {
        name: 'iznos_redovni',
        label: 'Iznos članarine za redovne članove',
        type: 'number',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
    },
    {
        name: 'iznos_pridruzeni',
        label: 'Iznos članarine za pridružene članove',
        type: 'number',
        rules: {
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        helperText: '* Nije obavezno',
    },
    {
        name: 'iznos_pocasni',
        label: 'Iznos članarine za počasne članove',
        type: 'number',
        rules: {
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        helperText: '* Nije obavezno',
    },
    {
        name: 'iznos_ostalo',
        label: 'Iznos članarine za ostale članove',
        type: 'number',
        rules: {
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        helperText: '* Nije obavezno',
    },
    {
        name: 'godina',
        label: 'Godina',
        input: 'year',
        rules: {
            required: 'Ovo polje je obavezno',
        },
    },
];
