import React, { useEffect, useState } from 'react';
import Logout from '@mui/icons-material/Logout';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    Avatar,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
} from '@mui/material';
import {
    useAdminStore,
    useDashboardTabsStore,
    useMembersStore,
    useOrganizationStore,
    useUserStore,
} from '../Store/Store';
import useLocalStorage from '../../hooks/useLocalStorage';
import { blue } from '@mui/material/colors';
import { Link, useRouteMatch } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { getMembers } from '../../services/members/members';
import { getOrganizationRanks } from '../../services/organizations/ranks';
import { getLogo } from '../../services/organizations/logo';

export default function Account() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { url } = useRouteMatch();

    const { doFetch } = useFetch();
    const setValue = useDashboardTabsStore(state => state.setValue);

    const setMembers = useMembersStore(state => state.setMembers);
    const setMembersCopy = useMembersStore(state => state.setMembersCopy);
    const resetMembers = useMembersStore(state => state.resetMembers);

    const userData = useUserStore(state => state.userData);
    const clearUserData = useUserStore(state => state.clearUserData);

    const userOrganizations = useOrganizationStore(state => state.userOrganizations);
    const setCurrentOrganization = useOrganizationStore(
        state => state.setCurrentOrganization
    );
    const setRanks = useOrganizationStore(state => state.setRanks);
    const setLogo = useOrganizationStore(state => state.setLogo);
    const resetOrg = useOrganizationStore(state => state.resetOrg);

    const resetAdmin = useAdminStore(state => state.resetAdmin);

    const [token, setToken] = useLocalStorage('tokens', '');
    const [userEmail, setUserEmail] = useLocalStorage('email', '');

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleDashboardPage = () => {
        if (userOrganizations.length !== 0) {
            setValue(0);
            setCurrentOrganization(userOrganizations[0]);
            const { naziv, id } = userOrganizations[0];

            doFetch([
                {
                    func: getMembers,
                    info: [token.access, naziv],
                },
                {
                    func: data => {
                        setMembers(data);
                        setMembersCopy(data);
                    },
                },
                { func: getLogo, info: [token.access, id] },
                { func: data => setLogo(data) },
                {
                    func: getOrganizationRanks,
                    info: [token.access, id],
                },
                { func: data => setRanks(data) },
            ]);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        setToken('0-0');
        setUserEmail('');
        //localStorage.setItem('tokens', '0-0');
    };

    useEffect(() => {
        if (!token?.access && !userEmail) {
            resetMembers();
            resetOrg();
            resetAdmin();
            clearUserData();
        }
    }, [clearUserData, token, userEmail, resetAdmin, resetOrg, resetMembers]);

    useEffect(() => {
        if (userData?.id) {
            setFirstName(userData['first name: ']);
            setLastName(userData['last name ']);
        }
    }, [userData]);

    return (
        <>
            <Tooltip title='Račun'>
                <IconButton onClick={handleClick} size='medium'>
                    <Avatar sx={{ bgcolor: blue[500] }}>
                        {userData?.is_superuser
                            ? 'A'
                            : `${firstName.charAt(0)} ${lastName.charAt(0)}`}
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 2,
                        '& .MuiAvatar-root': {
                            width: 36,
                            height: 36,
                            ml: -0.5,
                            mr: 1.5,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 22,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={handleDashboardPage}
                    component={Link}
                    to={
                        userData?.is_superuser
                            ? '/dashboard/admin-organization/inactive'
                            : '/dashboard'
                    }
                >
                    <Avatar sx={{ bgcolor: blue[500] }} />{' '}
                    {userData?.is_superuser ? 'Admin' : `${firstName} ${lastName}`}
                </MenuItem>
                <Divider />
                <MenuItem sx={{ mb: 1 }} component={Link} to={`${url}/create`}>
                    <ListItemIcon>
                        <AddCircleOutlineIcon fontSize='medium' />
                    </ListItemIcon>
                    Kreirajte udrugu
                </MenuItem>
                <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                        <Logout fontSize='medium' />
                    </ListItemIcon>
                    Odjava
                </MenuItem>
            </Menu>
        </>
    );
}
