import { BASE_URL } from '../baseUrl';

export const addMembershipType = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADD_MEMBERSHIP_TYPE}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const deleteMembershipType = (token, vrsta_clanstva_id, clan_id, signal) => {
    return fetch(
        `${BASE_URL}${process.env.REACT_APP_DELETE_MEMBERSHIP_TYPE}${vrsta_clanstva_id},${clan_id}`,
        {
            credentials: 'include',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'DELETE',
            signal,
        }
    );
};
