export const membershipFeeEditRules = [
    {
        name: 'iznos_redovni',
        label: 'Iznos članarine za redovne članove',
        type: 'number',
        defaultValue: 'iznos_redovni',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
    },
    {
        name: 'iznos_pridruzeni',
        label: 'Iznos članarine za pridruzene članove',
        type: 'number',
        defaultValue: 'iznos_pridruzeni',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
    },
    {
        name: 'iznos_pocasni',
        label: 'Iznos članarine za počasne članove',
        type: 'number',
        defaultValue: 'iznos_pocasni',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
    },
    {
        name: 'iznos_ostalo',
        label: 'Iznos članarine za ostale članove',
        type: 'number',
        defaultValue: 'iznos_ostalo',
        rules: {
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
    },
];
