import { BASE_URL } from '../baseUrl';

export const getTransfer = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_GET_TRANSFER}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const addTransfer = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADD_TRANSFER}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        method: 'POST',
        signal,
    });
};

export const editTransfer = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_TRANSFER}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};
