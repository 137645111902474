import React from 'react';
import { DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import ListComp from '../ListComp/ListComp';
import CloseIcon from '@mui/icons-material/Close';
import { useShowModalOptions } from '../Store/Store';

const roles = [
    {
        desc: 'AU',
        info: ['Administrator udruga (najviša moguća razina ovlaštenja u udruzi)'],
        text: true,
    },
    {
        desc: 'UU',
        info: ['Upravitelj udruge (visoka razina ovlaštenja u udruzi)'],
        text: true,
    },
    {
        desc: 'KU',
        info: ['Korisnik udruge (razina ograničena na pregled podataka)'],
        text: true,
    },
    {
        desc: 'ČU',
        info: ['član udruge (trenutno nema ovlaštenja)'],
        text: true,
    },
];

const legend = [
    {
        desc: 'AU, UU, KU',
        info: ['pristup osnovnim informacijama o udruzi'],
        text: true,
    },
    {
        desc: 'AU, UU',
        info: ['izmjena adrese i kontakta udruge'],
        text: true,
    },
    {
        desc: 'AU',
        info: ['brisanje udruge iz sustava'],
        text: true,
    },
];

const Help = () => {
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleClose = () => setShowModal({ show: false, Comp: '' });

    return (
        <>
            <DialogTitle
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant='h5'>Ovlaštenja u udruzi</Typography>
                <IconButton aria-label='close' onClick={handleClose} color='primary'>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant='h6'>Razine ovlaštenja u udruzi:</Typography>
                <ListComp listItems={roles} />
            </DialogContent>
            <DialogContent dividers>
                <Typography pb={2} variant='h6'>
                    Dodjela ovlaštenja:
                </Typography>
                <Typography pb={1} component='p'>
                    Kreiranje udruge u sustavu dopušteno je svakom registriranom
                    korisniku. Po dovršetku tog procesa, administrator sustava mora
                    aktivirati udrugu, uz uvjet da su dati podaci ispravni. Korisnik koji
                    je kreirao udrugu, automatski ima AU razinu ovlaštenja u toj udruzi.
                </Typography>
                <Typography pb={1} component='p'>
                    Samo korisnik s AU razinom u udruzi može drugom korisniku dodijeliti
                    razinu ovlaštenja za upravljanje tom udrugom. Taj se korisnik, kojem
                    želimo dodijeliti razinu ovlaštenja, mora prvo registrirati u sustav.
                    A zatim mu se na temelju email adrese s kojom se prijavio dodjeljuje
                    željena razina ovlaštenja na određeni vremenski rok.
                </Typography>
                <Typography pb={1} component='p'>
                    Pojedini korisnik kada se prijavi u sustav može vidjeti listu svih
                    udruga u kojima ima aktivne razine ovlaštenja AU, UU i KU. Kojim sve
                    podacima ima pristup i na koji način, za pojedinu udrugu, ovisit će o
                    razini ovlaštenja koju ima u određenoj udruzi.
                </Typography>
            </DialogContent>
            <DialogContent dividers>
                <Typography variant='h6'>Legenda:</Typography>
                <ListComp listItems={legend} />
            </DialogContent>
        </>
    );
};

export default Help;
