import { ErrorMessage } from '@hookform/error-message';
import { TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';

const TextRender = props => {
    const {
        onChange,
        type,
        value,
        name,
        errors,
        label,
        helperText,
        multiline,
        rows,
        relatedPair,
        setMain,
    } = props;

    useEffect(() => {
        if (relatedPair.length !== 0) {
            const fieldName = relatedPair.find(e => e.related === name);

            if (fieldName) {
                setMain({ [name]: value });
            }
        }
    }, [relatedPair, name, setMain, value]);

    return (
        <TextField
            multiline={multiline}
            rows={rows}
            fullWidth
            onChange={onChange}
            variant='outlined'
            type={type}
            value={value === 0 || value !== null ? value : ''}
            label={<p>{label}</p>}
            helperText={
                (Object.keys(errors).length !== 0 && errors[name] && (
                    <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({ message }) => (
                            <Typography
                                component='span'
                                variant='caption'
                                color='inherit'
                            >
                                {message}
                            </Typography>
                        )}
                    />
                )) ||
                helperText
            }
            error={errors && (errors[name] ? true : false)}
        />
    );
};

export default TextRender;
