import React from 'react';
import { Divider } from '@mui/material';
import EditFormPayment from './EditFormPayment';
import EditFormSpecific from './EditFormSpecific';

const EditForm = ({ currentRow }) => {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <EditFormPayment currentRow={currentRow} />
                <Divider style={{ height: '12rem' }} orientation='vertical' />
                <EditFormSpecific currentRow={currentRow} />
            </div>
        </>
    );
};

export default EditForm;
