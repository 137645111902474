import React, { useCallback, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableComp from '../../../../TableComp/TableComp';
import Activate from './Activate';
import Deactivate from './Deactivate';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import { deleteStatus, getMemberStatus } from '../../../../../services/members/status';
import { useMembersStore } from '../../../../Store/Store';
import DeleteForever from '@mui/icons-material/DeleteForever';

const statusTableInfo = [
    {
        id: 'status',
        label: 'Status',
        info: id => (id === 1 ? 'Aktivan' : 'Neaktivan'),
        type: 'function',
    },
    { id: 'clan_od', label: 'Aktivan/Neaktivan od', type: 'date' },
    { id: 'delete', btn: true },
];

const EditStatus = ({ filterMember }) => {
    const [{ access }] = useLocalStorage('tokens');
    const [expanded, setExpanded] = useState(false);

    const { doFetch } = useFetch();
    const { doFetch: deleteStatusFetch } = useFetch({ message: { showErrMsg: true } });

    const currentMember = useMembersStore(state => state.currentMember);
    const memberStatus = useMembersStore(state => state.memberStatus);
    const setMemberStatus = useMembersStore(state => state.setMemberStatus);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleGetMemberStatus = useCallback(() => {
        doFetch([
            { func: getMemberStatus, info: [access, currentMember.id] },
            { func: data => setMemberStatus(data) },
        ]);
    }, [doFetch, currentMember.id, access, setMemberStatus]);

    const handleDelete = id => {
        deleteStatusFetch([
            { func: deleteStatus, info: [access, id, currentMember.id] },
            { func: handleGetMemberStatus },
            { func: filterMember },
        ]);
    };

    const btnsArr = [
        {
            name: 'delete',
            onClick: ({ id }) => handleDelete(id),
            icon: <DeleteForever />,
            disabled: memberStatus.length === 1,
        },
    ];

    return (
        <div>
            <Accordion
                style={{ marginBottom: '2rem' }}
                expanded={expanded === 'status'}
                onChange={handleChange('status')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                >
                    <Typography variant='body1'>
                        {currentMember['status(Aktivan/Neaktivan)'] === 'Aktivan'
                            ? 'Deaktivacija člana'
                            : 'Aktivacija člana'}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {currentMember['status(Aktivan/Neaktivan)'] === 'Aktivan' ? (
                        <Deactivate
                            currentMember={currentMember}
                            filterMember={filterMember}
                            handleGetMemberStatus={handleGetMemberStatus}
                        />
                    ) : (
                        <Activate
                            currentMember={currentMember}
                            filterMember={filterMember}
                            handleGetMemberStatus={handleGetMemberStatus}
                        />
                    )}
                </AccordionDetails>
            </Accordion>
            {Array.isArray(memberStatus) && memberStatus.length !== 0 && (
                <TableComp
                    maxTableHeight='24vh'
                    head={statusTableInfo}
                    body={memberStatus}
                    btnsArr={btnsArr}
                    btns
                />
            )}
        </div>
    );
};

export default EditStatus;
