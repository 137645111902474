import React from 'react';
import UpdateForm from '../FormComp/UpdateForm';
import { registerRules } from './registerRules';
import { Paper } from '@mui/material';
import Banner from '../Banner';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';
import useFetch from '../../hooks/useFetch';
import { registerUser } from '../../services/user';
import { useHistory } from 'react-router-dom';

const RegisterForm = () => {
    const history = useHistory();

    const { doFetch } = useFetch({
        message: {
            showErrMsg: true,
            errMsg: 'Došlo je do pogreške pri registraciji',
            showSuccessMsg: true,
            successMsg: 'Potvrdite e-mail adresu kako bi aktivirali račun',
        },
    });

    const handleRegisterUser = ({ email, password, first_name, last_name }) => {
        doFetch([
            {
                func: registerUser,
                info: [
                    {
                        email,
                        password,
                        first_name: capitalizeFirstLetter(first_name),
                        last_name: capitalizeFirstLetter(last_name),
                    },
                ],
            },
            { func: () => history.push('/resend', { email }) },
        ]);
    };

    return (
        <>
            <Banner />
            <div className='form'>
                <Paper sx={{ width: '24rem' }}>
                    <div className='form__titleContainer'>
                        <h3 className='form__title'>Registracija</h3>
                    </div>
                    <UpdateForm
                        updateInputs={registerRules}
                        onSubmit={handleRegisterUser}
                        btnMessage='Registriraj se'
                    />
                </Paper>
            </div>
        </>
    );
};

export default RegisterForm;
