import React from 'react';
import { Button, Divider, Drawer, Toolbar, IconButton, Stack } from '@mui/material';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useOrganizationStore, useUserStore } from '../Store/Store';
import UsersOrganizations from './Organizations/UsersOrganizations';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import pdf from '../../images/Pravila_privatnosti-udruga.pdf';
import secondPdf from '../../images/Uvjeti korištenja-udruga.pdf';
import Administration from './Admin/Administration/Administration';

const LeftSideDrawer = ({ drawerWidth, open, handleDrawerClose, handleGetUserOrg }) => {
    const { url } = useRouteMatch();

    const userOrganizations = useOrganizationStore(state => state.userOrganizations);
    const userData = useUserStore(state => state.userData);

    return userOrganizations?.length >= 0 ? (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant='persistent'
            open={open}
            anchor='left'
        >
            <Toolbar sx={{ height: '10vh' }}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
            </Toolbar>
            <UsersOrganizations handleGetUserOrg={handleGetUserOrg} />
            <Divider />
            {userData?.is_superuser && (
                <>
                    <Stack spacing={2} m={2}>
                        <Button
                            component={Link}
                            to={`${url}/admin-organization/inactive`}
                            variant='outlined'
                            fullWidth
                        >
                            Administracija udruga
                        </Button>
                        <Button
                            component={Link}
                            to={`${url}/statistic`}
                            variant='outlined'
                            fullWidth
                        >
                            Statistika
                        </Button>
                    </Stack>
                    <Divider />
                </>
            )}
            <Stack direction='row' gap={2} sx={{ p: 3 }} justifyContent='space-between'>
                <Button
                    size='small'
                    rel='noreferrer'
                    href={pdf}
                    target={'_blank'}
                    component='a'
                >
                    Pravila privatnosti
                </Button>
                <Button
                    size='small'
                    rel='noreferrer'
                    href={secondPdf}
                    target={'_blank'}
                    component='a'
                >
                    Uvjeti korištenja
                </Button>
            </Stack>
        </Drawer>
    ) : (
        userData?.is_superuser && (
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant='persistent'
                open={open}
                anchor='left'
            >
                <Administration />
            </Drawer>
        )
    );
};

export default LeftSideDrawer;
