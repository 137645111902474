import React from 'react';
import Header from './Header/Header';

const Banner = () => {
    return (
        <div className='banner'>
            <Header />
        </div>
    );
};

export default Banner;
