import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useLocation } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { resendEmail } from '../../services/user';
import Banner from '../Banner';

const ResendEmail = () => {
    const { state } = useLocation();
    const { doFetch } = useFetch({
        message: {
            showErrMsg: true,
            errMsg: 'Korisnik sa ovom e-mail adresom nije prepoznat',
            showSuccessMsg: true,
            successMsg: 'E-mail je uspješno poslan',
        },
    });

    const handleResendEmail = () => {
        doFetch([{ func: resendEmail, info: [{ email: state.email }] }]);
    };

    return (
        <>
            <Banner />
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Paper sx={{ width: '24rem' }}>
                    <Typography variant='h6' align='center' sx={{ m: 3 }}>
                        Niste dobili email za aktivaciju računa ?
                    </Typography>
                    <Box textAlign='center'>
                        <Button
                            onClick={handleResendEmail}
                            sx={{ mb: 2 }}
                            variant='outlined'
                        >
                            Pošalji ponovno
                        </Button>
                    </Box>
                </Paper>
            </div>
        </>
    );
};

export default ResendEmail;
