import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ListComp from '../../ListComp/ListComp';

const OrganizationDetails = props => {
    const {
        data: {
            skraceni,
            oib,
            reg_broj,
            sjediste: { street_name, street_numb, city, state },
            kontakt: { email, mobitel, tel_broj },
            ljecnicki_pregled,
            iban,
        },
    } = props;
    const [expanded, setExpanded] = useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const details = [
        { desc: 'Skraćeni naziv', info: [skraceni], text: true },
        { desc: 'OIB', info: [oib], text: true },
        { desc: 'IBAN', info: [iban], text: true },
        { desc: 'Registarski broj', info: [reg_broj], text: true },
        { desc: 'Liječnički pregled', info: [ljecnicki_pregled], checkbox: true },
        {
            info: ['Kontakt i adresa'],
            btn: 'collapse',
            desc: 'Kontakt i adresa',
            collapseInfo: [
                {
                    info: [street_name, street_numb, city, state],
                    icon: <LocationOnIcon />,
                    text: true,
                },
                { info: [tel_broj], icon: <PhoneIcon />, text: true },
                { info: [mobitel], icon: <PhoneIcon />, text: true },
                { info: [email], icon: <EmailIcon />, text: true },
            ],
            CollapseComp: ListComp,
        },
    ];

    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
            >
                <Typography variant='body1'>Osnovne informacije o udruzi</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ListComp listItems={details} />
            </AccordionDetails>
        </Accordion>
    );
};

export default OrganizationDetails;
