import React from 'react';
import TableCollapse from '../../../../TableComp/TableCollapse';
import TableComp from '../../../../TableComp/TableComp';
import { useMembersStore, useShowModalOptions } from '../../../../Store/Store';
import { Button, Typography } from '@mui/material';
import EditForm from './EditForm/EditForm';
import { useParams } from 'react-router-dom';
import AddMembership from './AddMembership';
import Transfer from './Transfer';

const membershipInfo = [
    { id: 'status_placanja', label: 'Status plaćanja', type: 'string' },
    { id: 'iznos_uplate', label: 'Iznos uplate', type: 'string' },
    { id: 'iznos_clanarine', label: 'Iznos članarine', type: 'string' },
    { id: 'proizvoljno_definirana', label: 'Proizvoljno definirana', type: 'boolean' },
    { id: 'datum_placanja', label: 'Datum plaćanja', type: 'date' },
    { id: 'razdoblje_uplate', label: 'Razdoblje uplate', type: 'date' },
    { id: '', label: '', btn: true, collapse: true },
];

const Membership = () => {
    const { id, member_id } = useParams();

    const memberPayment = useMembersStore(state => state.memberPayment);
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    return Array.isArray(memberPayment) && memberPayment?.length !== 0 ? (
        <>
            <div style={{ marginBottom: '1rem' }}>
                <Button
                    style={{ marginRight: '1rem' }}
                    variant='outlined'
                    onClick={() =>
                        setShowModal({
                            show: true,
                            Comp: Transfer,
                            dialogSize: 'sm',
                            info: { members: [member_id], id },
                        })
                    }
                >
                    Uplatnica
                </Button>
            </div>
            <TableComp head={membershipInfo} body={memberPayment}>
                <TableCollapse>
                    <EditForm />
                </TableCollapse>
            </TableComp>
        </>
    ) : (
        <>
            <Typography variant='h6' align='center' mb={2} mt={3}>
                Nije moguće doći do članarine
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant='outlined'
                    onClick={() =>
                        setShowModal({
                            show: true,
                            Comp: AddMembership,
                            dialogSize: 'md',
                            info: { member_id },
                        })
                    }
                >
                    Definiraj članarinu
                </Button>
            </div>
        </>
    );
};

export default Membership;
