export const addFunctionAllMembersRules = [
    {
        name: 'funkcija',
        label: 'Funkcija',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 30,
            },
        },
    },
    {
        name: 'pocetak_duznosti',
        label: 'Početak dužnosti',
        input: 'date',
        rules: { required: 'Ovo polje je obavezno' },
    },
    {
        name: 'kraj_duznosti',
        label: 'Kraj dužnosti',
        input: 'date',
        rules: { required: 'Ovo polje je obavezno' },
    },
];
