import React from 'react';
import { useParams } from 'react-router-dom';
import formatDate from '../../../../../../helpers/formatDate';
import useFetch from '../../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../../hooks/useLocalStorage';
import UpdateForm from '../../../../../FormComp/UpdateForm';
import { addFunctionRules } from './addFunctionRules';
import {
    addFunction,
    getAllMemberFunction,
} from '../../../../../../services/members/function';
import { useMembersStore } from '../../../../../Store/Store';

const AddFunctionForm = ({ filterMember }) => {
    const { member_id, id } = useParams();
    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch({ message: { showErrMsg: true } });

    const setAllMemberFunction = useMembersStore(state => state.setAllMemberFunction);

    const handleAddFunction = data => {
        const { funkcija, kraj_duznosti, pocetak_duznosti } = data;
        doFetch([
            {
                func: addFunction,
                info: [
                    access,
                    {
                        funkcija,
                        kraj_duznosti: formatDate(kraj_duznosti),
                        pocetak_duznosti: formatDate(pocetak_duznosti),
                        clan: member_id,
                    },
                ],
            },
            { func: getAllMemberFunction, info: [access, id] },
            { func: data => setAllMemberFunction(data) },
            { func: filterMember },
        ]);
    };

    return (
        <UpdateForm
            updateInputs={addFunctionRules}
            onSubmit={handleAddFunction}
            btnMessage='Dodaj'
            styleContainer='updateForm__container updateForm__container-3c'
            clearFields
        />
    );
};

export default AddFunctionForm;
