import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { addDefaultValues, createDefaultValues } from '../../../../helpers/editHelpers';
import formatDate from '../../../../helpers/formatDate';
import { roles } from '../../../../helpers/options';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import UpdateForm from '../../../FormComp/UpdateForm';
import {
    editUsersWithRole,
    getUsersWithRole,
} from '../../../../services/organizations/roles';
import { editUsersWithRoleRules } from './editUserWithRoleRules';
import filterRole from '../../../../helpers/filterRole';
import { useOrganizationStore } from '../../../Store/Store';

const EditUserRoleForm = ({ currentRow, handleGetUsersWithRole }) => {
    const { id } = useParams();
    const [{ access }] = useLocalStorage('tokens');

    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const { doFetch } = useFetch({ message: { showErrMsg: true } });
    const setUsersWithRole = useOrganizationStore(state => state.setUsersWithRole);

    const handleEditUsersWithRole = data => {
        const { email, role, pocetak, kraj } = data;

        doFetch([
            {
                func: editUsersWithRole,
                info: [
                    access,
                    id,
                    {
                        email,
                        role,
                        od: formatDate(pocetak),
                        do: formatDate(kraj),
                    },
                ],
            },
            { func: getUsersWithRole, info: [access, id] },
            {
                func: data =>
                    data.length !== 0 &&
                    setUsersWithRole(
                        data.map(el => {
                            return { ...el, role: filterRole(el.role) };
                        })
                    ),
            },
        ]);
    };
    useEffect(() => {
        setEditFields(
            addDefaultValues(editUsersWithRoleRules, {
                ...currentRow,
                role: roles.filter(el => el.naziv === currentRow.role)[0].pk,
            })
        );
    }, [currentRow]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        <UpdateForm
            updateInputs={editFields}
            onSubmit={handleEditUsersWithRole}
            btnMessage='Promijeni'
            updateDisable={true}
            defaultValues={defaultValues}
            styleContainer='updateForm__container updateForm__container-2c'
        />
    );
};

export default EditUserRoleForm;
