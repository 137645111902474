export const editOrganizationRules = [
    {
        name: 'naziv',
        label: 'Naziv',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 150,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'naziv',
    },
    {
        name: 'skraceni',
        label: 'Kratki naziv',
        rules: {
            maxLength: {
                value: 50,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'skraceni',
    },
    {
        name: 'tel_broj',
        label: 'Telefonski broj',
        rules: {
            maxLength: {
                value: 9,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'tel_broj',
    },
    {
        name: 'mobitel',
        label: 'Mobitel',
        rules: {
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'mobitel',
    },
    {
        name: 'email',
        label: 'E-mail adresa',
        rules: {
            maxLength: {
                value: 255,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^\S+@\S+$/,
                message: 'Pogrešna e-mail adresa',
            },
        },
        defaultValue: 'email',
    },
    {
        name: 'state',
        label: 'Država',
        rules: {
            maxLength: {
                value: 55,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'state',
    },
    {
        name: 'city',
        label: 'Grad',
        rules: {
            maxLength: {
                value: 55,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'city',
    },
    {
        name: 'postal_number',
        label: 'Poštanski broj',
        rules: {
            maxLength: {
                value: 55,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'postal_number',
    },
    {
        name: 'street_name',
        label: 'Naziv ulice',
        rules: {
            maxLength: {
                value: 55,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'street_name',
    },
    {
        name: 'street_numb',
        label: 'Broj ulice',
        rules: {
            maxLength: {
                value: 5,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^[a-zA-Z0-9]*$/,
            },
        },
        defaultValue: 'street_numb',
    },
];
