import React from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { useShowModalOptions } from '../../../Store/Store';

const defaultBtnMsgs = { confirm: 'Prihvati', close: 'Zatvori' };

const ConfirmModal = ({ title, content, handleConfirm, submitParams }) => {
    const setShowModal = useShowModalOptions(state => state.setShowModal);
    const handleClose = () => setShowModal({ show: false, Comp: '' });

    return (
        <>
            {title && <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>}
            <DialogContent>
                <Typography pt={1} component='p'>
                    {content}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button color='error' variant='outlined' onClick={handleClose}>
                    {defaultBtnMsgs.close}
                </Button>
                <Button
                    variant='contained'
                    onClick={() => handleConfirm(...submitParams)}
                    autoFocus
                >
                    {defaultBtnMsgs.confirm}
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmModal;
