import { Stack, Typography } from '@mui/material';
import React from 'react';

const AddOrganizaionFinalStep = () => {
    return (
        <Stack direction='row' justifyContent='center' my={10}>
            <Typography color='primary' align='center' mx={4} variant='h4'>
                Zahtjev za kreiranje udruge je uspješno poslan!
            </Typography>
        </Stack>
    );
};

export default AddOrganizaionFinalStep;
