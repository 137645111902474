import React from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ErrorMessage } from '@hookform/error-message';

const MultiSelectRender = props => {
    const { value, onChange, name, errors, data, label, helperText } = props;

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth error={errors && (errors[name] ? true : false)}>
                <InputLabel id='demo-multiple-name-label'>{label}</InputLabel>
                <Select
                    fullWidth
                    labelId='demo-multiple-name-label'
                    id='demo-multiple-name'
                    multiple
                    value={value || []}
                    onChange={onChange}
                    input={<OutlinedInput label={label} />}
                >
                    {data.data.map((el, i) => (
                        <MenuItem key={`${el[data.value]}+${i}`} value={el[data.value]}>
                            {el[data.label]}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    {Object.keys(errors).length !== 0 && errors[name] ? (
                        <ErrorMessage
                            errors={errors}
                            name={name}
                            render={({ message }) => (
                                <Typography
                                    component='span'
                                    variant='caption'
                                    color='inherit'
                                >
                                    {message}
                                </Typography>
                            )}
                        />
                    ) : (
                        <Typography component='span' variant='caption' color='inherit'>
                            {helperText}
                        </Typography>
                    )}
                </FormHelperText>
            </FormControl>
        </Box>
    );
};

export default MultiSelectRender;
