import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import UpdateForm from '../../../FormComp/UpdateForm';
import { useShowModalOptions } from '../../../Store/Store';

const CommentForm = ({ handleSubmit, submitParams, title, addForm }) => {
    const setShowModal = useShowModalOptions(state => state.setShowModal);
    const handleClose = () => setShowModal({ show: false, Comp: '' });

    return (
        <>
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            {addForm ? (
                <DialogContent>
                    <UpdateForm
                        updateInputs={[
                            {
                                name: 'comment',
                                label: 'Komentar',
                                multiline: true,
                                rows: 4,
                                rules: {
                                    required: 'Ovo polje je obavezno',
                                },
                            },
                        ]}
                        onSubmit={data => handleSubmit(data, ...submitParams)}
                        btnMessage='Potvrdi'
                    >
                        <Button onClick={handleClose} color='error' variant='outlined'>
                            Natrag
                        </Button>
                    </UpdateForm>
                </DialogContent>
            ) : (
                <DialogActions>
                    <Button color='error' variant='outlined' onClick={handleClose}>
                        Natrag
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => handleSubmit(null, ...submitParams)}
                    >
                        Potvrdi
                    </Button>
                </DialogActions>
            )}
        </>
    );
};

export default CommentForm;
