import { Divider, Step, StepLabel, Stepper } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { addDefaultValues } from '../../helpers/editHelpers';
import BackStepBtn from './BackStepBtn';
import LinkButton from './LinkButton';
import UpdateForm from './UpdateForm';

const StepsForm = ({ updateInputs, styleContainer, onSubmit, hideBackLink }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [fields, setFields] = useState([]);
    const [steps, setSteps] = useState([]);
    const [filteredInputs, setFilteredInputs] = useState([]);
    const [defaultValues, setDefaultValues] = useState({});
    const [finalData, setFinalData] = useState({});

    const handleSubmit = (...rest) => {
        onSubmit(finalData, ...rest);
    };

    const handleNext = data => {
        if (data && data?._reactName !== 'onClick') {
            setFinalData(prev => ({ ...prev, ...data }));
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handlePrevStep = data => {
        if (data && data?._reactName !== 'onClick') {
            setFinalData(prev => ({ ...prev, ...data }));
        }
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    /* useEffect(() => {
        const obj = {};
        updateInputs.map(input => (obj[input.name] = ''));
        setFinalData(obj);
    }, [updateInputs]); */

    useEffect(() => {
        setFields(addDefaultValues(updateInputs, finalData));
    }, [updateInputs, finalData]);

    useEffect(() => {
        const stepsSet = new Set();
        fields.forEach(el => el?.step && stepsSet.add(el.step));
        setSteps([...stepsSet]);
    }, [fields]);

    useEffect(() => {
        setFilteredInputs(steps.map(step => fields.filter(input => input.step === step)));
    }, [steps, fields]);

    useEffect(() => {
        const obj = {};

        if (filteredInputs.length !== 0 && activeStep !== steps.length - 1) {
            filteredInputs[activeStep].map(input => {
                return input.name && (obj[input.name] = finalData[input.name]);
            });
        }

        setDefaultValues(obj);
    }, [activeStep, filteredInputs, finalData, steps]);

    return (
        <Box>
            <Stepper
                style={{ padding: '1rem 0' }}
                activeStep={activeStep}
                alternativeLabel
            >
                {steps?.map?.(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Divider />
            {Array.isArray(filteredInputs) &&
                filteredInputs.length !== 0 &&
                filteredInputs.map(input =>
                    !input[0].Comp
                        ? input[0].step === steps[activeStep] &&
                          Object.keys(input).length !== 0 && (
                              <UpdateForm
                                  styleContainer={styleContainer}
                                  updateInputs={input}
                                  onSubmit={handleNext}
                                  defaultValues={defaultValues}
                                  key={input[0].name}
                                  btnMessage='Dalje'
                              >
                                  {!hideBackLink ? (
                                      <LinkButton linkFuncGoBack btnMessage='Odustani' />
                                  ) : (
                                      <></>
                                  )}
                                  <BackStepBtn
                                      setFinalData={setFinalData}
                                      activeStep={activeStep}
                                      handlePrevStep={handlePrevStep}
                                  />
                              </UpdateForm>
                          )
                        : input[0].step === steps[activeStep] &&
                          input.map(i => {
                              const { Comp, step } = i;

                              return (
                                  <Comp
                                      key={step}
                                      options={{
                                          finalData,
                                          handleSubmit,
                                          handlePrevStep,
                                          handleNext,
                                      }}
                                  />
                              );
                          })
                )}
        </Box>
    );
};

export default StepsForm;
