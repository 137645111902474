import Close from '@mui/icons-material/Close';
import React, { useContext, useState } from 'react';
import DeleteForever from '@mui/icons-material/DeleteForever';
import FileDownload from '@mui/icons-material/FileDownload';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Slide,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import Compressor from 'compressorjs';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { deleteLogo, getLogo, uploadLogo } from '../../../../services/organizations/logo';
import { useOrganizationStore, useShowModalOptions } from '../../../Store/Store';
import { FeedbackDispatch } from '../../../Store/Feedback/FeedbackContext';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const UploadLogo = ({ handleDelete }) => {
    const [selectedFile, setSelectedFile] = useState([]);
    const [showLogo, setShowLogo] = useState('');
    const [deleteDialog, setOpenDeleteDialog] = useState(false);

    const [{ access }] = useLocalStorage('tokens');

    const setShowModal = useShowModalOptions(state => state.setShowModal);
    const { id } = useOrganizationStore(state => state.currentOrganization);
    const logo = useOrganizationStore(state => state.logo);
    const setLogo = useOrganizationStore(state => state.setLogo);

    const handleClose = () => setShowModal({ show: false, Comp: '' });

    const dispatch = useContext(FeedbackDispatch);

    const handleChange = ({ target }) => {
        if (target?.files) {
            setSelectedFile(target.files[0]);
            setShowLogo(URL.createObjectURL(target.files[0]));
            return URL.revokeObjectURL(target.files[0]);
        }
    };

    const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

    const handleCompressedUpload = file => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.5,
                maxHeight: 1024,
                maxWidth: 1024,
                success: res => resolve(new File([res], file.name, { type: res.type })),
                error: err => reject(err),
            });
        });
    };

    const handleUploadLogo = () => {
        dispatch({ type: 'startLoading' });
        new Promise((resolve, reject) => {
            if (!logo.hasOwnProperty('id')) {
                return resolve();
            }
            deleteLogo(access, logo.id).then(res => {
                if (!res.ok) {
                    return reject('Došlo je do greške prilikom brisanja loga');
                }
                return resolve();
            });
        })
            .then(() => handleCompressedUpload(selectedFile))
            .then(slika => uploadLogo(access, { slika, udruga: id }))
            .then(res => {
                if (!res.ok) {
                    return Promise.reject();
                }
                return getLogo(access, id);
            })
            .then(res => {
                if (!res.ok) {
                    return Promise.reject();
                }
                return res.json();
            })
            .then(data => {
                setLogo(data);
                handleClose();
            })
            .catch(err => {
                console.log(err);
                dispatch({
                    type: 'setMessage',
                    message: {
                        open: true,
                        type: 'error',
                        msg: err,
                    },
                });
            })
            .finally(() => dispatch({ type: 'stopLoading' }));
    };

    return (
        <>
            <DialogTitle
                id='alert-dialog-title'
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant='h6'>Logo udruge</Typography>
                <IconButton aria-label='close' onClick={handleClose} color='primary'>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 0, m: 1 }}>
                <Stack justifyContent='center' direction='row' mb={2} mt={1}>
                    <Button variant='outlined' component='label'>
                        <Typography variant='body2' mr={1}>
                            {logo.hasOwnProperty('url') ? 'Promijeni logo' : 'Dodaj logo'}
                        </Typography>
                        <PhotoCamera />
                        <input
                            onChange={handleChange}
                            accept='image/*'
                            type='file'
                            hidden
                        />
                    </Button>
                </Stack>
                {logo.hasOwnProperty('url') &&
                    logo?.url !== null &&
                    logo?.url.length !== 0 && (
                        <List>
                            <ListItem
                                secondaryAction={
                                    <Stack direction='row' spacing={2}>
                                        <Tooltip title='Skini logo'>
                                            <IconButton
                                                disabled={logo?.url === null}
                                                onClick={() =>
                                                    window.open(logo?.url, '_blank')
                                                }
                                            >
                                                <FileDownload />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Obriši logo'>
                                            <IconButton
                                                disabled={!logo.hasOwnProperty('url')}
                                                color='error'
                                                edge='end'
                                                aria-label='delete'
                                                onClick={handleOpenDeleteDialog}
                                            >
                                                <DeleteForever />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{ height: 56, width: 56, mr: 1 }}
                                        src={logo?.url}
                                    >
                                        {logo['şlika'].charAt(0)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ mr: 7 }} primary={logo['şlika']} />
                            </ListItem>
                        </List>
                    )}
                {showLogo.length !== 0 && (
                    <Box
                        component='img'
                        src={showLogo}
                        alt='logo'
                        style={{ width: '100%', padding: '1rem' }}
                    />
                )}
            </DialogContent>
            <Divider />
            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    disabled={selectedFile.length === 0}
                    variant='contained'
                    onClick={handleUploadLogo}
                    autoFocus
                >
                    Prihvati
                </Button>
            </DialogActions>
            <Dialog
                fullWidth
                open={deleteDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDeleteDialog}
                aria-describedby='alert-dialog-slide-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    Jeste li sigurni da želite izbrisati ?
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant='outlined'
                        color='error'
                        onClick={handleCloseDeleteDialog}
                    >
                        Odustani
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() =>
                            handleDelete(false, () => handleCloseDeleteDialog())
                        }
                        autoFocus
                    >
                        Prihvati
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UploadLogo;
