import { Button, Divider, Typography } from '@mui/material';
import React from 'react';
import ListComp from '../../../ListComp/ListComp';

const AddOrganizationConfirmStep = ({
    options: { finalData, handlePrevStep, handleSubmit, handleNext },
}) => {
    const {
        naziv,
        skraceni,
        reg_broj,
        oib,
        iban,
        ljecnicki_pregled,
        tel_broj,
        mobitel,
        email,
        state,
        postal_number,
        city,
        street_name,
        street_numb,
    } = finalData;

    const generalInfo = [
        { desc: 'Naziv', info: [naziv], text: true },
        { desc: 'Skraćeni naziv', info: [skraceni], text: true },
        { desc: 'OIB', info: [oib], text: true },
        { desc: 'Registarski broj', info: [reg_broj], text: true },
        { desc: 'IBAN udruge', info: [iban], text: true },
        {
            desc: 'Lječnički pregled',
            info: [ljecnicki_pregled !== '' ? (ljecnicki_pregled ? 'Da' : 'Ne') : 'Ne'],
            text: true,
        },
    ];

    const contactAndAddress = [
        { desc: 'Telefonski broj', info: [tel_broj], text: true },
        { desc: 'Mobitel', info: [mobitel], text: true },
        { desc: 'E-mail adresa', info: [email], text: true },
        {
            desc: 'Adresa',
            info: [street_name, street_numb, postal_number, city, state],
            text: true,
        },
    ];

    return (
        <>
            <div style={{ paddingTop: '2rem' }}>
                <Typography color='primary' align='center' variant='h6'>
                    Osobni podaci
                </Typography>
                <ListComp
                    listStyle={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                    listItems={generalInfo}
                />
                <Typography color='primary' align='center' mt={2} variant='h6'>
                    Kontakt i adresa
                </Typography>
                <ListComp
                    listStyle={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                    listItems={contactAndAddress}
                />
            </div>
            <Divider style={{ paddingBottom: '1rem' }} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',

                    padding: '1rem',
                }}
            >
                <Button onClick={handlePrevStep} color='error' variant='outlined'>
                    Natrag
                </Button>
                <Button
                    onClick={() => handleSubmit(() => handleNext())}
                    color='primary'
                    variant='contained'
                >
                    Dodaj udrugu
                </Button>
            </div>
        </>
    );
};

export default AddOrganizationConfirmStep;
