export const filterFieldArr = [
    { label: 'Ime', name: 'ime' },
    { label: 'Prezime', name: 'prezime' },
    {
        label: 'Spol',
        name: 'spol',
        input: 'select',
        data: [
            { value: 'm', label: 'Muško' },
            { value: 'ž', label: 'Žensko' },
        ],
    },
    {
        label: 'Status',
        name: 'status(Aktivan/Neaktivan)',
        input: 'select',
        data: [
            { value: 'Aktivan', label: 'Aktivan' },
            { value: 'Neaktivan', label: 'Neaktivan' },
        ],
    },
    {
        label: 'Vrsta članstva',
        name: 'vrsta_clanstva',
        input: 'select',
        data: [
            { value: 'Počasni', label: 'Počasni' },
            { value: 'Pridruženi', label: 'Pridruženi' },
            { value: 'Redovni', label: 'Redovni' },
            { value: 'Ostalo', label: 'Ostalo' },
        ],
    },
    { label: 'Razina osposobljenosti', name: 'rank' },
    {
        label: 'Grupna obavijest',
        name: 'grupna_obavijest',
        input: 'select',
        data: [
            { value: true, label: 'Da' },
            { value: false, label: 'Ne' },
        ],
    },
    {
        label: 'Članarina',
        name: 'clanarina',
        input: 'select',
        data: [
            { value: 'Plaćena', label: 'Plaćena' },
            { value: 'Netočan iznos uplate', label: 'Netoćan iznos uplaćen' },
            { value: 'Neplaćena', label: 'Neplaćena' },
        ],
    },
    { label: 'Napomena', name: 'napomena', key: 'napomena' },
];
