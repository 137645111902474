import React, { useEffect, useState } from 'react';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../helpers/editHelpers';
import formatDate from '../../../../../helpers/formatDate';
import { assemblyStatusType } from '../../../../../helpers/options';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import { editAssembly } from '../../../../../services/organizations/assembly';
import UpdateForm from '../../../../FormComp/UpdateForm';
import { assemblyEditRules } from './assemblyEditRules';

const AssemblyEditForm = ({ currentRow, handleOrganizationAssembly }) => {
    const [{ access }] = useLocalStorage('tokens');

    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const { doFetch } = useFetch({ message: { showErrMsg: true } });

    const handleEditUsersWithRole = data => {
        const { naziv, status, datum_odrzavanja } = data;
        doFetch([
            {
                func: editAssembly,
                info: [
                    access,
                    currentRow.id,
                    {
                        datum: [
                            {
                                datum_odrzavanja: formatDate(datum_odrzavanja),
                            },
                        ],
                        naziv,
                        status,
                    },
                ],
            },
            { func: handleOrganizationAssembly },
        ]);
    };

    useEffect(() => {
        Object.keys(currentRow).length &&
            setEditFields(
                addDefaultValues(assemblyEditRules, {
                    ...currentRow,
                    datum_odrzavanja: currentRow.datumi['datum odrzavanja'] || '',
                    status: assemblyStatusType.filter(
                        el => el.naziv === currentRow['naziv statusa']
                    )[0].pk,
                })
            );
    }, [currentRow]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        <UpdateForm
            updateInputs={editFields}
            onSubmit={handleEditUsersWithRole}
            btnMessage='Promijeni'
            updateDisable={true}
            defaultValues={defaultValues}
            styleContainer='updateForm__container updateForm__container-2c'
        />
    );
};

export default AssemblyEditForm;
