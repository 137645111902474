import React from 'react';
import { Button, Divider, Typography } from '@mui/material';
import formatDateDmy from '../../../../helpers/formatDateDmy';
import { membershipType } from '../../../../helpers/options';
import LinkButton from '../../../FormComp/LinkButton';
import ListComp from '../../../ListComp/ListComp';
import { useOrganizationStore } from '../../../Store/Store';
import TableComp from '../../../TableComp/TableComp';

const AddMemberFinalStep = ({ options: { finalData, handlePrevStep, handleSubmit } }) => {
    const ranks = useOrganizationStore(state => state.ranks);

    const PersonalInfo = [
        { desc: 'Ime', info: [finalData.ime], text: true },
        { desc: 'Prezime', info: [finalData.prezime], text: true },
        { desc: 'OIB', info: [finalData.oib], text: true },
        {
            desc: 'Broj putovnice',
            info: [finalData.broj_putovnice || 'Nije uneseno'],
            text: true,
        },
        {
            desc: 'Datum rođenja',
            info: [formatDateDmy(finalData.datum_rodenja) || ''],
            text: true,
        },
        { desc: 'Spol', info: [finalData.spol === 'm' ? 'Muško' : 'Žensko'], text: true },
    ];

    const contactAndAddress = [
        {
            desc: 'Telefonski broj',
            info: [finalData.tel_broj || 'Nije uneseno'],
            text: true,
        },
        { desc: 'Mobitel', info: [finalData.mobitel], text: true },
        { desc: 'E-mail adresa', info: [finalData.email], text: true },
        {
            desc: 'Adresa',
            info: [
                finalData.street_name,
                finalData.street_numb,
                finalData.city,
                finalData.state,
            ],
            text: true,
        },
    ];

    const details = [
        {
            desc: 'Vrsta članstva',
            info: [],
            func: () => {
                const type = membershipType.filter(
                    ({ pk }) => pk === finalData.vrsta_clanstva
                );
                return type.length === 0 ? '' : type[0].naziv;
            },
            text: true,
        },
        {
            desc: 'Početak članstva',
            info: [formatDateDmy(finalData.pocetak_clanstva) || ''],
            text: true,
        },
        {
            desc: 'Aktivan od',
            info: [formatDateDmy(finalData.clan_od) || ''],
            text: true,
        },
        {
            desc: 'Grupna obavijest',
            info: [finalData.grupna_obavijest ? 'Da' : 'Ne'],
            text: true,
        },
        {
            desc:
                finalData.cinovi.length > 1
                    ? 'Razine osposobljenosti'
                    : 'Razina osposobljenosti',
            info: [],
            func: () => {
                if (finalData.cinovi.length === 0) return 'Nije uneseno';

                const type = finalData.cinovi.map(cin => {
                    return ranks.find(r => r.id === cin)?.naziv_cina;
                });

                return type.join(', ');
            },
            text: true,
        },
        {
            desc: 'Početak',
            info: [formatDateDmy(finalData.cinovi_pocetak) || ''],
            text: true,
        },
        {
            desc: 'Kraj',
            info: [formatDateDmy(finalData.cinovi_kraj) || ''],
            text: true,
        },
        { desc: 'Napomena', info: [finalData.napomena || 'Nije uneseno'], text: true },
    ];

    return (
        <>
            <div style={{ paddingTop: '2rem' }}>
                <Typography color='primary' align='center' variant='h6'>
                    Osobni podaci
                </Typography>
                <ListComp
                    listStyle={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                    listItems={PersonalInfo}
                />
                <Typography color='primary' align='center' mt={2} variant='h6'>
                    Kontakt i adresa
                </Typography>
                <ListComp
                    listStyle={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                    listItems={contactAndAddress}
                />
                <Typography color='primary' align='center' mt={2} variant='h6'>
                    Ostalo
                </Typography>
                <ListComp
                    listStyle={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                    listItems={details}
                />
            </div>
            <Divider style={{ paddingBottom: '1rem' }} />
            {finalData.selectedFile.length !== 0 && (
                <>
                    <Typography my={2} color='primary' align='center' variant='h6'>
                        Pristupnica
                    </Typography>
                    <TableComp
                        head={[{ id: 'name', label: 'Ime datoteke', type: 'string' }]}
                        body={finalData.selectedFile}
                    />
                </>
            )}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    padding: '1rem',
                }}
            >
                <LinkButton linkFuncGoBack btnMessage='Odustani' />
                <Button onClick={handlePrevStep} color='error' variant='outlined'>
                    Natrag
                </Button>
                <Button onClick={handleSubmit} color='primary' variant='contained'>
                    Dodaj člana
                </Button>
            </div>
        </>
    );
};

export default AddMemberFinalStep;
