import { membershipType } from '../../../../helpers/options';
import AddMemberFinalStep from './AddMemberFinalStep';
import MemberFiles from './MemberFiles';

export const membersRules = [
    {
        step: 'Osobni podaci',
        name: 'ime',
        label: 'Ime',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 150,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'ime',
    },
    {
        step: 'Osobni podaci',
        name: 'prezime',
        label: 'Prezime',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 50,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'prezime',
    },
    {
        step: 'Osobni podaci',
        name: 'oib',
        label: 'OIB člana',
        rules: {
            maxLength: {
                value: 11,
            },
            minLength: {
                value: 11,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'oib',
    },
    {
        step: 'Osobni podaci',
        name: 'broj_putovnice',
        label: 'Broj putovnice',
        rules: {
            maxLength: {
                value: 30,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'broj_putovnice',
        helperText: 'Broj putovnice se upisuje samo za strane državljane koji nemaju OIB',
    },
    {
        step: 'Osobni podaci',
        name: 'datum_rodenja',
        label: 'Datum rođenja',
        input: 'date',
        rules: {
            required: 'Ovo polje je obavezno',
        },
        defaultValue: 'datum_rodenja',
    },
    {
        step: 'Osobni podaci',
        name: 'spol',
        label: 'Spol',
        input: 'select',
        data: {
            data: [
                { pk: 'm', naziv: 'Muško' },
                { pk: 'ž', naziv: 'Žensko' },
            ],
            value: 'pk',
            label: 'naziv',
        },
        rules: {
            required: 'Ovo polje je obavezno',
        },
        defaultValue: 'spol',
    },
    {
        step: 'Kontakt i adresa',
        name: 'tel_broj',
        label: 'Telefonski broj',
        defaultValue: 'tel_broj',
    },
    {
        step: 'Kontakt i adresa',
        name: 'mobitel',
        label: 'Mobitel',
        rules: {
            required: 'Ovo polje je obavezno',
        },
        defaultValue: 'mobitel',
    },
    {
        step: 'Kontakt i adresa',
        name: 'email',
        label: 'E-mail adresa',
        defaultValue: 'email',
    },
    {
        step: 'Kontakt i adresa',
        name: 'state',
        label: 'Država',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 55,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'state',
    },
    {
        step: 'Kontakt i adresa',
        name: 'postal_number',
        label: 'Poštanski broj',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 5,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^[0-9]+$/,
            },
        },
        defaultValue: 'postal_number',
    },
    {
        step: 'Kontakt i adresa',
        name: 'city',
        label: 'Grad',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 55,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'city',
    },
    {
        step: 'Kontakt i adresa',
        name: 'street_name',
        label: 'Naziv ulice',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 55,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'street_name',
    },
    {
        step: 'Kontakt i adresa',
        name: 'street_numb',
        label: 'Broj ulice',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 5,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^[a-zA-Z0-9]*$/,
            },
        },
        defaultValue: 'street_numb',
    },
    {
        step: 'Pristupnica',
        Comp: MemberFiles,
    },
    {
        step: 'Ostalo',
        name: 'vrsta_clanstva',
        label: 'Vrsta članstva',
        input: 'select',
        data: {
            data: membershipType,
            value: 'pk',
            label: 'naziv',
        },
        rules: { required: 'Ovo polje je obavezno' },
        defaultValue: 'vrsta_clanstva',
    },
    {
        step: 'Ostalo',
        name: 'pocetak_clanstva',
        label: 'Početak članstva',
        input: 'date',
        defaultValue: 'pocetak_clanstva',
        rules: { required: 'Ovo polje je obavezno' },
    },
    {
        step: 'Ostalo',
        name: 'clan_od',
        label: 'Aktivan od',
        input: 'date',
        defaultValue: 'clan_od',
        rules: { required: 'Ovo polje je obavezno' },
        related: 'pocetak_clanstva',
    },
    {
        step: 'Ostalo',
        name: 'grupna_obavijest',
        label: 'Grupno obavještavanje',
        input: 'checkbox',
        defaultValue: 'grupna_obavijest',
        helperText:
            'Odabrati ako je član dio već postojeće grupe za komunikaciju unutar udruge.',
    },
    {
        step: 'Potvrda',
        Comp: AddMemberFinalStep,
    },
];
