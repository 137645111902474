import React, { useContext, useState } from 'react';
import { alpha, Button, Collapse, Toolbar, Tooltip, Typography } from '@mui/material';
import { useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
import {
    useMembersStore,
    useOrganizationStore,
    useShowModalOptions,
    useTableStore,
} from '../../Store/Store';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterMembers from './FilterMembers';
import Transfer from './MemberDetails/Membership/Transfer';
import { deleteMember, getMembers } from '../../../services/members/members';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { getAllMemberFunction } from '../../../services/members/function';
import { FeedbackDispatch } from '../../Store/Feedback/FeedbackContext';
import ConfirmDeleteModal from '../ConfirmDeleteModal';

const MembersToolbar = ({ selected, setSelected }) => {
    const { id } = useParams();
    const { url } = useRouteMatch();
    const history = useHistory();

    const [{ access }] = useLocalStorage('tokens');
    const [filterBy, setFilterBy] = useState({});

    const { naziv } = useOrganizationStore(state => state.currentOrganization);
    const membershipFee = useOrganizationStore(state => state.membershipFee);

    const openFilter = useTableStore(state => state.openFilter);
    const toggleOpenFilter = useTableStore(state => state.toggleOpenFilter);

    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const membersCopy = useMembersStore(state => state.membersCopy);
    const setMembers = useMembersStore(state => state.setMembers);
    const setMembersCopy = useMembersStore(state => state.setMembersCopy);
    const setAllMemberFunction = useMembersStore(state => state.setAllMemberFunction);
    const currentOrganization = useOrganizationStore(state => state.currentOrganization);

    const dispatch = useContext(FeedbackDispatch);

    const showMessage = ({ msg, type }) => {
        dispatch({
            type: 'setMessage',
            message: { open: true, type, msg },
        });
    };

    const handleClose = () => setShowModal({ show: false, Comp: '' });

    const handleOpenConfirmDelete = () => {
        setShowModal({
            show: true,
            Comp: ConfirmDeleteModal,
            dialogSize: 'xs',
            info: { handleDelete },
        });
    };

    const handleClick = () => {
        toggleOpenFilter(prev => !prev);

        if (openFilter) {
            setMembers(membersCopy);
            setFilterBy({});
        }
    };

    const handleDelete = () => {
        dispatch({ type: 'startLoading' });
        selected
            .reduce((promiseChain, member_id) => {
                return promiseChain.then(() => {
                    return deleteMember(access, member_id).then(res => {
                        if (!res.ok) {
                            return Promise.reject(
                                'Došlo je do greške prilikom brisanja člana'
                            );
                        }
                        return Promise.resolve();
                    });
                });
            }, Promise.resolve())
            .then(() => getMembers(access, naziv))
            .then(res => {
                if (!res.ok) {
                    return Promise.reject(
                        'Došlo je do greške prilikom dohvaćanja članova'
                    );
                }
                return res.json();
            })
            .then(data => {
                setMembersCopy(data);
                setMembers(data);

                if (data.length === 0) {
                    setAllMemberFunction([]);
                    history.push(`${url}/add-member`);
                } else {
                    return getAllMemberFunction(access, id).then(res => {
                        if (!res.ok) {
                            return Promise.reject(
                                'Došlo je do greške prilikom dohvaćanja članova'
                            );
                        }
                        return res.json();
                    });
                }

                return Promise.resolve('');
            })
            .then(data => {
                let msg =
                    selected.length !== 1
                        ? 'Članovi su uspješno obrisani'
                        : 'Član je uspješno obrisan';

                if (Array.isArray(data)) {
                    setAllMemberFunction(data);
                }

                showMessage({ msg, type: 'info' });
            })
            .catch(err => showMessage({ msg: err, type: 'error' }))
            .finally(() => {
                handleClose();
                dispatch({ type: 'stopLoading' });
            });
    };

    return (
        <Toolbar
            sx={{
                display: 'flex',
                flexDirection: 'column',
                ...(selected.length > 0 && {
                    bgcolor: theme =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
            disableGutters
        >
            <div
                style={{
                    padding: '1.6rem 0',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
            >
                {selected.length > 0 ? (
                    <Typography color='inherit' variant='h6'>
                        Broj označenih: {selected.length}
                    </Typography>
                ) : (
                    <Typography variant='h5'>Članovi udruge: {naziv}</Typography>
                )}
                {selected.length > 0 ? (
                    <div>
                        <Button
                            onClick={handleOpenConfirmDelete}
                            color='error'
                            variant='outlined'
                            disabled={currentOrganization?.block}
                        >
                            Obriši
                        </Button>
                        <Tooltip
                            title={
                                membershipFee.length === 0
                                    ? 'Članarina nije definirana'
                                    : ''
                            }
                        >
                            <span>
                                <Button
                                    disabled={membershipFee.length === 0}
                                    style={{ marginLeft: '2rem' }}
                                    variant='contained'
                                    onClick={() =>
                                        setShowModal({
                                            show: true,
                                            Comp: Transfer,
                                            dialogSize: 'sm',
                                            info: { members: selected, id, setSelected },
                                        })
                                    }
                                >
                                    Uplatnica
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                ) : (
                    <div style={{ display: 'flex', gap: '1.4rem' }}>
                        <Tooltip title='Dodaj člana'>
                            <Button
                                component={Link}
                                to={`${url}/add-member`}
                                variant='outlined'
                            >
                                <AddIcon />
                            </Button>
                        </Tooltip>
                        <Button
                            color={!openFilter ? 'primary' : 'error'}
                            variant='outlined'
                            onClick={handleClick}
                        >
                            {!openFilter ? <FilterListIcon /> : <CloseIcon />}
                        </Button>
                    </div>
                )}
            </div>
            <div style={{ width: '100%' }}>
                <Collapse orientation='vertical' in={openFilter}>
                    <FilterMembers filterBy={filterBy} setFilterBy={setFilterBy} />
                </Collapse>
            </div>
        </Toolbar>
    );
};

export default MembersToolbar;
