import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { getAllMemberFunction } from '../../../../services/members/function';
import { getMembershipFee } from '../../../../services/organizations/membershipFee';
import { getOrganizationInfo } from '../../../../services/organizations/organizations';
import { useMembersStore, useOrganizationStore } from '../../../Store/Store';
import InfoList from './InfoList';

const OrganizationInfo = () => {
    const { id } = useParams();
    const [orgInfo, setOrgInfo] = useState();
    const [newId, setNewId] = useState(null);

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch({ loading: true });

    const setAllMemberFunction = useMembersStore(state => state.setAllMemberFunction);
    const setMembershipFee = useOrganizationStore(state => state.setMembershipFee);
    const userOrganizations = useOrganizationStore(state => state.userOrganizations);

    useEffect(() => {
        newId &&
            doFetch([
                { func: getOrganizationInfo, info: [access, newId] },
                {
                    func: data => {
                        let arr = [];
                        const { top5_duznika_clanarine: top5 } = data;

                        Object.keys(top5).forEach(name => {
                            arr.push({
                                name,
                                unpaid: top5[name][0],
                                debt: top5[name][1],
                            });
                        });

                        setOrgInfo({ ...data, top5_duznika_clanarine: arr });
                    },
                },
                { func: getAllMemberFunction, info: [access, newId] },
                { func: data => setAllMemberFunction(data) },
                { func: getMembershipFee, info: [access, newId] },
                {
                    func: data => {
                        if (Array.isArray(data)) {
                            setMembershipFee(data);
                        }
                    },
                },
            ]);
    }, [access, doFetch, setAllMemberFunction, setMembershipFee, newId]);

    useEffect(() => {
        if (!id && userOrganizations.length !== 0) {
            setNewId(userOrganizations[0].id);
        } else {
            setNewId(id);
        }
    }, [id, userOrganizations]);

    return orgInfo ? <InfoList data={orgInfo} /> : null;
};

export default OrganizationInfo;
