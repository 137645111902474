import { assemblyStatusType } from '../../../../../helpers/options';
import FinalStep from './FinalStep';
import UploadFileAddForm from './UploadFileAddForm';

export const assemblyRules = [
    {
        step: 'Osnovni podatci',
        name: 'naziv',
        label: 'Naziv skupštine',
        type: 'text',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 240,
            },
            minLength: {
                value: 1,
            },
        },
        defaultValue: 'naziv',
    },
    {
        step: 'Osnovni podatci',
        name: 'status',
        label: 'Status',
        input: 'select',
        data: {
            data: assemblyStatusType,
            value: 'pk',
            label: 'naziv',
        },
        rules: {
            required: 'Ovo polje je obavezno',
        },
        defaultValue: 'status',
    },
    {
        step: 'Osnovni podatci',
        name: 'datum_odrzavanja',
        label: 'Datum Održavanja',
        input: 'date',
        defaultValue: 'datum_odrzavanja',
        rules: {
            required: 'Ovo polje je obavezno',
        },
    },
    {
        step: 'Dodavanje PDF-a',
        Comp: UploadFileAddForm,
    },
    {
        step: 'Potvrda',
        Comp: FinalStep,
    },
];
