import { roles } from '../../../../helpers/options';

export const addUsersWithRoleRules = [
    {
        name: 'email',
        label: 'Email',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 255,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^\S+@\S+$/,
                message: 'Pogrešna e-mail adresa',
            },
        },
    },
    {
        name: 'role',
        label: 'Funkcija',
        input: 'select',
        data: {
            data: roles,
            value: 'pk',
            label: 'naziv',
        },
        rules: {
            required: 'Ovo polje je obavezno',
        },
    },
    {
        name: 'kraj',
        label: 'Kraj dužnosti',
        input: 'date',
        rules: {
            required: 'Ovo polje je obavezno',
        },
    },
];
