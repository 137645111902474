import React from 'react';
import { Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TableComp from '../../TableComp/TableComp';
import TableCheckboxToolbar from '../../TableComp/TableCheckboxToolbar';
import MembersToolbar from './MembersToolbar';
import { useMembersStore, useOrganizationStore } from '../../Store/Store';
import { useRouteMatch, useHistory } from 'react-router-dom';

let membersTableInfo = [
    { id: 'ime', label: 'Ime', type: 'string' },
    { id: 'prezime', label: 'Prezime', type: 'string' },
    {
        id: 'spol',
        label: 'Spol',
        info: id => (id === 'm' ? 'Muško' : 'Žensko'),
        type: 'function',
    },
    { id: 'status(Aktivan/Neaktivan)', label: 'Status', type: 'string' },
    { id: 'vrsta_clanstva', label: 'Vrsta članstva', type: 'string' },
    {
        id: 'cinovi',
        label: 'Razina osposobljenosti',
        type: 'function',
        info: ranks => {
            if (ranks.length === 0) return 'Nije uneseno';
            return ranks.map(rank => rank.cin).join(', ');
        },
    },
    { id: 'grupna_obavijest', label: 'Grupna obavijest', type: 'boolean' },
    {
        id: 'clanarina',
        label: `Članarina za ${new Date().getFullYear()}`,
        type: 'string',
    },
    { id: 'napomena', label: 'Napomena', key: 'napomena', type: 'object' },
    { id: 'edit', label: '', btn: true },
];

const MembersTable = () => {
    const { url } = useRouteMatch();
    const history = useHistory();

    const currentOrganization = useOrganizationStore(state => state.currentOrganization);
    const members = useMembersStore(state => state.members);

    const handleNavigteEdit = id => {
        history.push(`${url}/edit-member/${id}`, { call: true });
    };

    const btnsArr = [
        {
            name: 'edit',
            onClick: ({ id }) => handleNavigteEdit(id),
            icon: <EditIcon />,
            disabled: currentOrganization?.block,
        },
    ];

    return (
        Object.keys(currentOrganization).length !== 0 && (
            <Paper sx={{ margin: '1rem 3vw' }}>
                <TableComp
                    //maxTableHeight='56vh'
                    head={
                        currentOrganization.ljecnicki_pregled
                            ? [
                                  ...membersTableInfo.slice(0, 8),
                                  {
                                      id: 'medical_status',
                                      label: 'Liječnički pregled',
                                      type: 'boolean',
                                  },
                                  ...membersTableInfo.slice(8),
                              ]
                            : membersTableInfo
                    }
                    body={members}
                    rowOnClick={({ id }) => handleNavigteEdit(id)}
                    pagination
                    btnsArr={btnsArr}
                    btns
                >
                    <TableCheckboxToolbar>
                        <MembersToolbar />
                    </TableCheckboxToolbar>
                </TableComp>
            </Paper>
        )
    );
};

export default MembersTable;
