import React, { useCallback, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Transfer from './Transfer/Transfer';
import EmailBody from './EmailBody/EmailBody';
import MembershipFeeTable from './MembershipFeeTable';
import useFetch from '../../../../hooks/useFetch';
import { getMembershipFee } from '../../../../services/organizations/membershipFee';
import { useParams } from 'react-router-dom';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { useOrganizationStore } from '../../../Store/Store';

const membershipFeeFormsDefault = [
    {
        name: 'Članarine za udrugu',
        Comp: MembershipFeeTable,
        disabled: false,
    },
    {
        name: 'Tekstualni sadržaj obrasca za uplatu članarine',
        Comp: Transfer,
        disabled: false,
    },
    {
        name: 'Tekstualni sadržaj email poruke za slanje uplatnice',
        Comp: EmailBody,
        disabled: false,
    },
];

const MembershipFee = () => {
    const { id } = useParams();
    const [membershipFeeForms, setMembershipFeeForms] = useState(
        membershipFeeFormsDefault
    );
    const [expanded, setExpanded] = useState(false);
    const [{ access }] = useLocalStorage('tokens');

    const { doFetch } = useFetch();

    const setMembershipFee = useOrganizationStore(state => state.setMembershipFee);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleGetMembershipFee = useCallback(() => {
        doFetch([
            { func: getMembershipFee, info: [access, id] },
            {
                func: data => {
                    if (Array.isArray(data)) {
                        setMembershipFee(data);
                        data.length === 0
                            ? setMembershipFeeForms(
                                  membershipFeeFormsDefault.map(m =>
                                      m.Comp === Transfer || m.Comp === EmailBody
                                          ? { ...m, disabled: true }
                                          : m
                                  )
                              )
                            : setMembershipFeeForms(membershipFeeFormsDefault);
                    }
                },
            },
        ]);
    }, [access, id, doFetch, setMembershipFee]);

    useEffect(() => handleGetMembershipFee(), [handleGetMembershipFee]);

    return (
        <Paper style={{ maxWidth: '48rem', margin: '0 auto', padding: '0.2rem 0' }}>
            {membershipFeeForms.map(({ name, Comp, disabled }) => (
                <Tooltip
                    followCursor
                    key={name}
                    title={disabled ? 'Članarina mora biti definirana' : ''}
                >
                    <Accordion
                        style={{ margin: '1rem' }}
                        key={name}
                        expanded={expanded === name}
                        onChange={handleChange(name)}
                        disabled={disabled}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1bh-content'
                            id='panel1bh-header'
                        >
                            <Typography variant='body1'>{name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Comp handleGetMembershipFee={handleGetMembershipFee} />
                        </AccordionDetails>
                    </Accordion>
                </Tooltip>
            ))}
        </Paper>
    );
};

export default MembershipFee;
