import { BASE_URL } from '../baseUrl';

export const getMemberStatus = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_GET_MEMBER_STATUS}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const addStatus = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ACTIVATE_MEMBER_STATUS}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const removeStatus = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_DEACTIVATE_MEMBER_STATUS}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const deleteStatus = (token, status_id, clan_id, signal) => {
    return fetch(
        `${BASE_URL}${process.env.REACT_APP_DELETE_MEMBER_STATUS}${status_id},${clan_id}`,
        {
            credentials: 'include',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'DELETE',
            signal,
        }
    );
};
