import React, { useEffect, useMemo, useState } from 'react';
import { Button, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { compareObjWithSameKeys } from '../../helpers/editHelpers';
import empty from '../../helpers/objectValuesToEmpty';
import SimpleForm from './SimpleForm';

const UpdateForm = props => {
    const {
        updateInputs,
        onSubmit,
        btnMessage,
        defaultValues,
        updateDisable,
        confirmPass = true,
        styleContainer,
        clearFields = false,
        children,
        updateDefaultValues,
        noBtn = false,
    } = props;
    const {
        handleSubmit,
        control,
        reset,
        getValues,
        formState: { errors, isSubmitted, isSubmitSuccessful },
        watch,
        clearErrors,
        setValue,
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: useMemo(() => defaultValues, [defaultValues]),
    });

    const [disableBtn, setDisableBtn] = useState(false);
    const [submittedData, setSubmittedData] = useState({});

    let watchFields = watch();

    const onSubmitFunc = (data, e) => {
        setSubmittedData(data);
        onSubmit(data);
    };

    useEffect(() => {
        updateDefaultValues && reset(defaultValues);
    }, [defaultValues, reset, updateDefaultValues]);

    useEffect(() => {
        updateDisable &&
            setDisableBtn(!compareObjWithSameKeys(watchFields, defaultValues));
    }, [watchFields, defaultValues, updateDisable]);

    useEffect(() => {
        isSubmitted && setTimeout(() => clearErrors(), [5000]);
    }, [isSubmitted, clearErrors]);

    useEffect(() => {
        if (clearFields && isSubmitSuccessful) {
            reset(empty(submittedData));
        }
    }, [isSubmitSuccessful, submittedData, reset, clearFields]);

    return (
        <form className='updateForm' onSubmit={handleSubmit(onSubmitFunc)}>
            <SimpleForm
                styleContainer={styleContainer}
                updateInputs={updateInputs}
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                watchFields={watchFields}
                getValues={getValues}
            />

            {!confirmPass && (
                <h4 className='updateForm__confirmPass'>Šifre se ne poklapaju</h4>
            )}

            <Divider />
            {!noBtn && (
                <div className='updateForm__btnContainer'>
                    {React.Children.map(children, child =>
                        React.cloneElement(child, { options: { getValues, control } })
                    )}
                    {btnMessage && (
                        <Button
                            disabled={disableBtn}
                            type='submit'
                            variant='contained'
                            color='primary'
                        >
                            {btnMessage}
                        </Button>
                    )}
                </div>
            )}
        </form>
    );
};

export default UpdateForm;
