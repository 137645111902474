import { BASE_URL } from '../baseUrl';

export const getMembers = (token, organizationName, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_GET_MEMBERS}${organizationName}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const createMembers = async (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADD_MEMBER}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const deleteMember = (token, id, signal) => {
    return fetch(`${BASE_URL}/clan/delete/${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
        signal,
    });
};
