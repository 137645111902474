import React, { useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import {
    deletePdfAssembly,
    getAssembly,
    getAssemblyFile,
    uploadFileAssembly,
} from '../../../../../services/organizations/assembly';
import TableComp from '../../../../TableComp/TableComp';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { Button, Typography } from '@mui/material';
import UploadFile from '@mui/icons-material/UploadFile';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import ConfirmDeleteModal from '../../../ConfirmDeleteModal';
import { useOrganizationStore, useShowModalOptions } from '../../../../Store/Store';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { FeedbackDispatch } from '../../../../Store/Feedback/FeedbackContext';

const UploadFileEditForm = ({
    currentRow,
    handleOrganizationAssembly,
    assemblyFiles,
    setAssemblyFiles,
}) => {
    const [selectedFile, setSelectedFile] = useState([]);
    const [{ access }] = useLocalStorage('tokens');
    const { assembly_id, id } = useParams();
    const dispatch = useContext(FeedbackDispatch);

    const { doFetch: deletePdf } = useFetch({ loading: true });
    const setShowModal = useShowModalOptions(state => state.setShowModal);
    const setAssemblyData = useOrganizationStore(state => state.setAssemblyData);

    const handleChange = ({ target }) => setSelectedFile([...target.files]);
    const handleClose = () => setShowModal({ show: false, Comp: '' });

    const showMessage = ({ msg, type }) => {
        dispatch({
            type: 'setMessage',
            message: { open: true, type, msg },
        });
    };

    const handleDelete = ({ id }) => {
        deletePdf([
            { func: deletePdfAssembly, info: [access, id] },
            { func: getAssemblyFile, info: [access, assembly_id] },
            { func: data => setAssemblyFiles(data) },
            { func: () => handleOrganizationAssembly(() => handleClose()) },
        ]);
    };

    const handleUploadFile = () => {
        dispatch({ type: 'startLoading' });
        const fetchArr = selectedFile.map(file => ({
            func: uploadFileAssembly,
            info: [access, { skupstine_i_sjednice: currentRow.id, pdf: file }],
        }));

        fetchArr
            .reduce((promiseChain, currentFunc) => {
                return promiseChain.then(() => {
                    const { func, info } = currentFunc;

                    return func(...info).then(res => {
                        if (!res.ok) {
                            return Promise.reject();
                        }
                        return Promise.resolve();
                    });
                });
            }, Promise.resolve())
            .then(() => getAssemblyFile(access, assembly_id))
            .then(res => {
                if (!res.ok) {
                    return Promise.reject();
                }
                return res.json();
            })
            .then(files => {
                setAssemblyFiles(files);
                return Promise.all([getAssembly(access, id), files]);
            })
            .then(([res, files]) => {
                if (!res.ok) {
                    return Promise.reject();
                }
                return Promise.all([res.json(), files]);
            })
            .then(([data, files]) => {
                let msg =
                    files.length !== 1
                        ? 'Podatci su uspješno spremljeni'
                        : 'Podatak je uspješno spremljen';

                if (Array.isArray(data)) {
                    setAssemblyData(
                        data.map(m => ({
                            ...m,
                            isPdf: m.pdf,
                            datum_odrzavanja: m.datumi['datum odrzavanja'],
                        }))
                    );
                }

                setSelectedFile([]);
                showMessage({ msg, type: 'info' });
            })
            .catch(err => {
                let msg = 'Došlo je do greške.';
                showMessage({ msg, type: 'error' });
            })
            .finally(() => dispatch({ type: 'stopLoading' }));
    };

    const btnsArr = [
        {
            name: 'delete',
            onClick: ({ id }) =>
                setShowModal({
                    show: true,
                    Comp: ConfirmDeleteModal,
                    dialogSize: 'xs',
                    info: { id, handleDelete },
                }),
            icon: <DeleteForever />,
        },
        {
            name: 'show',
            onClick: ({ url }) => window.open(url, '_blank'),
            icon: <FileDownloadIcon />,
        },
    ];

    const btnsArrSelected = [
        {
            name: 'obriši',
            onClick: file =>
                setSelectedFile(prevFiles =>
                    prevFiles.filter(
                        ({ name, size }) => name !== file.name && size !== file.size
                    )
                ),
            icon: <DeleteForever />,
        },
    ];

    return (
        <div>
            {assemblyFiles.length !== 0 && (
                <TableComp
                    head={[
                        { id: 'name', label: 'Ime dokumenta', type: 'string' },
                        { id: 'delete', label: 'Obriši', btn: true },
                        { id: 'show', label: 'Skinuti', btn: true },
                    ]}
                    body={assemblyFiles}
                    btns
                    btnsArr={btnsArr}
                />
            )}
            <div style={{ margin: '2rem', display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' component='label'>
                    <Typography variant='body2' mr={1}>
                        Dodaj datoteku
                    </Typography>
                    <UploadFile />
                    <input
                        accept='.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        multiple
                        onChange={handleChange}
                        type='file'
                        hidden
                    />
                </Button>
            </div>
            {selectedFile.length !== 0 && (
                <>
                    <TableComp
                        head={[
                            { id: 'name', label: 'Ime datoteke', type: 'string' },
                            { id: 'obriši', label: 'Obriši', btn: true },
                        ]}
                        body={selectedFile}
                        btns
                        btnsArr={btnsArrSelected}
                    />
                    <div
                        style={{
                            marginTop: '2rem',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            endIcon={<DownloadDoneIcon />}
                            variant='outlined'
                            onClick={() => handleUploadFile()}
                        >
                            Potvrdi
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default UploadFileEditForm;
