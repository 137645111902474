import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../helpers/editHelpers';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import UpdateForm from '../../../../FormComp/UpdateForm';
import { membershipFeeEditRules } from './membershipFeeEditRules';
import {
    editMembershipFee,
    getMembershipFee,
} from '../../../../../services/organizations/membershipFee';
import { useOrganizationStore } from '../../../../Store/Store';

const EditForm = ({ currentRow }) => {
    const { godina } = currentRow;
    const { id } = useParams();
    const [{ access }] = useLocalStorage('tokens');

    const { doFetch } = useFetch({ message: { showErrMsg: true } });

    const setMembershipFee = useOrganizationStore(state => state.setMembershipFee);

    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const handleEditMembership = data => {
        const { iznos_redovni, iznos_pridruzeni, iznos_pocasni, iznos_ostalo } = data;

        doFetch([
            {
                func: editMembershipFee,
                info: [
                    access,
                    {
                        iznos_redovni: +iznos_redovni,
                        iznos_pridruzeni: +iznos_pridruzeni,
                        iznos_pocasni: +iznos_pocasni,
                        iznos_ostalo: +iznos_ostalo,
                        godina,
                        udruga: +id,
                    },
                ],
            },
            { func: getMembershipFee, info: [access, id] },
            { func: data => Array.isArray(data) && setMembershipFee(data) },
        ]);
    };

    useEffect(() => {
        setEditFields(addDefaultValues(membershipFeeEditRules, currentRow));
    }, [currentRow]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        <UpdateForm
            updateInputs={editFields}
            onSubmit={handleEditMembership}
            btnMessage='Promijeni'
            updateDisable={true}
            defaultValues={defaultValues}
            styleContainer='updateForm__container updateForm__container-2c'
        />
    );
};

export default EditForm;
