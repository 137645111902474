import React from 'react';

const TableCheckboxToolbar = ({ children, selected, setSelected }) => {
    if (!children) return null;

    return React.Children.map(children, child =>
        React.cloneElement(child, { selected, setSelected })
    );
};

export default TableCheckboxToolbar;
