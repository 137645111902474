import { BASE_URL } from '../baseUrl';

export const addAssembly = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADD_ASSEMBLY}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        method: 'POST',
        signal,
    });
};

export const getAssembly = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_GET_ASSEMBLY}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const editAssembly = (token, id, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_ASSEMBLY}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PUT',
        body: JSON.stringify(data),
        signal,
    });
};

export const getAssemblyFile = (token, id, signal) => {
    return fetch(`${BASE_URL}/skupstina/pdf-get-list/${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const deleteAssembly = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_DELETE_ASSEMBLY}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
        signal,
    });
};

export const uploadFileAssembly = (token, data, signal) => {
    let body = Object.keys(data).reduce((formData, key) => {
        formData.append(key, data[key]);
        return formData;
    }, new FormData());

    return fetch(`${BASE_URL}${process.env.REACT_APP_UPLOAD_FILE_ASSEMBLY}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body,
        signal,
    });
};

export const deletePdfAssembly = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_DELETE_FILE_ASSEMBLY}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
        signal,
    });
};
