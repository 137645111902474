import React, { useEffect } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { TextField, Typography } from '@mui/material';
import { ErrorMessage } from '@hookform/error-message';
//import { hr } from 'date-fns/locale';

const YearPicker = props => {
    const {
        value,
        onChange,
        name,
        errors,
        label,
        helperText,
        disabled,
        relatedPair,
        setMain,
    } = props;

    useEffect(() => {
        if (relatedPair.length !== 0) {
            const fieldName = relatedPair.find(e => e.related === name);

            if (fieldName) {
                setMain({ [name]: value });
            }
        }
    }, [relatedPair, name, setMain, value]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                disabled={disabled}
                views={['year']}
                label={label}
                value={value}
                onChange={newValue => onChange(newValue)}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            label={label}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            helperText={
                                (Object.keys(errors).length !== 0 && (
                                    <ErrorMessage
                                        errors={errors}
                                        name={name}
                                        render={({ message }) => (
                                            <Typography
                                                component='span'
                                                variant='caption'
                                                color='inherit'
                                            >
                                                {message}
                                            </Typography>
                                        )}
                                    />
                                )) ||
                                helperText
                            }
                            error={errors && (errors[name] ? true : false)}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
};

export default YearPicker;
