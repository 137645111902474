export const addFormRules = [
    {
        name: 'datum_pregleda',
        label: 'Datum pregleda',
        input: 'date',
        rules: { required: 'Ovo polje je obavezno' },
    },
    {
        name: 'vrijedi_do',
        label: 'Vrijedi do',
        input: 'date',
        rules: { required: 'Ovo polje je obavezno' },
    },
    {
        name: 'status_pregleda',
        label: 'Pregled obavljen',
        input: 'checkbox',
    },
];
