import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Typography, RadioGroup, Radio, FormControlLabel, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../helpers/editHelpers';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import useFetch from '../../../../../hooks/useFetch';
import UpdateForm from '../../../../FormComp/UpdateForm';
import {
    addTransfer,
    editTransfer,
    getTransfer,
} from '../../../../../services/organizations/transfer';
import { FeedbackDispatch } from '../../../../Store/Feedback/FeedbackContext';
import { addTransferFormRules } from './transferFormRules';
import { useOrganizationStore } from '../../../../Store/Store';

const Transfer = () => {
    const { id } = useParams();
    const [{ access }] = useLocalStorage('tokens');

    const [editForm, setEditForm] = useState(false);
    const [text, setText] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());
    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const handleChange = event => setYear(event.target.value);
    const dispatch = useContext(FeedbackDispatch);

    const handleShowMsg = msg => {
        dispatch({
            type: 'setMessage',
            message: {
                open: true,
                type: 'info',
                msg,
            },
        });
    };

    const {
        response: { transfers },
        doFetch,
    } = useFetch();
    const { doFetch: addEditTransfer } = useFetch({ message: { showErrMsg: true } });

    const membershipFee = useOrganizationStore(state => state.membershipFee);
    const currentOrganization = useOrganizationStore(state => state.currentOrganization);

    const handleGetTransfer = useCallback(() => {
        doFetch([{ func: getTransfer, info: [access, id], id: 'transfers' }]);
    }, [access, doFetch, id]);

    const handleTransfer = ({ tekst }) => {
        let promise;
        let message = '';

        let hasDefault = editFields.filter(field => field.defaultValue.length !== 0);

        promise = addTransfer;
        message = 'Tekst za uplatnicu je uspješno dodan.';

        if (hasDefault.length !== 0) {
            promise = editTransfer;
            message = 'Tekst za uplatnicu je uspješno promijenjen.';
        }

        addEditTransfer([
            { func: promise, info: [access, { udruga: +id, godina: year, tekst }] },
            { func: handleGetTransfer },
            {
                func: () => {
                    handleShowMsg(message);
                    setEditForm(false);
                },
            },
        ]);
    };

    useEffect(() => {
        if (Array.isArray(transfers)) {
            let text = transfers.filter(t => t.godina === +year);

            if (text.length !== 0) {
                setText(text[0].tekst);
                setEditFields(addDefaultValues(addTransferFormRules, text[0]));
            } else {
                setText('');
                setEditFields(addDefaultValues(addTransferFormRules, { tekst: '' }));
            }
        }
    }, [transfers, year]);

    useEffect(() => handleGetTransfer(), [handleGetTransfer]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography align='center' variant='body1'>
                    Za godinu:
                </Typography>
                <RadioGroup
                    row
                    aria-label='gender'
                    name='controlled-radio-buttons-group'
                    value={year}
                    onChange={handleChange}
                >
                    {[...membershipFee].reverse().map(fee => {
                        return (
                            <FormControlLabel
                                key={fee.id}
                                labelPlacement='start'
                                value={fee.godina}
                                control={<Radio size='medium' />}
                                label={fee.godina}
                            />
                        );
                    })}
                </RadioGroup>
            </div>
            {!editForm && text ? (
                <div style={{ margin: '2rem 1rem 1rem 1rem' }}>
                    <Typography mb={2} style={{ display: 'block' }} variant='p'>
                        {text}
                    </Typography>
                    <Button
                        disabled={currentOrganization?.block}
                        onClick={() => setEditForm(!editForm)}
                        variant='contained'
                    >
                        Promijeni
                    </Button>
                </div>
            ) : (
                editFields.length !== 0 && (
                    <UpdateForm
                        updateDefaultValues={true}
                        updateInputs={editFields}
                        onSubmit={handleTransfer}
                        updateDisable={true}
                        defaultValues={defaultValues}
                        btnMessage={text ? 'Promijeni' : 'Dodaj'}
                    >
                        {text ? (
                            <Button
                                onClick={() => setEditForm(!editForm)}
                                variant='outlined'
                                color='error'
                            >
                                Natrag
                            </Button>
                        ) : null}
                    </UpdateForm>
                )
            )}
        </>
    );
};

export default Transfer;
