import React, { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';

const ListComp = ({ listItems, listStyle }) => {
    const [open, setOpen] = useState(false);

    const handleClickCollapse = () => {
        setOpen(!open);
    };

    return (
        <List style={listStyle}>
            {listItems?.map((item, i) => {
                return (
                    <React.Fragment key={i}>
                        {item.btn === 'collapse' && (
                            <>
                                <ListItemButton divider onClick={handleClickCollapse}>
                                    {item.icon && (
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                    )}
                                    <ListItemText primary={item.desc} />
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={open} timeout='auto' unmountOnExit>
                                    <item.CollapseComp listItems={item.collapseInfo} />
                                </Collapse>
                            </>
                        )}
                        {item.btn === 'btn' && (
                            <ListItemButton
                                divider
                                onClick={() => item.handleClick(item)}
                            >
                                <ListItemText
                                    primary={`${item.desc ? item.desc + ': ' : ''} ${
                                        item?.info
                                    }`}
                                />
                                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                            </ListItemButton>
                        )}
                        {item.checkbox &&
                            item?.desc.toString().length !== 0 &&
                            item?.info && (
                                <ListItem divider>
                                    {item.desc && (
                                        <Typography variant='body1'>{`${item.desc}: `}</Typography>
                                    )}
                                    {Array.isArray(item.info) && item.info[0] ? (
                                        <CheckIcon sx={{ marginLeft: '1rem' }} />
                                    ) : (
                                        <ClearIcon sx={{ marginLeft: '1rem' }} />
                                    )}
                                </ListItem>
                            )}
                        {item.text && item?.info.toString().length !== 0 && (
                            <ListItem divider>
                                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                {item.desc && (
                                    <Typography variant='body1'>{`${item.desc}: `}</Typography>
                                )}
                                <Typography
                                    style={{
                                        marginLeft: '4px',
                                        letterSpacing: '0.4px',
                                    }}
                                    component='p'
                                >
                                    {item.info.join(', ')}
                                </Typography>
                            </ListItem>
                        )}
                        {item.text && typeof item?.func === 'function' && (
                            <ListItem divider>
                                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                {item.desc && (
                                    <Typography variant='body1'>{`${item.desc}: `}</Typography>
                                )}
                                <Typography
                                    style={{ marginLeft: '4px', letterSpacing: '0.4px' }}
                                    component='p'
                                >
                                    {item.func()}
                                </Typography>
                            </ListItem>
                        )}
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default ListComp;
