import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import {
    getBlockedOrganization,
    getApprovedOrganizations,
    getInactiveOrganization,
} from '../../../../services/admin';
import { useAdminStore } from '../../../Store/Store';
import { a11yProps, TabPanel } from '../../../TabPanel/TabPanel';
import ApprovedOrganizationsTable from '../ApprovedOrganizations/ApprovedOrganizationsTable';
import InactiveOrganizationsTable from '../InactiveOrganizations/InactiveOrganizationsTable';

const adminTabsArr = [
    { to: 'inactive', label: 'Zahtjevi za odobrenje udruge' },
    { to: 'approved', label: 'Odobrene udruge' },
];

const Administration = ({ handleGetInactiveOrg, handleGetUserOrg }) => {
    const { path, url } = useRouteMatch();
    const [value, setValue] = useState(0);

    const { doFetch, isLoading } = useFetch();
    const [{ access }] = useLocalStorage('tokens');

    const blockedOrgRef = useRef();

    const { pathname } = useLocation();

    const setInactiveOrganization = useAdminStore(state => state.setInactiveOrganization);
    const setApprovedOrganization = useAdminStore(state => state.setApprovedOrganization);

    useEffect(() => {
        setValue(adminTabsArr.findIndex(t => pathname.includes(t.to)));
    }, [pathname]);

    useEffect(() => {
        doFetch([
            { func: getBlockedOrganization, info: [access] },
            { func: data => (blockedOrgRef.current = data) },
            { func: getInactiveOrganization, info: [access] },
            { func: data => setInactiveOrganization(data) },
            { func: getApprovedOrganizations, info: [access] },
            {
                func: data => {
                    setApprovedOrganization(
                        data.map(org => {
                            let blocked = blockedOrgRef.current.find(
                                ({ udruga }) => +udruga === org.id
                            );

                            return blocked ? { ...blocked, ...org } : org;
                        })
                    );
                },
            },
        ]);
    }, [access, doFetch, setApprovedOrganization, setInactiveOrganization]);

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs value={value} aria-label='tabs'>
                {adminTabsArr.map(({ to, label }, i) => (
                    <Tab
                        key={label}
                        component={Link}
                        to={`${url}/${to}`}
                        label={label}
                        {...a11yProps(i)}
                    />
                ))}
            </Tabs>
            <TabPanel index={value} value={value}>
                <Switch>
                    <Route exact path={`${path}/inactive`}>
                        <InactiveOrganizationsTable
                            isLoading={isLoading}
                            handleGetInactiveOrg={handleGetInactiveOrg}
                            handleGetUserOrg={handleGetUserOrg}
                        />
                    </Route>
                    <Route exact path={`${path}/approved`}>
                        <ApprovedOrganizationsTable
                            isLoading={isLoading}
                            handleGetUserOrg={handleGetUserOrg}
                        />
                    </Route>
                </Switch>
            </TabPanel>
        </Box>
    );
};

export default Administration;
