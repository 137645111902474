export const rankRules = [
    {
        name: 'naziv_cina',
        label: 'Naziv razine (čin, brevet, ...)',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 50,
            },
            minLength: {
                value: 1,
            },
        },
    },
];
