import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useFetch from '../../../../hooks/useFetch';
import { useOrganizationStore, useShowModalOptions } from '../../../Store/Store';
import { deleteUserOrganization } from '../../../../services/organizations/organizations';

const DeleteOrganization = props => {
    const { setValue, handleGetUserOrg } = props;
    const history = useHistory();

    const { doFetch } = useFetch();

    const [name, setName] = useState('');

    const handleChange = e => setName(e.target.value);
    const handleClose = () => setShowModal({ show: false, Comp: '' });

    const [{ access }] = useLocalStorage('tokens');

    const { naziv, id } = useOrganizationStore(state => state.currentOrganization);
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleDelete = () => {
        doFetch([
            { func: deleteUserOrganization, info: [access, id] },
            {
                func: () => {
                    handleGetUserOrg(() => {
                        setValue(0);
                        handleClose();
                        history.push('/dashboard');
                    });
                    return null;
                },
            },
        ]);
    };

    return (
        <>
            <DialogTitle id='alert-dialog-title'>
                Jeste li sigurni da želite obrisati udrugu ?
            </DialogTitle>
            <DialogContent>
                <Typography pb={3} component='p'>
                    {`Ovu akciju nije moguće poništiti.    
                    Time ćete trajno izbrisati udrugu ${naziv}. 
                    Upišite ${naziv} kako biste potvrdili.`}
                </Typography>
                <TextField
                    fullWidth
                    onChange={e => handleChange(e)}
                    variant='outlined'
                    type={'text'}
                    value={name}
                    label={<p>Naziv udruge</p>}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='error' onClick={handleClose}>
                    Odustani
                </Button>
                <Button
                    disabled={name !== naziv}
                    variant='contained'
                    onClick={handleDelete}
                    autoFocus
                >
                    Prihvati
                </Button>
            </DialogActions>
        </>
    );
};

export default DeleteOrganization;
