import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import formatDate from '../../../../../helpers/formatDate';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import {
    addFunction,
    getAllMemberFunction,
} from '../../../../../services/members/function';
import LinkButton from '../../../../FormComp/LinkButton';
import UpdateForm from '../../../../FormComp/UpdateForm';
import { useMembersStore } from '../../../../Store/Store';
import { addFunctionAllMembersRules } from './addFunctionAllMembersRules';

const AddFunction = () => {
    const history = useHistory();
    const { id } = useParams();

    const [{ access }] = useLocalStorage('tokens');
    const [memberName, setMemberName] = useState([]);

    const { doFetch } = useFetch();
    const members = useMembersStore(state => state.members);
    const setAllMemberFunction = useMembersStore(state => state.setAllMemberFunction);

    const handleAddFunction = data => {
        const { funkcija, kraj_duznosti, pocetak_duznosti, clan } = data;
        doFetch([
            {
                func: addFunction,
                info: [
                    access,
                    {
                        funkcija,
                        kraj_duznosti: formatDate(kraj_duznosti),
                        pocetak_duznosti: formatDate(pocetak_duznosti),
                        clan,
                    },
                ],
            },
            { func: getAllMemberFunction, info: [access, id] },
            { func: data => setAllMemberFunction(data) },
            { func: () => history.goBack() },
        ]);
    };

    useEffect(() => {
        setMemberName(
            members.map(m => ({ ime_prezime: `${m.ime} ${m.prezime}`, id: m.id }))
        );
    }, [members]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <Paper sx={{ width: '32rem' }}>
                <UpdateForm
                    updateInputs={[
                        {
                            name: 'clan',
                            label: 'Član',
                            input: 'select',
                            data: {
                                data: memberName,
                                value: 'id',
                                label: 'ime_prezime',
                            },
                            rules: { required: 'Ovo polje je obavezno' },
                        },
                        ...addFunctionAllMembersRules,
                    ]}
                    onSubmit={handleAddFunction}
                    btnMessage='Dodaj'
                    clearFields
                >
                    <LinkButton linkFuncGoBack btnMessage='Odustani' />
                </UpdateForm>
            </Paper>
        </div>
    );
};

export default AddFunction;
