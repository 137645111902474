import { Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ListComp from '../../../ListComp/ListComp';
import TableComp from '../../../TableComp/TableComp';
import PieGraph from '../../Organizations/OrganizationInfo/PieGraph';
import ColumnChart from './ColumnChart';

const StatisticItem = ({ info }) => {
    const { title, data, graphData, options, tableData } = info;
    const { graph, additionalList, table } = options;

    return (
        <React.Fragment>
            <Box my={2}>
                {title && (
                    <Typography align='center' color='primary' variant='h6' pb={2} pt={1}>
                        {title}
                    </Typography>
                )}
                <Stack direction='row'>
                    {additionalList && (
                        <Box style={{ flex: '0 0 36%' }}>
                            <ListComp
                                listStyle={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr',
                                }}
                                listItems={data}
                            />
                        </Box>
                    )}
                    {graph?.type === 'pie' && graphData && (
                        <Box style={{ width: '100%', ...graph?.style }}>
                            <PieGraph data={graphData} />
                        </Box>
                    )}
                    {graph?.type === 'column' && graphData && (
                        <Box style={{ width: '100%', ...graph?.style }}>
                            <ColumnChart data={graphData} />
                        </Box>
                    )}
                </Stack>
                {table && <TableComp {...tableData} />}
            </Box>
            <Divider />
        </React.Fragment>
    );
};

export default StatisticItem;
