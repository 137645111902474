import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../helpers/editHelpers';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import {
    addEmailBody,
    editEmailBody,
    getEmailBody,
} from '../../../../../services/organizations/emailBody';
import {
    addTransfer,
    editTransfer,
    getTransfer,
} from '../../../../../services/organizations/transfer';
import UpdateForm from '../../../../FormComp/UpdateForm';
import { useOrganizationStore } from '../../../../Store/Store';
import { emailFormRule, pdfFormRule } from './transferRules';

const TransferText = ({ options: { finalData, handlePrevStep, handleSubmit }, id }) => {
    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch();

    const transfer = useOrganizationStore(state => state.transfer);
    const setTransfer = useOrganizationStore(state => state.setTransfer);
    const emailBody = useOrganizationStore(state => state.emailBody);
    const setEmailBody = useOrganizationStore(state => state.setEmailBody);

    const handlePromise = (editFunc, addFunc, key_name) => {
        let promise;
        let value = editFields.filter(field => field.name === key_name)[0]?.defaultValue;

        value !== '' ? (promise = editFunc) : (promise = addFunc);
        return promise;
    };

    const handlePdfText = pdf_tekst => [
        {
            func: handlePromise(editTransfer, addTransfer, 'pdf_tekst'),
            info: [access, { udruga: +id, godina: finalData.godina, tekst: pdf_tekst }],
        },
        { func: getTransfer, info: [access, id] },
        { func: data => setTransfer(data) },
    ];

    const handleEmailText = email_tekst => [
        {
            func: handlePromise(editEmailBody, addEmailBody, 'email_tekst'),
            info: [
                access,
                { udruga: +id, godina: finalData.godina, body_tekst: email_tekst },
            ],
        },
        { func: getEmailBody, info: [access, id] },
        { func: data => setEmailBody(data) },
    ];

    const handleTextSubmit = ({ pdf_tekst, email_tekst }) => {
        let promise = [{ func: () => null }];

        let defaultPdfText = editFields.filter(e => e.name === 'pdf_tekst')[0]
            ?.defaultValue;
        let defaultEmailText = editFields.filter(e => e.name === 'email_tekst')[0]
            ?.defaultValue;

        // finalData.uplatnica === 2 => download pdf
        if (finalData.uplatnica === 2) {
            if (pdf_tekst !== defaultPdfText) {
                promise = handlePdfText(pdf_tekst);
            }
            // finalData.uplatnica === 1 => send email
        } else if (finalData.uplatnica === 1) {
            if (pdf_tekst !== defaultPdfText || email_tekst !== defaultEmailText) {
                promise = [];

                if (email_tekst !== defaultEmailText) {
                    promise = [...promise, ...handleEmailText(email_tekst)];
                }
                if (pdf_tekst !== defaultPdfText) {
                    promise = [...promise, ...handlePdfText(pdf_tekst)];
                }
            }
        }

        doFetch([...promise, { func: () => handleSubmit(finalData) }]);
    };

    const handleEditFields = useCallback(
        data => {
            let defaultText = { pdf_tekst: '', email_tekst: '' };
            let formRules =
                finalData.uplatnica === 1 ? [emailFormRule, pdfFormRule] : [pdfFormRule];

            if (Array.isArray(data)) {
                let textArr = data.filter(t => t.godina === +finalData.godina);

                if (textArr.length !== 0) {
                    setEditFields(
                        addDefaultValues(formRules, {
                            pdf_tekst: textArr[0].pdf_tekst,
                            email_tekst: textArr[1]?.email_tekst || '',
                        })
                    );
                } else {
                    setEditFields(addDefaultValues(formRules, defaultText));
                }
            } else {
                setEditFields(addDefaultValues(formRules, defaultText));
            }
        },
        [finalData.godina, finalData.uplatnica]
    );

    useEffect(() => {
        if (finalData.uplatnica === 2) {
            handleEditFields(transfer.map(e => ({ ...e, pdf_tekst: e.tekst || '' })));
        } else if (finalData.uplatnica === 1) {
            handleEditFields([
                ...transfer.map(e => ({ ...e, pdf_tekst: e.tekst || '' })),
                ...emailBody.map(e => ({ ...e, email_tekst: e.body_tekst || '' })),
            ]);
        }
    }, [finalData.uplatnica, handleEditFields, emailBody, transfer]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        <UpdateForm
            updateInputs={editFields.length !== 0 ? editFields : [pdfFormRule]}
            onSubmit={handleTextSubmit}
            defaultValues={defaultValues}
            updateDefaultValues
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <Button onClick={handlePrevStep} color='error' variant='outlined'>
                    Natrag
                </Button>
                <Button
                    onSubmit={handleTextSubmit}
                    color='primary'
                    variant='contained'
                    type='submit'
                >
                    Pošalji/skini uplatnicu
                </Button>
            </div>
        </UpdateForm>
    );
};

export default TransferText;
