import React, { useCallback, useEffect } from 'react';
import { Button, Divider, Paper, Typography } from '@mui/material';
import { Switch, Route, useRouteMatch } from 'react-router';
import { Link, useHistory, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { useOrganizationStore, useShowModalOptions } from '../../../Store/Store';
import TableComp from '../../../TableComp/TableComp';
import AssemblyAddForm from './AddForm/AssemblyAddForm';
import TableCollapse from '../../../TableComp/TableCollapse';
import useFetch from '../../../../hooks/useFetch';
import { deleteAssembly, getAssembly } from '../../../../services/organizations/assembly';
import EditAssembly from './EditForm/EditAssembly';
import DeleteForever from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmDeleteModal from '../../ConfirmDeleteModal';

const assemblyTableInfo = [
    { id: 'naziv', label: 'Naziv', type: 'string' },
    { id: 'naziv statusa', label: 'Status', type: 'string' },
    { id: 'datum_odrzavanja', label: 'Datum održavanja', type: 'date' },
    { id: 'isPdf', label: 'Datoteka', type: 'boolean' },
    { id: 'delete', btn: true },
    { id: 'edit', btn: true },
];

const AssemblyTable = () => {
    const history = useHistory();
    const { url, path } = useRouteMatch();
    const [{ access }] = useLocalStorage('tokens');
    const { id } = useParams();

    const {
        error: { error },
        doFetch: handleGetAssembly,
    } = useFetch({ loading: true });
    const { doFetch } = useFetch();

    const assemblyData = useOrganizationStore(state => state.assemblyData);
    const setAssemblyData = useOrganizationStore(state => state.setAssemblyData);
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleClose = () => setShowModal({ show: false, Comp: '' });

    const handleDelete = ({ id }) => {
        doFetch([
            { func: deleteAssembly, info: [access, id] },
            { func: () => handleOrganizationAssembly(() => handleClose()) },
        ]);
    };

    const handleOrganizationAssembly = useCallback(
        cb => {
            handleGetAssembly([
                { func: getAssembly, info: [access, id] },
                {
                    func: data => {
                        if (Array.isArray(data)) {
                            setAssemblyData(
                                data.map(m => ({
                                    ...m,
                                    isPdf: m.pdf,
                                    datum_odrzavanja: m.datumi['datum odrzavanja'],
                                }))
                            );
                            if (data.length === 0) history.push(`${url}/add-assembly`);
                        }
                    },
                },
                { func: cb },
            ]);
        },
        [access, id, handleGetAssembly, setAssemblyData, history, url]
    );

    const btnsArr = [
        {
            name: 'delete',
            onClick: ({ id }) =>
                setShowModal({
                    show: true,
                    Comp: ConfirmDeleteModal,
                    dialogSize: 'xs',
                    info: { id, handleDelete },
                }),
            icon: <DeleteForever />,
        },
        {
            name: 'edit',
            onClick: ({ id }) => history.push(`${url}/edit-assembly/${id}`),
            icon: <EditIcon />,
        },
    ];

    useEffect(() => handleOrganizationAssembly(), [handleOrganizationAssembly]);

    if (error) return null;

    return (
        <Switch>
            <Route exact path={path}>
                <Paper sx={{ maxWidth: '54rem', margin: '1rem auto' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            padding: '1.6rem',
                        }}
                    >
                        <Typography variant='h5'>Popis skupština</Typography>
                        <Button
                            component={Link}
                            to={`${url}/add-assembly`}
                            variant='outlined'
                        >
                            <AddIcon />
                        </Button>
                    </div>
                    <Divider />
                    {assemblyData && (
                        <TableComp
                            head={assemblyTableInfo}
                            body={assemblyData}
                            btns
                            btnsArr={btnsArr}
                        >
                            <TableCollapse>
                                <EditAssembly />
                            </TableCollapse>
                        </TableComp>
                    )}
                </Paper>
            </Route>
            <Route exact path={`${path}/add-assembly`}>
                <AssemblyAddForm
                    handleOrganizationAssembly={handleOrganizationAssembly}
                />
            </Route>
            <Route exact path={`${path}/edit-assembly/:assembly_id`}>
                <EditAssembly handleOrganizationAssembly={handleOrganizationAssembly} />
            </Route>
        </Switch>
    );
};

export default AssemblyTable;
