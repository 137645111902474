export const initialStateMembers = {
    members: [],
    membersCopy: [],
    membersMembershipType: null,
    currentMember: [],
    memberStatus: [],
    memberMedical: [],
    memberRanks: [],
    memberPayment: [],
    allMembersFunction: [],
    memberFiles: [],
};

export const initialStateOrganization = {
    userOrganizations: [],
    currentOrganization: [],
    usersWithRole: [],
    membershipFee: [],
    ranks: [],
    assemblyData: [],
    logo: {},
    transfer: [],
    emailBody: [],
};

export const initialStateAdmin = {
    inactiveOrganization: [],
    approvedOrganization: [],
    statistic: {},
};
