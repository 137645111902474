export const addDefaultValues = (rules, current) => {
    return rules.map(rule => ({
        ...rule,
        defaultValue: current[rule.defaultValue],
    }));
};

export const createDefaultValues = editFields => {
    const obj = {};
    editFields.map(field => (obj[field.name] = field.defaultValue));
    return obj;
};

export const compareObjWithSameKeys = (obj1, obj2) => {
    if (obj1 && obj2) {
        const objKeys = Object.keys(obj1);
        const valuesObj1 = Object.values(obj1);
        const valuesObj2 = Object.values(obj2);
        let result = {};

        let filteredValues = valuesObj1.filter((el, i) => el !== valuesObj2[i]);
        if (filteredValues.length === 0) return false;

        let indexes = filteredValues.map(el => valuesObj1.findIndex(val => val === el));
        indexes.forEach(index => (result[objKeys[index]] = valuesObj1[index]));

        return result;
    }
    return false;
};

export const findCorrectFunc = (keys, obj) => {
    if (!Array.isArray(keys)) return [];

    const objValues = Object.values(obj);
    const objKeys = Object.keys(obj);
    let arr = new Set();

    objValues.forEach((el, i) => {
        keys.forEach(key => {
            const index = el.findIndex(index => index === key);
            if (index !== -1) arr.add(i);
        });
    });

    return [...arr].map(num => objKeys[num]);
};
