import React from 'react';
import { Controller } from 'react-hook-form';
import Date from './Inputs/Date';
import SelectRender from './Inputs/SelectRender';
import TextRender from './Inputs/TextRender';
import CheckboxRender from './Inputs/CheckboxRender';
import MultiSelectRender from './Inputs/MultiSelectRender';
import DateYear from './Inputs/DateYear';
import YearPicker from './Inputs/YearPicker';

const FormInput = props => {
    const {
        name,
        type,
        rules,
        defaultValue,
        control,
        errors,
        input,
        data,
        label,
        helperText,
        multiline,
        rows,
        disabled,
        relatedPair,
        setMain,
    } = props;

    return (
        <>
            <Controller
                render={({ field }) => {
                    const { value, onChange } = field;

                    return (
                        <>
                            {input === 'checkbox' && (
                                <CheckboxRender
                                    onChange={onChange}
                                    value={value}
                                    label={label ? label : name}
                                    helperText={helperText}
                                />
                            )}
                            {input === 'select' && (
                                <SelectRender
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    label={label ? label : name}
                                    errors={errors}
                                    data={data}
                                    helperText={helperText}
                                />
                            )}
                            {input === 'multiSelect' && (
                                <MultiSelectRender
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    label={label ? label : name}
                                    errors={errors}
                                    data={data}
                                    helperText={helperText}
                                />
                            )}
                            {input === 'date' && (
                                <Date
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    label={label ? label : name}
                                    errors={errors}
                                    helperText={helperText}
                                    disabled={disabled}
                                    relatedPair={relatedPair}
                                    setMain={setMain}
                                />
                            )}
                            {input === 'year' && (
                                <YearPicker
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    label={label ? label : name}
                                    errors={errors}
                                    helperText={helperText}
                                    disabled={disabled}
                                    relatedPair={relatedPair}
                                    setMain={setMain}
                                />
                            )}
                            {input === 'date_year' && (
                                <DateYear onChange={onChange} value={value} />
                            )}
                            {input !== 'date' &&
                                input !== 'year' &&
                                input !== 'select' &&
                                input !== 'multiSelect' &&
                                input !== 'checkbox' &&
                                input !== 'date_year' && (
                                    <TextRender
                                        onChange={onChange}
                                        type={type}
                                        value={value}
                                        name={name}
                                        label={label ? label : name}
                                        errors={errors}
                                        helperText={helperText}
                                        multiline={multiline}
                                        rows={rows}
                                        relatedPair={relatedPair}
                                        setMain={setMain}
                                    />
                                )}
                        </>
                    );
                }}
                shouldUnregister={true}
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
            />
        </>
    );
};

export default FormInput;
