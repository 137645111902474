import { BASE_URL } from '../baseUrl';

export const getLogo = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_GET_LOGO}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const uploadLogo = (token, data, signal) => {
    let body = Object.keys(data).reduce((formData, key) => {
        formData.append(key, data[key]);
        return formData;
    }, new FormData());

    return fetch(`${BASE_URL}${process.env.REACT_APP_UPLOAD_LOGO}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body,
        signal,
    });
};

export const deleteLogo = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_DELETE_LOGO}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
        signal,
    });
};
