import { useState } from 'react';

const useToggle = (initialVal = false) => {
    const [state, setState] = useState(initialVal);
    const toggle = () => {
        setState(prevState => !prevState);
    };
    // return piece of state AND a function to toggle it
    return [state, toggle, setState];
};

export default useToggle;
