import { BASE_URL } from '../baseUrl';

export const addPayment = async (token, data, signal) => {
    return await fetch(`${BASE_URL}${process.env.REACT_APP_ADD_PAYMENT}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PUT',
        body: JSON.stringify(data),
        signal,
    });
};

export const getPayment = async (token, id, signal) => {
    return await fetch(`${BASE_URL}${process.env.REACT_APP_GET_PAYMENT}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const addSpecificPayment = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADD_SPECIFIC_PAYMENT}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};

export const removeSpecificPayment = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_DELETE_SPECIFIC_PAYMENT}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};
