import React from 'react';
import logo from '../images/isu-logo.png';
import { Link } from 'react-router-dom';
import Account from './Header/Account';
import { useUserStore } from './Store/Store';
import { Typography } from '@mui/material';

const IndexBanner = ({ title, description }) => {
    const userData = useUserStore(state => state.userData);
    let squares = [];

    for (let i = 0; i < 20; i++) {
        squares.push(i);
    }

    const generateRandomNum = ({ min, max }) =>
        Math.floor(Math.random() * (max - min + 1) + min);

    return (
        <div className='intro' id={'intro'} >
            {/* <div className='quote'>
                <h1>{title}</h1>
                <p>{description}</p>
            </div> */}
            <div className='squares-wrapper'>
                <ul className='squares'>
                    {squares.map((el, i) => {
                        const randomDimensions = Math.floor(
                            Math.random() * (150 - 15 + 1) + 15
                        );
                        return (
                            <li
                                key={i}
                                style={{
                                    left: `${generateRandomNum({
                                        min: 0,
                                        max: 90,
                                    })}%`,
                                    width: randomDimensions,
                                    height: randomDimensions,
                                    animationDelay: `${
                                        i % 2 ? generateRandomNum({ min: 0, max: 20 }) : 0
                                    }s`,
                                    animationDuration: `${generateRandomNum({
                                        min: 10,
                                        max: 50,
                                    })}s`,
                                }}
                            />
                        );
                    })}
                </ul>
            </div>
            <div className='image-overlay'>
                <div className='hero'>
                    <header
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '1rem',
                        }}
                    >
                        <div className='header-branding'>
                            <h1 className='header-branding__title'>
                                <img src={logo} alt={'organization'} width={'60'} />
                            </h1>

                        </div>

                        <div className='header-links'>
                            {userData?.id ? (
                                <Account />
                            ) : (
                                <div className='header-links'>
                                    <Link className='header-branding__link' to='/login'>
                                        Prijava
                                    </Link>
                                    <Link
                                        className='header-branding__link'
                                        to='/register'
                                    >
                                        Registracija
                                    </Link>
                                </div>
                            )}
                        </div>
                    </header>
                    <div className='hero-content'>
                        <Typography variant='h2'>Moja Udruga</Typography>
                        {/* <h2 className='hero-content__title'>Moja Udruga</h2> */}
                        <Typography variant='h5'>
                            Upravljanje i poslovanje udruga
                        </Typography>
                        {/* <h5>
                            Administrativno upravljenje
                            <br />
                            za poslovanje udruga
                        </h5> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndexBanner;
