import { BASE_URL } from '../baseUrl';

export const getUsersWithRole = (token, id, signal) => {
    return fetch(
        `${BASE_URL}${process.env.REACT_APP_USER_ORG}${id}${process.env.REACT_APP_GET_USERS_ROLE}`,
        {
            credentials: 'include',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            signal,
        }
    );
};

export const addUsersWithRole = (token, id, data, signal) => {
    return fetch(
        `${BASE_URL}${process.env.REACT_APP_USER_ORG}${id}${process.env.REACT_APP_ADD_USERS_ROLE}`,
        {
            credentials: 'include',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'POST',
            body: JSON.stringify(data),
            signal,
        }
    );
};

export const editUsersWithRole = (token, id, data, signal) => {
    return fetch(
        `${BASE_URL}${process.env.REACT_APP_USER_ORG}${id}${process.env.REACT_APP_EDIT_USERS_ROLE}`,
        {
            credentials: 'include',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'PATCH',
            body: JSON.stringify(data),
            signal,
        }
    );
};

export const deleteUserWithRole = (token, udruga_id, role_id, signal) => {
    return fetch(
        `${BASE_URL}${process.env.REACT_APP_USER_ORG}${udruga_id}${process.env.REACT_APP_DELETE_USERS_ROLE}${role_id}`,
        {
            credentials: 'include',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'DELETE',
            signal,
        }
    );
};
