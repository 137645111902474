import { membershipType } from '../../../../../helpers/options';

export const membershipStatusRules = [
    {
        name: 'vrsta_clanstva',
        label: 'Vrsta članstva',
        input: 'select',
        data: {
            data: membershipType,
            value: 'pk',
            label: 'naziv',
        },
        rules: { required: 'Ovo polje je obavezno' },
    },
    {
        name: 'pocetak_clanstva',
        label: 'Početak članstva',
        input: 'date',
        rules: { required: 'Ovo polje je obavezno' },
    },
];
