import { useState, useEffect, useCallback, useContext } from 'react';
import { FeedbackDispatch } from '../components/Store/Feedback/FeedbackContext';

const useFetch = (feedbackOptions = { loading: false, message: false }, finallyCb) => {
    const { loading, message } = feedbackOptions;

    const [response, setResponse] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({ error: false, msg: null });
    const [options, setOptions] = useState({});

    const dispatch = useContext(FeedbackDispatch);

    const showMessage = useCallback(
        (message, err) => {
            let msg =
                (Array.isArray(message) &&
                    message.length === 1 &&
                    typeof message[0] === 'string') ||
                typeof message === 'string'
                    ? message
                    : '';

            dispatch({
                type: 'setMessage',
                message: {
                    open: true,
                    type: err ? 'error' : 'info',
                    msg,
                },
            });
        },
        [dispatch]
    );

    const handleReduce = useCallback(
        (optionsArr, signal) => {
            return optionsArr
                .reduce((promiseChain, currentFunc, i) => {
                    return promiseChain.then(data => {
                        try {
                            let info = [...currentFunc.info, signal];

                            return currentFunc.func
                                .apply(this, info)
                                .then(res => Promise.all([res.text(), res]))
                                .then(([text, res]) => {
                                    try {
                                        let data = JSON.parse(text);
                                        if (!res.ok) {
                                            return Promise.reject(data);
                                        }
                                        setResponse(prevState => ({
                                            ...prevState,
                                            [currentFunc?.id || i]: data,
                                        }));

                                        if (options.length - 1 === i) {
                                            setIsLoading(false);
                                            message?.showSuccessMsg &&
                                                showMessage(
                                                    message?.successMsg || data,
                                                    false
                                                );
                                        }
                                        return Promise.resolve(data);
                                    } catch (err) {
                                        //console.log('Received text');
                                        if (!res.ok) {
                                            return Promise.reject();
                                        }
                                        return Promise.resolve();
                                    }
                                });
                        } catch (err) {
                            if (options.length - 1 === i) {
                                setIsLoading(false);
                                message?.showSuccessMsg &&
                                    showMessage(message?.successMsg || data, false);
                            }
                            if (typeof currentFunc.func === 'function') {
                                currentFunc.func(data);
                            }
                            return Promise.resolve(data);
                        }
                    });
                }, Promise.resolve())
                .catch(err => {
                    setIsLoading(false);
                    setResponse(false);
                    setError({ error: true, msg: err });

                    message?.showErrMsg && showMessage(message?.errMsg || err, true);
                });
        },
        [
            message?.showSuccessMsg,
            message?.successMsg,
            message?.showErrMsg,
            message?.errMsg,
            options.length,
            showMessage,
        ]
    );

    const doFetch = useCallback(
        options => {
            if (Array.isArray(options) && options.length !== 0) {
                setOptions(options);
                setIsLoading(true);
                setError({ error: false, msg: null });
                setResponse({});
                loading && dispatch({ type: 'startLoading' });
            }
        },
        [loading, dispatch]
    );

    useEffect(() => {
        if (!isLoading) return;

        let controller = new AbortController();

        handleReduce(options, controller.signal).finally(() => {
            loading && dispatch({ type: 'stopLoading' });

            if (typeof finallyCb === 'function') {
                finallyCb();
            }
        });

        return () => controller.abort();
    }, [isLoading, options, handleReduce, dispatch, loading, finallyCb]);

    return { response, error, isLoading, doFetch };
};

export default useFetch;
