import { Paper } from '@mui/material';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import formatDate from '../../../../../helpers/formatDate';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import {
    addAssembly,
    getAssembly,
    uploadFileAssembly,
} from '../../../../../services/organizations/assembly';
import StepsForm from '../../../../FormComp/StepsForm';
import { FeedbackDispatch } from '../../../../Store/Feedback/FeedbackContext';
import { useOrganizationStore } from '../../../../Store/Store';
import { assemblyRules } from './assemblyRules';
import FinalStep from './FinalStep';
import UploadFileAddForm from './UploadFileAddForm';

const AssemblyAddForm = () => {
    const { id } = useParams();
    const history = useHistory();
    const [{ access }] = useLocalStorage('tokens');

    const assemblyData = useOrganizationStore(state => state.assemblyData);
    const setAssemblyData = useOrganizationStore(state => state.setAssemblyData);

    const dispatch = useContext(FeedbackDispatch);

    const showMessage = ({ msg, type }) => {
        dispatch({
            type: 'setMessage',
            message: { open: true, type, msg },
        });
    };

    const handleAddAssemblyRules = data => {
        const { naziv, status, datum_odrzavanja, selectedFile } = data;
        dispatch({ type: 'startLoading' });

        addAssembly(access, {
            naziv,
            status: +status,
            datum: [
                {
                    datum_odrzavanja: formatDate(datum_odrzavanja) || null,
                },
            ],
            udruga: +id,
        })
            .then(res => Promise.all([res.text(), res]))
            .then(([text, res]) => {
                try {
                    let data = JSON.parse(text);
                    if (!res.ok) {
                        return Promise.reject(
                            'Došlo je do greške prilikom dodavanja skupštine'
                        );
                    }
                    return data;
                } catch (err) {
                    return Promise.reject(
                        'Došlo je do greške prilikom dodavanja skupštine'
                    );
                }
            })
            .then(({ skupstine_i_sjednice_id }) => {
                if (selectedFile.length === 0) {
                    return getAssembly(access, id);
                }

                const fetchArr = selectedFile.map(file => ({
                    func: uploadFileAssembly,
                    info: [
                        access,
                        { skupstine_i_sjednice: skupstine_i_sjednice_id, pdf: file },
                    ],
                }));

                return fetchArr
                    .reduce((promiseChain, currentFunc) => {
                        return promiseChain.then(() => {
                            const { func, info } = currentFunc;

                            return func(...info).then(res => {
                                if (!res.ok) {
                                    return Promise.reject(
                                        'Došlo je do greške prilikom dodavanje datoteke'
                                    );
                                }
                                return Promise.resolve();
                            });
                        });
                    }, Promise.resolve())
                    .then(() => getAssembly(access, id));
            })
            .then(res => {
                if (!res.ok) {
                    return Promise.reject(
                        'Došlo je do greške prilikom dohvaćanja skupština'
                    );
                }
                return res.json();
            })
            .then(data => {
                setAssemblyData(
                    data.map(m => ({
                        ...m,
                        isPdf: m.pdf,
                        datum_odrzavanja: m.datumi['datum odrzavanja'],
                    }))
                );
                history.goBack();
            })
            .catch(err => showMessage({ msg: err, type: 'error' }))
            .finally(() => dispatch({ type: 'stopLoading' }));
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <Paper sx={{ width: '32rem' }}>
                <StepsForm
                    updateInputs={assemblyRules}
                    onSubmit={handleAddAssemblyRules}
                    hideBackLink={assemblyData?.length === 0}
                >
                    <UploadFileAddForm />
                    <FinalStep />
                </StepsForm>
            </Paper>
        </div>
    );
};

export default AssemblyAddForm;
