import { CircularProgress, Stack } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import {
    getApprovedOrganizations,
    getStatistic,
    getStatisticActiveMembers,
    getStatisticMeetings,
    getStatisticMember,
    getStatisticUserActivity,
} from '../../../../services/admin';
import { useAdminStore } from '../../../Store/Store';
import StatisticList from './StatisticList';

const Statistic = () => {
    const [{ access }] = useLocalStorage('tokens');
    const { doFetch, isLoading } = useFetch();

    const statisticRef = useRef();
    const approvedOrgRef = useRef();

    const statistic = useAdminStore(state => state.statistic);
    const setStatistic = useAdminStore(state => state.setStatistic);

    useEffect(() => {
        doFetch([
            { func: getApprovedOrganizations, info: [access] },
            { func: data => (approvedOrgRef.current = data) },
            { func: getStatistic, info: [access] },
            { func: data => (statisticRef.current = { statistic: data }) },
            { func: getStatisticMeetings, info: [access] },
            {
                func: data =>
                    (statisticRef.current = { ...statisticRef.current, meetings: data }),
            },
            { func: getStatisticMember, info: [access] },
            {
                func: data => {
                    statisticRef.current = { ...statisticRef.current, members: data };
                },
            },
            { func: getStatisticUserActivity, info: [access] },
            {
                func: data => {
                    statisticRef.current = {
                        ...statisticRef.current,
                        membersActivity: data,
                    };
                },
            },
            { func: getStatisticActiveMembers, info: [access] },
            {
                func: data => {
                    const orgInfo = data.map(d => {
                        return {
                            ...d,
                            ...approvedOrgRef.current.find(({ id }) => +id === d.udruga),
                        };
                    });
                    statisticRef.current = {
                        ...statisticRef.current,
                        organizationMembersActivity: orgInfo,
                    };

                    setStatistic(statisticRef.current);
                },
            },
        ]);
    }, [access, doFetch, setStatistic]);

    if (isLoading) {
        return (
            <Stack direction='row' justifyContent='center' my={6}>
                <CircularProgress size={60} />
            </Stack>
        );
    }

    return Object.keys(statistic).length !== 0 && <StatisticList />;
};

export default Statistic;
