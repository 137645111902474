import React, { useEffect, useState } from 'react';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../../helpers/editHelpers';
import formatDate from '../../../../../../helpers/formatDate';
import useLocalStorage from '../../../../../../hooks/useLocalStorage';
import useFetch from '../../../../../../hooks/useFetch';
import UpdateForm from '../../../../../FormComp/UpdateForm';
import { editFunctionRules } from './editFunctionRules';
import { useMembersStore, useOrganizationStore } from '../../../../../Store/Store';
import { useParams } from 'react-router-dom';
import { editFunction } from '../../../../../../services/members/function';
import { getMembers } from '../../../../../../services/members/members';

const EditFunctionForm = ({ currentRow }) => {
    const { member_id } = useParams();

    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch({ message: { showErrMsg: true } });

    const { naziv } = useOrganizationStore(state => state.currentOrganization);
    const setCurrentMember = useMembersStore(state => state.setCurrentMember);

    const handleEditFunction = data => {
        doFetch([
            {
                func: editFunction,
                info: [
                    access,
                    currentRow.function_date_id,
                    {
                        funkcija: data.funkcija,
                        pocetak_duznosti: formatDate(data.pocetak_duznosti),
                        kraj_duznosti: formatDate(data.kraj_duznosti),
                    },
                ],
            },
            { func: getMembers, info: [access, naziv] },
            {
                func: data => {
                    if (typeof data === 'object' && Object.keys(data).length !== 0) {
                        setCurrentMember(data.filter(res => res.id === +member_id)[0]);
                    }
                },
            },
        ]);
    };

    useEffect(() => {
        setEditFields(addDefaultValues(editFunctionRules, currentRow));
    }, [currentRow]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        editFields.length !== 0 && (
            <UpdateForm
                updateInputs={editFields}
                onSubmit={handleEditFunction}
                defaultValues={defaultValues}
                updateDisable={true}
                btnMessage='Promijeni'
                styleContainer='updateForm__container updateForm__container-3c'
            />
        )
    );
};

export default EditFunctionForm;
