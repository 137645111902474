import React, { useCallback, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import TableComp from '../../../../TableComp/TableComp';
import AddForm from './AddForm/AddForm';
import EditForm from './EditForm/EditForm';
import TableCollapse from '../../../../TableComp/TableCollapse';
import useFetch from '../../../../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import {
    getMedicalList,
    deleteMedicalMember,
} from '../../../../../services/members/medical';
import { useMembersStore } from '../../../../Store/Store';

const medicalTableInfo = [
    { id: 'datum_pregleda', label: 'Datum pregleda', type: 'date' },
    { id: 'vrijedi_do', label: 'Vrijedi do', type: 'date' },
    { id: 'status_pregleda', label: 'Pregled obavljen', type: 'boolean' },
    { id: 'obrisi', btn: true },
    { id: '', btn: true, collapse: true },
];

const MedicalMember = () => {
    const { member_id } = useParams();

    const [expanded, setExpanded] = useState(false);

    const { doFetch: getMedical } = useFetch();
    const { doFetch: deleteMedical } = useFetch();

    const [{ access }] = useLocalStorage('tokens');

    const memberMedical = useMembersStore(state => state.memberMedical);
    const setMemberMedical = useMembersStore(state => state.setMemberMedical);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDeleteMedical = ({ id }) => {
        deleteMedical([
            { func: deleteMedicalMember, info: [access, id], id: 'delete' },
            { func: handleGetUserMedicalList },
        ]);
    };

    const handleGetUserMedicalList = useCallback(() => {
        getMedical([
            { func: getMedicalList, info: [access, member_id], id: 'medical' },
            { func: data => setMemberMedical(data) },
        ]);
    }, [access, getMedical, member_id, setMemberMedical]);

    const btnsArr = [
        {
            name: 'obrisi',
            icon: <DeleteForeverIcon />,
            onClick: handleDeleteMedical,
        },
    ];

    return memberMedical.length > 0 ? (
        <>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                >
                    <Typography variant='body1'>Dodaj liječnički pregled</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AddForm getUserMedical={handleGetUserMedicalList} />
                </AccordionDetails>
            </Accordion>
            <div style={{ marginTop: '2rem' }}>
                <TableComp
                    maxTableHeight='40vh'
                    head={medicalTableInfo}
                    body={memberMedical}
                    btns
                    btnsArr={btnsArr}
                >
                    <TableCollapse>
                        <EditForm getUserMedical={handleGetUserMedicalList} />
                    </TableCollapse>
                </TableComp>
            </div>
        </>
    ) : (
        <AddForm getUserMedical={handleGetUserMedicalList} />
    );
};

export default MedicalMember;
