import React from 'react';
import { IconButton, styled, Toolbar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import Account from '../Header/Account';
import img from '../../images/network.jpg';
import logo from '../../images/isu-logo.png';
import { useOrganizationStore, useUserStore } from '../Store/Store';

const drawerWidth = 400;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Navbar = ({ open, handleDrawerOpen }) => {
    const userOrganizations = useOrganizationStore(state => state.userOrganizations);
    const userData = useUserStore(state => state.userData);

    return (
        <AppBar position='fixed' open={open}>
            <Toolbar
                sx={{
                    height: '10vh',
                    background: `url(${img}) no-repeat center center fixed`,
                    backgroundSize: 'cover',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', flex: '0 0 20%' }}>
                    {(userOrganizations.length !== 0 || userData?.is_superuser) && (
                        <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={handleDrawerOpen}
                            edge='start'
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <InfoIcon fontSize='medium' />
                        </IconButton>
                    )}
                    <Link className='header__logo' to='/'>
                        <img src={logo} alt={'organization'} />
                    </Link>
                </div>
                <Account />
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
