import React, { useCallback, useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Index from './components/Index/Index';
import useLocalStorage from './hooks/useLocalStorage';
import { useUserStore } from './components/Store/Store';
import Dashboard from './components/Dashboard/Dashboard';
import LoginForm from './components/LoginForm/LoginForm';
import RegisterForm from './components/RegisterForm/RegisterForm';
import ConfirmAcc from './components/ConfirmAcc/ConfirmAcc';
import ResetPassFinal from './components/ResetPassFinal/ResetPassFinal';
import Error404 from './components/Error404';
import {
    Alert,
    CircularProgress,
    Modal,
    Slide,
    Snackbar,
    Stack,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import useFetch from './hooks/useFetch';
import './styles/css/style.css';
import { getUserData, refreshToken } from './services/user';
import {
    FeedbackDispatch,
    FeedbackState,
} from './components/Store/Feedback/FeedbackContext';
import Banner from './components/Banner';
import ResendEmail from './components/ResendEmail/ResendEmail';
import ProtectedRoute from './components/ProtectedRoute';
import handleDebounce from './helpers/handleDebounce';

const SlideTransition = props => {
    return <Slide {...props} direction='up' />;
};

const App = () => {
    const [tokenLoading, setTokenLoading] = useState(false);

    const [token, setToken] = useLocalStorage('tokens', '');
    const [userEmail] = useLocalStorage('email', '');

    const {
        doFetch: fetchTokens,
        error: { error },
    } = useFetch();
    const { doFetch } = useFetch();

    const setUserData = useUserStore(state => state.setUserData);

    const dispatch = useContext(FeedbackDispatch);
    const {
        feedback: {
            loading,
            message: { open, type, msg },
        },
    } = useContext(FeedbackState);

    const handleClose = () => {
        dispatch({
            type: 'setMessage',
            message: {
                open: false,
                type,
                msg,
            },
        });
    };

    const handleGetUserData = useCallback(
        (email, token, cb) => {
            doFetch([
                { func: getUserData, info: [email, token] },
                { func: data => setUserData(data) },
                { func: () => setTokenLoading(false) },
                { func: cb },
            ]);
        },
        [doFetch, setUserData]
    );

    useEffect(() => {
        if (token?.refresh && userEmail) {
            setTokenLoading(true);
            const handleFetchTokens = handleDebounce(
                () =>
                    fetchTokens([
                        { func: refreshToken, info: [token.refresh] },
                        {
                            func: ({ access }) => {
                                if (access) {
                                    setToken(prevState => ({
                                        ...prevState,
                                        access,
                                    }));
                                    handleGetUserData(userEmail, access);
                                }
                            },
                        },
                    ]),
                2000
            );
            handleFetchTokens();
        }
    }, [token.refresh, userEmail, fetchTokens, setToken, handleGetUserData]);

    useEffect(() => {
        if (error) {
            setTokenLoading(false);
        }
    }, [error]);

    return (
        <>
            <Router>
                <Switch>
                    <Route path='/' exact component={Index} />
                    <Route path='/register' exact component={RegisterForm} />
                    <Route path='/resend' exact component={ResendEmail} />
                    <Route path='/login' exact>
                        <Banner />
                        <LoginForm
                            showResetPass={true}
                            handleGetUserData={handleGetUserData}
                        />
                    </Route>
                    <Route path='/email-verify/' exact>
                        <Banner />
                        <ConfirmAcc handleGetUserData={handleGetUserData} />
                    </Route>
                    <ProtectedRoute>
                        <Route path='/dashboard' component={Dashboard} />
                    </ProtectedRoute>
                    <Route
                        path='/password-reset/:uidb64/:token/'
                        exact
                        render={props => (
                            <ResetPassFinal
                                {...props}
                                handleGetUserData={handleGetUserData}
                            />
                        )}
                    />
                    <Route path='*' render={() => <Error404 />} />
                </Switch>
            </Router>
            <Snackbar
                TransitionComponent={SlideTransition}
                open={open}
                autoHideDuration={10000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                    {msg || (type === 'error' && 'Došlo je do greške.')}
                </Alert>
            </Snackbar>
            <Modal
                open={loading}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box
                    sx={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress color='info' size={76} thickness={3} />
                </Box>
            </Modal>
            <Modal open={tokenLoading}>
                <Box
                    sx={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'white',
                        border: 'white',
                    }}
                >
                    <Stack direction='column' spacing={3} alignItems='center'>
                        <CircularProgress size={100} thickness={4} />
                        <Typography variant='h6'>Učitavanje...</Typography>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default App;
