import { roles } from './options';

const filterRole = id => {
    if (id && id !== 'role') {
        return roles.filter(rol => rol.pk === id)[0].naziv;
    }
    return null;
};

export default filterRole;
