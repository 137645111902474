import React, { useState, useEffect } from 'react';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../helpers/editHelpers';
import capitalizeFirstLetter from '../../../../../helpers/capitalizeFirstLetter';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import useFetch from '../../../../../hooks/useFetch';
import UpdateForm from '../../../../FormComp/UpdateForm';
import { useOrganizationStore } from '../../../../Store/Store';
import { editMemberRules } from './editMemberRules';
import formatDate from '../../../../../helpers/formatDate';
import { editMember } from '../../../../../services/members/edit';

const EditMemberForm = ({ currentMember, filterMember }) => {
    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch({
        message: { showSuccessMsg: true, successMsg: 'Uspješno promjenjeno' },
    });

    const { id } = useOrganizationStore(state => state.currentOrganization);

    const handleEditMember = data => {
        doFetch([
            {
                func: editMember,
                info: [
                    access,
                    currentMember.id,
                    {
                        ime: capitalizeFirstLetter(data.ime),
                        prezime: capitalizeFirstLetter(data.prezime),
                        oib: data.oib,
                        broj_putovnice: data.broj_putovnice,
                        udruga: id,
                        datum_rodenja: formatDate(data.datum_rodenja),
                        spol: data.spol,
                        napomena: [{ napomena: data.napomena }],
                        grupna_obavijest: data.grupna_obavijest,
                    },
                ],
            },
            { func: filterMember },
        ]);
    };

    useEffect(() => {
        setEditFields(
            addDefaultValues(editMemberRules, {
                ...currentMember,
                napomena: currentMember.napomena.napomena,
            })
        );
    }, [currentMember]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        editFields.length !== 0 && (
            <UpdateForm
                updateInputs={editFields}
                onSubmit={handleEditMember}
                defaultValues={defaultValues}
                updateDisable={true}
                btnMessage={'Promijeni'}
                styleContainer='updateForm__container updateForm__container-4c'
            />
        )
    );
};

export default EditMemberForm;
