import React, { useEffect, useState } from 'react';
import FormInput from './FormInput';

const SimpleForm = ({ styleContainer, updateInputs, control, errors, setValue }) => {
    const [relatedPair, setRelatedPair] = useState([]);
    const [main, setMain] = useState({});

    useEffect(() => {
        const inputsSet = new Set();
        updateInputs.forEach(el => el?.related && inputsSet.add(el.related));

        const relatedInputs = [...inputsSet].map(step => {
            const inputs = updateInputs.filter(input => input.related === step);
            return { related: step, inputs };
        });

        setRelatedPair(relatedInputs);
    }, [updateInputs]);

    useEffect(() => {
        if (relatedPair.length !== 0) {
            relatedPair.forEach(pair => {
                pair.inputs.forEach(el => {
                    if (main[el.related]) {
                        const input = updateInputs.find(i => i.name === el.related);

                        if (!input.defaultValue) {
                            setValue(el.name, main[el.related]);
                        }
                        //setCopied(true);
                    }
                });
            });
        }
    }, [main, relatedPair, setValue, updateInputs]);

    return (
        <div
            className={
                styleContainer
                    ? styleContainer
                    : 'updateForm__container updateForm__container-1c'
            }
        >
            {updateInputs.map(el => (
                <FormInput
                    key={el.name}
                    control={control}
                    name={el.name}
                    label={el.label}
                    type={el.type ? el.type : 'text'}
                    defaultValue={
                        el.defaultValue
                            ? el.defaultValue
                            : typeof el.defaultValue === 'boolean' ||
                              el.defaultValue === 0
                            ? el.defaultValue
                            : ''
                    }
                    rules={el.rules}
                    errors={errors}
                    input={el.input ? el.input : 'text'}
                    data={el.data}
                    helperText={el.helperText}
                    multiline={el.multiline}
                    rows={el.rows}
                    disabled={el.disabled}
                    relatedPair={relatedPair}
                    setMain={setMain}
                />
            ))}
        </div>
    );
};

export default SimpleForm;
