import { BASE_URL } from '../baseUrl';

export const getUserOrganization = (token, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_USER_ORG}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const getUserOrganizationById = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_USER_ORG}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const createUserOrganization = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_USER_ORG}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const deleteUserOrganization = (token, id, signal) => {
    return fetch(`${BASE_URL}/udruga/${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
        signal,
    });
};

export const getOrganizationInfo = (token, id, signal) => {
    return fetch(`${BASE_URL}/dashboard-data/${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};
