import React, { useContext } from 'react';
import { memberRankRules } from './memberRankRules';
import useLocalStorage from '../../../../../../hooks/useLocalStorage';
import UpdateForm from '../../../../../FormComp/UpdateForm';
import formatDate from '../../../../../../helpers/formatDate';
import { useParams } from 'react-router-dom';
import { useOrganizationStore } from '../../../../../Store/Store';
import { addRankMember } from '../../../../../../services/members/ranks';
import { FeedbackDispatch } from '../../../../../Store/Feedback/FeedbackContext';

const AddMemberRankForm = ({ handleGetUserRanks, setExpanded }) => {
    const { member_id } = useParams();
    const [{ access }] = useLocalStorage('tokens');

    const ranks = useOrganizationStore(state => state.ranks);
    const updateRanks = useOrganizationStore(state => state.updateRanks);
    const dispatch = useContext(FeedbackDispatch);

    const showMessage = ({ msg, type }) => {
        dispatch({
            type: 'setMessage',
            message: { open: true, type, msg },
        });
    };

    const handleAddMemberRank = data => {
        const { cinovi, cinovi_kraj, cinovi_pocetak } = data;

        Promise.allSettled(
            cinovi.map(el =>
                addRankMember(access, {
                    cinovi_pocetak: formatDate(cinovi_pocetak) || null,
                    cinovi_kraj: formatDate(cinovi_kraj) || null,
                    cinovi: el,
                    clan: member_id,
                })
            )
        )
            .then(async resArr => {
                for (let { value } of resArr) {
                    if (!value.ok) {
                        const errMsg = await value.json();
                        return Promise.reject(errMsg);
                    }
                }
            })
            .then(() => {
                updateRanks(cinovi);
                handleGetUserRanks();
            })
            .catch(err => {
                showMessage({ msg: err || 'Došlo je do greške', type: 'error' });
            })
            .finally(() =>
                typeof setExpanded === 'function' ? setExpanded(false) : null
            );
    };

    return (
        <UpdateForm
            updateInputs={[
                ...memberRankRules,
                {
                    name: 'cinovi',
                    label: 'Razina osposobljenosti',
                    input: 'multiSelect',
                    data: {
                        data: [...ranks],
                        value: 'id',
                        label: 'naziv_cina',
                    },
                    rules: { required: 'Ovo polje je obavezno' },
                },
            ]}
            onSubmit={handleAddMemberRank}
            btnMessage='Dodaj'
            styleContainer='updateForm__container updateForm__container-3c'
        />
    );
};

export default AddMemberRankForm;
