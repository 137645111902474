import React, { useContext, useEffect } from 'react';
import Close from '@mui/icons-material/Close';
import { DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import { sendPaymentByEmail } from '../../../../../services/members/paymentByEmail';
import { getPaymentPdfMultiple } from '../../../../../services/members/paymentPDF';
import StepsForm from '../../../../FormComp/StepsForm';
import { FeedbackDispatch } from '../../../../Store/Feedback/FeedbackContext';
import { useOrganizationStore, useShowModalOptions } from '../../../../Store/Store';
import { transferRules } from './transferRules';
import TransferText from './TransferText';
import useFetch from '../../../../../hooks/useFetch';
import { getTransfer } from '../../../../../services/organizations/transfer';
import { getEmailBody } from '../../../../../services/organizations/emailBody';
import { getMembershipFee } from '../../../../../services/organizations/membershipFee';

const changeMembersId = s => {
    if (Array.isArray(s)) {
        return s.reduce((prevVal, currentVal) => prevVal + '%2C' + currentVal);
    }
    return s;
};

const Transfer = ({ members, id, setSelected }) => {
    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch({ loading: true });

    const dispatch = useContext(FeedbackDispatch);

    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const membershipFee = useOrganizationStore(state => state.membershipFee);
    const setTransfer = useOrganizationStore(state => state.setTransfer);
    const setEmailBody = useOrganizationStore(state => state.setEmailBody);
    const setMembershipFee = useOrganizationStore(state => state.setMembershipFee);

    const handleCloseModal = () => setShowModal({ show: false, Comp: '' });

    const showMsg = (msg, type) =>
        dispatch({
            type: 'setMessage',
            message: { open: true, type, msg },
        });

    const handleSendPaymentByEmail = (godina, member, dugovanja) => {
        sendPaymentByEmail(access, member, godina, id, dugovanja ? 'True' : 'False')
            .then(response => {
                if (!response.ok) {
                    return Promise.reject();
                }
                showMsg('Uplatnica je uspješno poslana', 'info');

                return handleCloseModal();
            })
            .catch(err =>
                showMsg('Došlo je do greške prilikom slanja uplatnice', 'error')
            )
            .finally(() => {
                if (setSelected) setSelected([]);
            });
    };

    const handleGetPdf = (godina, member, dugovanja) => {
        getPaymentPdfMultiple(access, member, godina, id, dugovanja ? 'True' : 'False')
            .then(response => {
                if (!response.ok) {
                    return Promise.reject();
                }
                return response.blob();
            })
            .then(blob => {
                const url = URL.createObjectURL(blob);
                handleCloseModal();
                window.open(url, '_blank');
            })
            .catch(err =>
                showMsg('Došlo je do greške prilikom generiranja pdf-a', 'error')
            )
            .finally(() => {
                if (setSelected) setSelected([]);
            });
    };

    const handleSendTransfer = ({ godina, uplatnica, dugovanja }) => {
        let memberIds = members.length !== 1 ? changeMembersId(members) : members[0];

        if (uplatnica === 2) {
            handleGetPdf(godina, memberIds, dugovanja);
        } else if (uplatnica === 1) {
            handleSendPaymentByEmail(godina, memberIds, dugovanja);
        }
    };

    useEffect(() => {
        doFetch([
            { func: getTransfer, info: [access, id] },
            { func: data => setTransfer(data) },
            { func: getEmailBody, info: [access, id] },
            { func: data => setEmailBody(data) },
            { func: getMembershipFee, info: [access, id] },
            { func: data => setMembershipFee(data) },
        ]);
    }, [access, id, doFetch, setTransfer, setEmailBody, setMembershipFee]);

    return (
        <>
            <DialogTitle
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant='h6'>Uplatnica</Typography>
                <IconButton aria-label='close' onClick={handleCloseModal} color='primary'>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            {membershipFee && (
                <StepsForm
                    updateInputs={[
                        {
                            name: 'godina',
                            label: 'Godina',
                            input: 'select',
                            data: {
                                data: membershipFee
                                    .map(m => ({ naziv: m.godina }))
                                    .reverse(),
                                value: 'naziv',
                                label: 'naziv',
                            },
                            rules: { required: 'Ovo polje je obavezno' },
                            defaultValue: 'godina',
                            step: 'Osnovne informacije',
                        },
                        ...transferRules,
                    ]}
                    onSubmit={handleSendTransfer}
                    btnMessage='Promijeni'
                    hideBackLink
                >
                    <TransferText id={id} />
                </StepsForm>
            )}
        </>
    );
};

export default Transfer;
