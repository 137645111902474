import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../../helpers/editHelpers';
import formatDate from '../../../../../../helpers/formatDate';
import useFetch from '../../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../../hooks/useLocalStorage';
import { addPayment, getPayment } from '../../../../../../services/members/payment';
import UpdateForm from '../../../../../FormComp/UpdateForm';
import { useMembersStore } from '../../../../../Store/Store';

const membershipRules = [
    {
        name: 'iznos_uplate',
        label: 'Uplaćeni iznos',
        type: 'number',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
        },
        defaultValue: 'iznos_uplate',
    },
    {
        name: 'datum_placanja',
        label: 'Datum plaćanja',
        input: 'date',
        rules: { required: 'Ovo polje je obavezno' },
        defaultValue: 'datum_placanja',
    },
];

const EditFormPayment = ({ currentRow }) => {
    const { member_id } = useParams();

    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const { doFetch } = useFetch();
    const [{ access }] = useLocalStorage('tokens');

    const setMemberPayment = useMembersStore(state => state.setMemberPayment);

    const handleEditPayment = data => {
        let { iznos_uplate, datum_placanja } = data;
        let { razdoblje_uplate } = currentRow;

        doFetch([
            {
                func: addPayment,
                info: [
                    access,
                    {
                        clan: +member_id,
                        iznos_uplate: +iznos_uplate,
                        razdoblje_uplate,
                        datum_placanja: formatDate(datum_placanja),
                    },
                ],
            },
            { func: getPayment, info: [access, member_id] },
            { func: data => setMemberPayment(data) },
        ]);
    };

    useEffect(() => {
        setEditFields(
            addDefaultValues(membershipRules, {
                iznos_uplate: currentRow.iznos_uplate,
                datum_placanja:
                    currentRow.datum_placanja === null ? '' : currentRow.datum_placanja,
            })
        );
    }, [currentRow]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        editFields.length !== 0 && (
            <div style={{ width: '100%' }}>
                <UpdateForm
                    updateInputs={editFields}
                    onSubmit={handleEditPayment}
                    defaultValues={defaultValues}
                    updateDisable={true}
                    btnMessage='Promijeni'
                    styleContainer='updateForm__container updateForm__container-2c'
                />
            </div>
        )
    );
};

export default EditFormPayment;
