import formatDateDmy from '../../../../helpers/formatDateDmy';

export const userActivityTableHead = [
    { id: 'user_id', label: 'ID korisnika', type: 'string' },
    { id: 'first_name', label: 'Ime', type: 'string' },
    { id: 'last_name', label: 'Prezime', type: 'string' },
    {
        id: 'last_login',
        label: 'Zadnji puta prijavljen',
        type: 'function',
        info: date => formatDateDmy(new Date(date), true),
    },
];
