import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../../helpers/editHelpers';
import formatDate from '../../../../../../helpers/formatDate';
import useFetch from '../../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../../hooks/useLocalStorage';
import { editRankMember, getMemberRanks } from '../../../../../../services/members/ranks';
import UpdateForm from '../../../../../FormComp/UpdateForm';
import { useMembersStore, useOrganizationStore } from '../../../../../Store/Store';
import { memberRankRules } from '../AddForm/memberRankRules';

const EditMemberRankForm = ({ currentRow }) => {
    const { member_id } = useParams();

    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const { doFetch } = useFetch({
        message: { showErrMsg: true, errMsg: 'Došlo je do greške.' },
    });
    const [{ access }] = useLocalStorage('tokens');
    const ranks = useOrganizationStore(state => state.ranks);
    const updateMemberRank = useOrganizationStore(state => state.updateMemberRank);

    const setMemberRanks = useMembersStore(state => state.setMemberRanks);

    const handleEditRank = data => {
        const { cinovi, cinovi_kraj, cinovi_pocetak } = data;

        doFetch([
            {
                func: editRankMember,
                info: [
                    access,
                    {
                        cinovi_kraj: formatDate(cinovi_kraj) || null,
                        cinovi_pocetak: formatDate(cinovi_pocetak) || null,
                        cinovi,
                    },
                    currentRow.id,
                ],
            },
            { func: getMemberRanks, info: [access, member_id] },
            {
                func: data => {
                    setMemberRanks(data);
                    if (cinovi !== currentRow.cinovi.id) {
                        updateMemberRank(
                            cinovi,
                            currentRow.cinovi.naziv_cina,
                            member_id,
                            data
                        );
                    }
                },
            },
        ]);
    };

    useEffect(() => {
        let mounted = true;

        mounted &&
            setEditFields(
                addDefaultValues(
                    [
                        ...memberRankRules,
                        {
                            name: 'cinovi',
                            label: 'Razina osposobljenosti',
                            input: 'select',
                            data: {
                                data: ranks,
                                value: 'id',
                                label: 'naziv_cina',
                            },
                            rules: { required: 'Ovo polje je obavezno' },
                            defaultValue: 'cin',
                        },
                    ],
                    { ...currentRow, cin: currentRow.cinovi.id }
                )
            );

        return () => (mounted = false);
    }, [currentRow, ranks]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        editFields.length !== 0 && (
            <UpdateForm
                updateInputs={editFields}
                onSubmit={handleEditRank}
                defaultValues={defaultValues}
                updateDisable={true}
                btnMessage='Promijeni'
                styleContainer='updateForm__container updateForm__container-3c'
            />
        )
    );
};

export default EditMemberRankForm;
