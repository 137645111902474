import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Account from './Account';
import { useUserStore } from '../Store/Store';
import logo from '../../images/isu-logo.png';
import { scrollToTop } from '../../helpers/scrolling/scrollToTop';

function Header() {
    const [click, setClick] = useState(false);
    const [navBarBg, setNavBarBg] = useState(false);
    const userData = useUserStore(state => state.userData);

    const handleClick = () => {
        setClick(!click);
    };

    const changeBackground = () => {
        if (window.scrollY >= 180) {
            setNavBarBg(true);
        } else {
            setNavBarBg(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => window.removeEventListener('scroll', changeBackground);
    });

    return (
        <div
            className={navBarBg ? 'header active' : 'header'}
            style={{ backgroundColor: '#021625' }}
        >
            <Link to='/' className='header__logo' onClick={() => scrollToTop()}>
                <img src={logo} alt={'organization'} />
            </Link>
            <div
                className={
                    click ? 'header__middle header__middle__active' : 'header__middle'
                }
            >
                <Link
                    to={'/'}
                    className={'text-link'}
                    onClick={() => {
                        setClick(false);
                        scrollToTop();
                    }}
                >
                    Početna
                </Link>
            </div>
            {userData?.id ? (
                <Account />
            ) : (
                <div className='header__icons'>
                    <Link className='text-link' to='/login'>
                        <li onClick={() => setClick(false)}>Prijava</li>
                    </Link>
                    <Link className='text-link' to='/register'>
                        <li onClick={() => setClick(false)}>Registracija</li>
                    </Link>
                </div>
            )}
            <div onClick={handleClick} className='burger'>
                <div className={click ? 'line1__closed' : 'line1'}></div>
                <div className={click ? 'line2__closed' : 'line2'}></div>
                <div className={click ? 'line3__closed' : 'line3'}></div>
            </div>
        </div>
    );
}

export default Header;
