import { BASE_URL } from '../baseUrl';

export const addMembershipFee = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADD_MEMBERSHIP_FEE}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        method: 'POST',
        signal,
    });
};

export const getMembershipFee = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_GET_MEMBERSHIP_FEE}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const editMembershipFee = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_MEMBERSHIP_FEE}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        method: 'PUT',
        signal,
    });
};
