import React from 'react';
import formatDate from '../../../../../helpers/formatDate';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import useFetch from '../../../../../hooks/useFetch';
import UpdateForm from '../../../../FormComp/UpdateForm';
import { useParams } from 'react-router-dom';
import { addStatus } from '../../../../../services/members/status';

const Activate = ({ filterMember, handleGetMemberStatus }) => {
    const { member_id } = useParams();
    const [{ access }] = useLocalStorage('tokens');

    const { doFetch } = useFetch({ message: { showErrMsg: true } });

    const handeAddStatus = data => {
        doFetch([
            {
                func: addStatus,
                info: [access, { clan_od: formatDate(data.clan_od), clan: +member_id }],
                id: 'status',
            },
            { func: filterMember },
            { func: handleGetMemberStatus },
        ]);
    };

    const addStatusRoles = [
        {
            name: 'clan_od',
            label: 'Aktivan od',
            input: 'date',
        },
    ];

    return (
        <UpdateForm
            updateInputs={addStatusRoles}
            onSubmit={handeAddStatus}
            btnMessage='Promijeni'
            clearFields
        />
    );
};

export default Activate;
