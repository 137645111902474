import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
    addDefaultValues,
    createDefaultValues,
    compareObjWithSameKeys,
    findCorrectFunc,
} from '../../../../helpers/editHelpers';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import {
    getUserOrganization,
    getUserOrganizationById,
} from '../../../../services/organizations/organizations';
import {
    editOrganizationAddress,
    editOrganizationContact,
    editOrganizationInfo,
} from '../../../../services/organizations/edit';
import LinkButton from '../../../FormComp/LinkButton';
import UpdateForm from '../../../FormComp/UpdateForm';
import { editOrganizationRules } from './editOrganizationRules';
import { useOrganizationStore } from '../../../Store/Store';

const checkObject = object => {
    for (let key in object) {
        if (typeof object[key] === 'string') {
            return true;
        }
    }
    return false;
};

const EditOrganizationForm = () => {
    const { id } = useParams();
    const history = useHistory();

    const [organizationInfo, setOrganizationInfo] = useState({});
    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const { doFetch } = useFetch({
        message: {
            showSuccessMsg: true,
            successMsg: 'Informacije o udruzi su uspješno promijenjene',
        },
    });
    const { doFetch: getOrgById } = useFetch({ loading: true });

    const setUserOrganization = useOrganizationStore(state => state.setUserOrganization);
    const setCurrentOrganization = useOrganizationStore(
        state => state.setCurrentOrganization
    );

    const [{ access }] = useLocalStorage('tokens');

    const editOrganizationAddressArr = [
        'state',
        'city',
        'postal_number',
        'street_name',
        'street_numb',
    ];

    const editOrganizationContactArr = ['tel_broj', 'mobitel', 'email'];

    const editOrganizationInfoArr = ['naziv', 'skraceni'];

    const keysObj = {
        contact: editOrganizationContactArr,
        address: editOrganizationAddressArr,
        info: editOrganizationInfoArr,
    };

    const handleEditOrganization = data => {
        let promisesArr = [];
        let changedValues = compareObjWithSameKeys(data, defaultValues);

        let keys = Object.keys(changedValues);
        let correctFunc = findCorrectFunc(keys, keysObj);

        if (correctFunc.length !== 0) {
            correctFunc.forEach(func => {
                if (func === 'contact') {
                    promisesArr.push({
                        func: editOrganizationContact,
                        info: [
                            access,
                            organizationInfo.contactId,
                            {
                                tel_broj: data.tel_broj,
                                mobitel: data.mobitel,
                                email: data.email,
                            },
                        ],
                    });
                }
                if (func === 'address') {
                    promisesArr.push({
                        func: editOrganizationAddress,
                        info: [
                            access,
                            organizationInfo.addressId,
                            {
                                street_name: data.street_name,
                                street_numb: data.street_numb,
                                city: data.city,
                                postal_number: data.postal_number,
                                state: data.state,
                            },
                        ],
                    });
                }
                if (func === 'info') {
                    promisesArr.push({
                        func: editOrganizationInfo,
                        info: [
                            access,
                            organizationInfo.id,
                            { naziv: data.naziv, skraceni: data.skraceni },
                        ],
                    });
                }
            });
            doFetch([
                ...promisesArr,
                { func: getUserOrganization, info: [access] },
                {
                    func: data => {
                        setUserOrganization(data);
                        setCurrentOrganization(data.filter(d => d.id === +id)[0]);
                    },
                },
                { func: () => history.goBack() },
            ]);
        }
    };

    useEffect(() => {
        getOrgById([
            { func: getUserOrganizationById, info: [access, id] },
            {
                func: data =>
                    setOrganizationInfo({
                        ...data.sjediste,
                        addressId: data.sjediste.id,
                        ...data.kontakt,
                        contactId: data.kontakt.id,
                        naziv: data.naziv,
                        skraceni: data.skraceni,
                        id: data.id,
                    }),
            },
        ]);
    }, [getOrgById, access, id]);

    useEffect(() => {
        setEditFields(addDefaultValues(editOrganizationRules, organizationInfo));
    }, [organizationInfo]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <Paper sx={{ width: '40rem' }}>
                {editFields.length !== 0 && checkObject(defaultValues) && (
                    <UpdateForm
                        updateInputs={editFields}
                        onSubmit={handleEditOrganization}
                        defaultValues={defaultValues}
                        updateDisable={true}
                        btnMessage='Promijeni'
                        styleContainer='updateForm__container updateForm__container-2c'
                    >
                        <LinkButton linkFuncGoBack btnMessage='Natrag' />
                    </UpdateForm>
                )}
            </Paper>
        </div>
    );
};

export default EditOrganizationForm;
