import { Paper, Step, StepLabel, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoginForm from '../LoginForm/LoginForm';
import ResetPassFinalForm from './ResetPassForm/ResetPassFinalForm';
import useFetch from '../../hooks/useFetch';
import { Redirect } from 'react-router-dom';
import Banner from '../Banner';
import { confirmResetEmail } from '../../services/user';

const steps = ['Promjena šifre', 'Prijava'];

const ResetPassFinal = props => {
    const uidb64 = props.match.params.uidb64;
    const token = props.match.params.token;
    const { handleGetUserData } = props;

    const [activeStep, setActiveStep] = useState(0);
    const {
        error: { error },
        doFetch,
    } = useFetch({
        message: {
            showErrMsg: true,
            errMsg: 'Token nije valjan, molimo Vas da zatražite novi',
        },
    });

    const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1);

    useEffect(() => {
        doFetch([{ func: confirmResetEmail, info: [uidb64, token] }]);
    }, [doFetch, token, uidb64]);

    if (error) return <Redirect to='/' />;

    return (
        <>
            <Banner />
            <div className='form'>
                <Paper sx={{ width: '24rem' }}>
                    <Stepper activeStep={activeStep} className='form__stepper'>
                        {steps.map((step, index) => (
                            <Step key={step}>
                                <StepLabel>{step}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === 0 ? (
                        <ResetPassFinalForm
                            uidb64={uidb64}
                            token={token}
                            handleNext={handleNext}
                        />
                    ) : (
                        <LoginForm
                            showResetPass={false}
                            containerStyle={'_'}
                            handleGetUserData={handleGetUserData}
                        />
                    )}
                </Paper>
            </div>
        </>
    );
};

export default ResetPassFinal;
