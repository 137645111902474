import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { updateMemberRanks } from './helper';
import {
    initialStateAdmin,
    initialStateMembers,
    initialStateOrganization,
} from './initialStates';

export const useUserStore = create(
    devtools(set => ({
        userData: false,
        setUserData: data => set({ userData: data }),
        clearUserData: () => set({ userData: {} }),
    }))
);

export const useMembersStore = create(
    devtools(set => ({
        ...initialStateMembers,
        setMembers: members => set({ members }),
        setMembersCopy: members => set({ membersCopy: members }),
        setMembersMembershipType: type => set({ membersMembershipType: type }),
        setCurrentMember: currentMember => set({ currentMember }),
        setMemberStatus: status => set({ memberStatus: status }),
        setMemberMedical: medical => set({ memberMedical: medical }),
        setMemberRanks: ranks => set({ memberRanks: ranks }),
        setMemberPayment: payment => set({ memberPayment: payment }),
        setAllMemberFunction: allMembersFunction => set({ allMembersFunction }),
        setMemberFiles: memberFiles => set({ memberFiles }),
        resetMembers: () => set(initialStateMembers),
    }))
);

export const useOrganizationStore = create(
    devtools(set => ({
        ...initialStateOrganization,
        setUserOrganization: org => set({ userOrganizations: org }),
        setCurrentOrganization: organization =>
            set({ currentOrganization: organization }),
        setUsersWithRole: roles => set({ usersWithRole: roles }),
        setMembershipFee: fee => set({ membershipFee: fee }),
        updateMemberRank: (rankId, rankName, memberId, newRanks) => {
            return set(state => {
                const members = useMembersStore.getState().members;
                const ranksCopy = [...state.ranks];

                const updatedMembers = updateMemberRanks(newRanks, memberId, members);
                // find if any member has previous rank
                // if found, return rank wit disabled delete true,
                // if not, return rank with disabled delete false
                return {
                    ranks: ranksCopy.map(r => {
                        if (+r.id === +rankId) {
                            return { ...r, disabledDelete: true };
                        }

                        const foundRank = updatedMembers.findIndex(m => {
                            const memberRanks = m.cinovi;
                            return memberRanks.find(
                                mr => mr.cin === rankName || mr.naziv_cina === rankName
                            );
                        });

                        if (foundRank !== -1) {
                            return { ...r, disabledDelete: true };
                        }

                        return { ...r, disabledDelete: false };
                    }),
                };
            });
        },
        deleteMemberRank: (rankName, memberId, newRanks) =>
            set(state => {
                const ranksCopy = [...state.ranks];
                const members = useMembersStore.getState().members;

                const updatedMembers = updateMemberRanks(newRanks, memberId, members);

                return {
                    ranks: ranksCopy.map(r => {
                        if (r.naziv_cina !== rankName) {
                            return r;
                        }

                        const foundRank = updatedMembers.findIndex(m => {
                            const memberRanks = m.cinovi;
                            return memberRanks.find(
                                mr => mr.naziv_cina === rankName || mr.cin === rankName
                            );
                        });

                        if (foundRank !== -1) {
                            return { ...r, disabledDelete: true };
                        }

                        return { ...r, disabledDelete: false };
                    }),
                };
            }),
        deleteRank: rank_id =>
            set(({ ranks }) => {
                return { ranks: ranks.filter(r => +r.id !== +rank_id) };
            }),
        setRanks: ranks =>
            set(state => {
                const members = useMembersStore.getState().members;
                const { ranks: prevRanks } = state;

                if (members.length === 0) return { ranks };

                return {
                    ranks: ranks.map(rank => {
                        let disabledDelete;
                        const foundRank = prevRanks.find(r => +r.id === +rank.id);

                        if (foundRank?.disabledDelete) {
                            return foundRank;
                        }

                        for (let m of members) {
                            disabledDelete = Boolean(
                                m.cinovi.find(({ cin }) => cin === rank.naziv_cina)
                            );
                            if (disabledDelete) return { ...rank, disabledDelete };
                        }

                        return { ...rank, disabledDelete: false };
                    }),
                };
            }),
        updateRanks: rank_ids =>
            set(state => {
                const ranks = state.ranks;

                return {
                    ranks: ranks.map(rank => {
                        if (rank?.disabledDelete) {
                            return rank;
                        }

                        return rank_ids.find(id => +id === +rank.id)
                            ? { ...rank, disabledDelete: true }
                            : { ...rank };
                    }),
                };
            }),
        setAssemblyData: assemblyData => set({ assemblyData }),
        setLogo: logo => set({ logo }),
        setTransfer: transfer => set({ transfer }),
        setEmailBody: emailBody => set({ emailBody }),
        resetOrg: () => set(initialStateOrganization),
    }))
);

export const useAdminStore = create(
    devtools(set => ({
        ...initialStateAdmin,
        setInactiveOrganization: org => set({ inactiveOrganization: org }),
        setApprovedOrganization: org => set({ approvedOrganization: org }),
        updateApprovedOrganization: (orgId, block) =>
            set(prev => {
                return {
                    approvedOrganization: prev.approvedOrganization.map(org => {
                        let blocked = [{ udruga: orgId, block }].find(
                            ({ udruga }) => udruga === org.id
                        );
                        return blocked ? { ...org, ...blocked } : org;
                    }),
                };
            }),
        setStatistic: statistic => set({ statistic }),
        resetAdmin: () => set(initialStateAdmin),
    }))
);

export const useTableStore = create(
    devtools(set => ({
        order: '',
        setOrder: order => set({ order: order }),
        orderBy: '',
        setOrderBy: orderBy => set({ orderBy: orderBy }),
        openFilter: false,
        setOpenFilter: openFilter => set({ openFilter }),
        toggleOpenFilter: () => set(prev => ({ openFilter: !prev.openFilter })),
        collapseArr: [],
    }))
);

export const useDashboardTabsStore = create(
    devtools(set => ({
        value: 0,
        setValue: value => set({ value }),
    }))
);

export const useShowModalOptions = create(
    devtools(set => ({
        showModal: { show: false, Comp: '', dialogSize: 'sm', info: [] },
        setShowModal: modal => set({ showModal: modal }),
    }))
);
