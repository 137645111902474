import React from 'react';
import { Paper } from '@mui/material';
import { createUserOrganization } from '../../../../services/organizations/organizations';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import capitalizeFirstLetter from '../../../../helpers/capitalizeFirstLetter';
import { organizationsRules } from './addOrganizationRules';
import StepsForm from '../../../FormComp/StepsForm';
import useFetch from '../../../../hooks/useFetch';

const OrganizationForm = () => {
    const [{ access }] = useLocalStorage('tokens');

    const { doFetch } = useFetch({
        message: {
            showSuccessMsg: true,
            successMsg: 'Zahtjev za kreiranje udruge je poslan',
            showErrMsg: true,
            errMsg: 'Došlo je do greške prilikom kreiranja udruge',
        },
    });

    const handleCreateOrganization = (data, handleNext) => {
        const { tel_broj, mobitel, email } = data;

        doFetch([
            {
                func: createUserOrganization,
                info: [
                    access,
                    {
                        naziv: capitalizeFirstLetter(data.naziv),
                        skraceni: capitalizeFirstLetter(data.skraceni),
                        reg_broj: data.reg_broj,
                        oib: data.oib,
                        iban: data.iban,
                        ljecnicki_pregled:
                            data.ljecnicki_pregled !== '' && data.ljecnicki_pregled,
                        contact: { tel_broj, mobitel, email },
                        address: {
                            state: capitalizeFirstLetter(data.state),
                            city: capitalizeFirstLetter(data.city),
                            street_name: capitalizeFirstLetter(data.street_name),
                            postal_number: data.postal_number,
                            street_numb: data.street_numb,
                        },
                    },
                ],
            },
            { func: () => handleNext() },
            //{ func: () => userData?.is_superuser && handleGetInactiveOrg() },
        ]);
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
            }}
        >
            <Paper sx={{ width: '40rem' }}>
                <StepsForm
                    updateInputs={organizationsRules}
                    styleContainer='updateForm__container updateForm__container-2c'
                    onSubmit={handleCreateOrganization}
                    hideBackLink
                />
            </Paper>
        </div>
    );
};

export default OrganizationForm;
