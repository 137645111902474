import { BASE_URL } from './baseUrl';
import transformToFormData from '../helpers/transformToFormData';

export const activateOrganization = (token, id, status) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADMIN_ACTIVATE}${id}/${status}/`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
    });
};

export const getInactiveOrganization = token => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADMIN_LIST_INACTIVE}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
    });
};

export const getApprovedOrganizations = token => {
    return fetch(`${BASE_URL}/udruga/list-active/`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
    });
};

export const blockOrganization = (token, data, signal) => {
    return fetch(`${BASE_URL}/udruga/blocking/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};

export const getBlockedOrganization = (token, signal) => {
    return fetch(`${BASE_URL}/udruga/blocking/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const unblockOrganization = (token, data, signal) => {
    return fetch(`${BASE_URL}/udruga/unblock/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: transformToFormData(data),
        signal,
    });
};

export const addCommentBeforeDeleteOrg = (token, data, id, signal) => {
    return fetch(`${BASE_URL}/udruga/comment-before-delete/${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const getStatistic = (token, signal) => {
    return fetch(`${BASE_URL}/statistic/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const getStatisticMeetings = (token, signal) => {
    return fetch(`${BASE_URL}/statistic/meetings`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const getStatisticMember = (token, signal) => {
    return fetch(`${BASE_URL}/statistic/member`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const getStatisticUserActivity = (token, signal) => {
    return fetch(`${BASE_URL}/statistic/user_activity/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const getStatisticActiveMembers = (token, signal) => {
    return fetch(`${BASE_URL}/statistic/active-members-perassociation/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};
