import React from 'react';
import {Link} from 'react-router-dom'

const About = () => {
    return (
        <div className='about__main' id={'about'}>
            <section className=''>
                <div className='hero-box-container'>
                    <a href='/#' className='hero-box'>
                        <span className='hero-box__circle hero-box__circle--blue'></span>
                        <h4 className='hero-box__title'>Jednostavno</h4>
                        <p className='hero-box__body'>
                            Jednostavno sučelje
                            <br/> za sve korisnike
                        </p>
                    </a>
                    <a href='/#' className='hero-box'>
                        <span className='hero-box__circle hero-box__circle--green'></span>
                        <h4 className='hero-box__title'>Dostupno</h4>
                        <p className='hero-box__body'>
                            Dostupnost podataka
                            <br/> u bilo koje vrijeme
                        </p>
                    </a>
                    <a href='/#' className='hero-box'>
                        <span className='hero-box__circle hero-box__circle--orange'></span>
                        <h4 className='hero-box__title'>Evidencija</h4>
                        <p className='hero-box__body'>
                            Evidencija svih podataka
                            <br/> udruge na jednom mjestu
                        </p>
                    </a>
                    <a href='/#' className='hero-box'>
                        <span className='hero-box__circle hero-box__circle--blue'></span>
                        <h4 className='hero-box__title'>Kontroliranje</h4>
                        <p className='hero-box__body'>
                            Lako kontroliranje
                            <br/> svih članova udruge
                        </p>
                    </a>
                    <a href='/#' className='hero-box'>
                        <span className='hero-box__circle hero-box__circle--green'></span>
                        <h4 className='hero-box__title'>Razvoj</h4>
                        <p className='hero-box__body'>
                            Stalni razvoj
                            <br/> usmjeren na korisnika
                        </p>
                    </a>
                </div>
            </section>

            <div className='box'>
                <div className='top-banner'>
                    <div className='top-banner--text' style={{zIndex: '9999'}}>

                            MojaUdruga je servis koji pomaže korisnicima pri upravljanju
                            njihovim udrugama. Aplikacija je u cijelosti prilagodljiva
                            potrebama korisnika, što olakšava proces vođenja udruge.
                            Problemi praćenja zdravstvenih pregleda i skupština stvar su
                            prošlosti. Slanje članarine nikada nije bilo lakše. Sustav je
                            kreiran kako bi proces vođenja udruge bio što bezbolniji,
                            stoga je osobiti naglasak stavljen na jednostavnost i
                            praktičnost. Sustav MojaUdruga može se koristiti bez ikakve
                            naknade za udruge registrane u Republici Hrvatskoj.

                        <br/>
                        <br/>

                        <div className="desktop">
                            Za korištenje sustava potrebna je
                        <Link to={'/register'} style={{
                            position: 'absolute',
                            color: 'whitesmoke',
                            marginLeft: '5px',
                            textDecoration: 'none'
                        }}><b>registracija.</b></Link>
                        </div>
                        <div className="mobile">
                            Za korištenje sustava potrebna je
                            <br/>
                        <Link to={'/register'} style={{
                            position: 'absolute',
                            color: 'whitesmoke',
                            marginLeft: '5px',
                            textDecoration: 'none'
                        }}><b>registracija.</b></Link>
                        </div>
                        <br/>
                        Email: <b>info@udruga.online</b>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
