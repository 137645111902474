import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Paper,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import {
    useMembersStore,
    useOrganizationStore,
    useShowModalOptions,
} from '../../../Store/Store';
import EditMemberForm from '../MemberDetails/EditMemberForm/EditMemberForm';
import EditAddressAndContactForm from '../MemberDetails/EditAddressAndContactForm/EditAddressAndContactForm';
import MedicalMember from '../MemberDetails/MedicalMember/MedicalMember';
import FunctionMember from '../MemberDetails/FunctionMember/FunctionMember';
import EditStatus from '../MemberDetails/EditStatus/EditStatus';
import MembershipStatusForm from '../MemberDetails/MembershipStatus/MembershipStatusForm';
import Membership from '../MemberDetails/Membership/Membership';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import MemberRank from '../MemberDetails/MemberRank/MemberRank';
import { deleteMember, getMembers } from '../../../../services/members/members';
import { getMemberStatus } from '../../../../services/members/status';
import { getMedicalList } from '../../../../services/members/medical';
import { getMemberRanks } from '../../../../services/members/ranks';
import { getPayment } from '../../../../services/members/payment';
import { FeedbackDispatch } from '../../../Store/Feedback/FeedbackContext';
import { getAllMemberFunction } from '../../../../services/members/function';
import ConfirmDeleteModal from '../../ConfirmDeleteModal';
import EditMemberFIles from '../MemberDetails/EditMemberFiles/EditMemberFIles';
import { getMemberFile } from '../../../../services/members/files';

const editFormsDefault = [
    { name: 'Osobne informacije', Comp: EditMemberForm, disabled: false },
    { name: 'Adresa i kontakt', Comp: EditAddressAndContactForm, disabled: false },
    { name: 'Status', Comp: EditStatus, disabled: false },
    { name: 'Funkcija', Comp: FunctionMember, disabled: false },
    { name: 'Vrsta članstva', Comp: MembershipStatusForm, disabled: false },
    { name: 'Razina osposobljenosti', Comp: MemberRank, disabled: false },
    { name: 'Članarina', Comp: Membership, disabled: false },
    { name: 'Pristupnica', Comp: EditMemberFIles, disabled: false },
];

const EditMember = () => {
    const { member_id, id } = useParams();
    const history = useHistory();
    const { url } = useRouteMatch();
    const location = useLocation();

    const [expanded, setExpanded] = useState(false);

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch: goBackFetch } = useFetch({ loading: true });
    const { doFetch: filterfetch } = useFetch();
    const { doFetch } = useFetch({ loading: true });

    const { naziv, ljecnicki_pregled } = useOrganizationStore(
        state => state.currentOrganization
    );

    const dispatch = useContext(FeedbackDispatch);
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const showMessage = ({ msg, type }) => {
        dispatch({
            type: 'setMessage',
            message: { open: true, type, msg },
        });
    };

    const handleOpenConfirmDelete = () => {
        setShowModal({
            show: true,
            Comp: ConfirmDeleteModal,
            dialogSize: 'xs',
            info: { handleDelete },
        });
    };

    const handleClose = () => setShowModal({ show: false, Comp: '' });

    const members = useMembersStore(state => state.members);
    const setMembers = useMembersStore(state => state.setMembers);
    const setMembersCopy = useMembersStore(state => state.setMembersCopy);
    const currentMember = useMembersStore(state => state.currentMember);
    const setCurrentMember = useMembersStore(state => state.setCurrentMember);
    const setMemberStatus = useMembersStore(state => state.setMemberStatus);
    const setMemberMedical = useMembersStore(state => state.setMemberMedical);
    const setMemberRanks = useMembersStore(state => state.setMemberRanks);
    const setMemberPayment = useMembersStore(state => state.setMemberPayment);
    const setAllMemberFunction = useMembersStore(state => state.setAllMemberFunction);
    const setMemberFiles = useMembersStore(state => state.setMemberFiles);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDelete = () => {
        dispatch({ type: 'startLoading' });
        deleteMember(access, member_id)
            .then(res => {
                if (!res.ok) {
                    return Promise.reject(
                        new Error('Došlo je do greške prilikom brisanja člana', {
                            cause: 'delete',
                        })
                    );
                }
                return getMembers(access, naziv);
            })
            .then(res => {
                if (!res.ok) {
                    return Promise.reject(
                        new Error('Došlo je do greške prilikom dohvaćanja članova', {
                            cause: 'members',
                        })
                    );
                }
                return res.json();
            })
            .then(data => {
                setMembersCopy(data);
                setMembers(data);

                if (data.length === 0) {
                    setAllMemberFunction([]);
                    handleClose();
                    history.push(`${url.slice(0, -15)}/add-member`);
                } else {
                    return getAllMemberFunction(access, id).then(res => {
                        if (!res.ok) {
                            return Promise.reject(
                                new Error(
                                    'Došlo je do greške prilikom dohvaćanja članova',
                                    {
                                        cause: 'members',
                                    }
                                )
                            );
                        }
                        return res.json();
                    });
                }

                return Promise.resolve('');
            })
            .then(data => {
                let msg = 'Član je uspješno obrisan';
                showMessage({ msg, type: 'info' });

                if (Array.isArray(data)) {
                    setAllMemberFunction(data);
                    handleClose();
                    history.goBack();
                }
            })
            .catch(({ message, cause }) => {
                if (cause === 'members') {
                    handleClose();
                    history.goBack();
                }
                showMessage({ msg: message, type: 'error' });
            })
            .finally(() => dispatch({ type: 'stopLoading' }));
    };

    const handleGoBack = () => {
        goBackFetch([
            { func: getMembers, info: [access, naziv] },
            {
                func: data => {
                    setMembers(data);
                    setMembersCopy(data);
                },
            },
            { func: () => history.goBack() },
        ]);
    };

    const filterMember = useCallback(() => {
        filterfetch([
            { func: getMembers, info: [access, naziv] },
            {
                func: data => {
                    if (typeof data === 'object' && Object.keys(data).length !== 0) {
                        setCurrentMember(data.filter(res => res.id === +member_id)[0]);
                    }
                },
            },
        ]);
    }, [access, naziv, filterfetch, member_id, setCurrentMember]);

    useEffect(() => {
        if (typeof members === 'object' && Object.keys(members).length !== 0) {
            setCurrentMember(members.filter(res => res.id === +member_id)[0]);
        }
    }, [member_id, members, setCurrentMember]);

    useEffect(() => {
        location?.state?.call &&
            doFetch([
                { func: getMemberStatus, info: [access, member_id] },
                { func: data => setMemberStatus(data) },
                ...(ljecnicki_pregled
                    ? [
                          { func: getMedicalList, info: [access, member_id] },
                          { func: data => setMemberMedical(data) },
                      ]
                    : []),
                { func: getMemberRanks, info: [access, member_id] },
                { func: data => setMemberRanks(data) },
                { func: getPayment, info: [access, member_id] },
                { func: data => setMemberPayment(data) },
                { func: getMemberFile, info: [access, member_id] },
                { func: data => setMemberFiles(data) },
            ]);
    }, [
        access,
        member_id,
        doFetch,
        setMemberStatus,
        setMemberMedical,
        setMemberRanks,
        setMemberPayment,
        setMemberFiles,
        ljecnicki_pregled,
        location.state,
    ]);

    return (
        typeof currentMember === 'object' &&
        Object.keys(currentMember).length !== 0 && (
            <div
                style={{
                    margin: '2.4rem 0',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Paper sx={{ width: '60rem' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '1rem',
                        }}
                    >
                        <Typography variant='h6'>
                            {`Član: ${currentMember.ime} ${currentMember.prezime}`}
                        </Typography>
                        <div>
                            <Button onClick={handleGoBack} variant='contained'>
                                Natrag
                            </Button>
                            <Button
                                onClick={handleOpenConfirmDelete}
                                sx={{ ml: 3 }}
                                color='error'
                                variant='outlined'
                            >
                                Obriši člana
                            </Button>
                        </div>
                    </div>
                    {(ljecnicki_pregled
                        ? [
                              ...editFormsDefault.slice(0, 2),
                              {
                                  name: 'Liječnički pregled',
                                  Comp: MedicalMember,
                                  disabled: false,
                              },
                              ...editFormsDefault.slice(2),
                          ]
                        : editFormsDefault
                    ).map(({ name, Comp, disabled }) => (
                        <Accordion
                            style={{ margin: '1rem' }}
                            key={name}
                            expanded={expanded === name}
                            onChange={handleChange(name)}
                            disabled={disabled}
                            TransitionProps={{ unmountOnExit: true }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1bh-content'
                                id='panel1bh-header'
                            >
                                <Typography variant='body1'>{name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Comp
                                    currentMember={currentMember}
                                    filterMember={filterMember}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Paper>
            </div>
        )
    );
};

export default EditMember;
