import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Stack, Tooltip, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { blue } from '@mui/material/colors';
import { useOrganizationStore, useShowModalOptions } from '../../../Store/Store';
import UploadLogo from './UploadLogo';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { deleteLogo, getLogo } from '../../../../services/organizations/logo';
import { FeedbackDispatch } from '../../../Store/Feedback/FeedbackContext';
import OptionsMenu from './OptionsMenu';

const hasObjectEmptyKey = object => {
    for (let key in object) {
        if (object[key] === '') {
            return false;
        }
    }
    return true;
};

const OrganizationLogo = ({ organization, handleGetUserOrg }) => {
    const [showDot, setShowDot] = useState(false);

    const [{ access }] = useLocalStorage('tokens');
    const dispatch = useContext(FeedbackDispatch);

    const showMessage = ({ msg, type }) => {
        dispatch({
            type: 'setMessage',
            message: { open: true, type, msg },
        });
    };

    const logo = useOrganizationStore(state => state.logo);
    const setLogo = useOrganizationStore(state => state.setLogo);
    const currentOrganization = useOrganizationStore(state => state.currentOrganization);

    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleClose = () => setShowModal({ show: false, Comp: '' });

    const handleOpenUpload = () => {
        setShowModal({
            show: true,
            Comp: UploadLogo,
            dialogSize: 'sm',
            info: { handleDelete },
        });
    };

    const handleDelete = (closeModal = true, cb) => {
        dispatch({ type: 'startLoading' });
        deleteLogo(access, logo.id)
            .then(res => {
                if (!res.ok) {
                    return Promise.reject('Došlo je do greške prilikom brisanja loga');
                }
                return getLogo(access, currentOrganization.id);
            })
            .then(res => {
                if (!res.ok) {
                    return Promise.reject('Došlo je do greške prilikom dohvaćanja loga');
                }
                return res.json();
            })
            .then(data => {
                setLogo(data);
                showMessage({ msg: 'Logo je uspješno obrisan', type: 'info' });
            })
            .catch(err => showMessage({ msg: err, type: 'error' }))
            .finally(() => {
                typeof cb === 'function' && cb();
                closeModal && handleClose();
                dispatch({ type: 'stopLoading' });
            });
    };

    useEffect(() => {
        setShowDot(
            hasObjectEmptyKey({
                naziv: currentOrganization.naziv,
                skraceni: currentOrganization.skraceni,
                ...currentOrganization.kontakt,
                ...currentOrganization.sjediste,
            })
        );
    }, [currentOrganization]);

    return (
        <Stack
            alignItems='center'
            justifyContent='space-around'
            sx={{ mt: 1, mb: 3 }}
            gap={1}
        >
            <Avatar
                sx={{ bgcolor: blue[500], width: 88, height: 88 }}
                src={logo?.url}
                alt={logo['şlika']}
            >
                {organization?.naziv.charAt(0)}
            </Avatar>
            <Stack direction='row' alignItems='center'>
                {!showDot && (
                    <Tooltip
                        title={
                            <Typography variant='body2'>
                                Potrebno je dodati informacije o udruzi
                            </Typography>
                        }
                    >
                        <ErrorOutlineIcon color='error' />
                    </Tooltip>
                )}
                <Typography mx={1} variant='h5'>
                    {organization?.naziv}
                </Typography>
                <OptionsMenu
                    handleGetUserOrg={handleGetUserOrg}
                    id={organization?.id}
                    handleOpenUpload={handleOpenUpload}
                    showDot={showDot}
                />
            </Stack>
        </Stack>
    );
};

export default OrganizationLogo;
