import { Button, Divider, Paper, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Switch, Route, useParams, useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import filterRole from '../../../helpers/filterRole';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useFetch from '../../../hooks/useFetch';
import { useOrganizationStore, useShowModalOptions } from '../../Store/Store';
import TableComp from '../../TableComp/TableComp';
import AddUserRoleForm from './AddUserRoleForm/AddUserRoleForm';
import EditUserRoleForm from './EditUserRoleForm/EditUserRoleForm';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TableCollapse from '../../TableComp/TableCollapse';
import {
    deleteUserWithRole,
    getUsersWithRole,
} from '../../../services/organizations/roles';
import Help from '../Help';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ConfirmDeleteModal from '../ConfirmDeleteModal';

const userRolesTableInfo = [
    { id: 'email', label: 'Email', type: 'string' },
    { id: 'od', label: 'Početak ovlasti', type: 'date' },
    { id: 'do', label: 'Kraj ovlasti', type: 'date' },
    { id: 'role', label: 'Razina ovlasti', type: 'string' },
    { id: 'delete', label: '', btn: true },
    { id: '', btn: true, collapse: true },
];

const UserRolesTable = ({ handleGetUserOrg }) => {
    const history = useHistory();
    const { id } = useParams();
    const { url, path } = useRouteMatch();

    const [email] = useLocalStorage('email');
    const [{ access }] = useLocalStorage('tokens');

    const { doFetch } = useFetch();
    const { doFetch: fetchDeleteRole } = useFetch({ message: { showErrMsg: true } });

    const usersWithRole = useOrganizationStore(state => state.usersWithRole);
    const setUsersWithRole = useOrganizationStore(state => state.setUsersWithRole);
    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleClose = useCallback(() => {
        setShowModal({ show: false, Comp: '' });
    }, [setShowModal]);

    const btnsArr = [
        {
            name: 'delete',
            onClick: ({ role_id, email: userEmail }) =>
                setShowModal({
                    show: true,
                    Comp: ConfirmDeleteModal,
                    dialogSize: 'xs',
                    info: { role_id, userEmail, handleDelete },
                }),
            icon: <DeleteForever />,
        },
    ];

    const handleGetUsersWithRole = useCallback(
        cb => {
            doFetch([
                { func: getUsersWithRole, info: [access, id] },
                {
                    func: data =>
                        data.length !== 0 &&
                        setUsersWithRole(
                            data.map(el => {
                                return { ...el, role: filterRole(el.role) };
                            })
                        ),
                },
                { func: cb },
            ]);
        },
        [access, id, doFetch, setUsersWithRole]
    );

    const handleDelete = useCallback(
        ({ role_id, userEmail }) => {
            fetchDeleteRole([
                { func: deleteUserWithRole, info: [access, id, role_id] },
                {
                    func: () => {
                        if (userEmail === email) {
                            handleGetUserOrg(() => {
                                handleClose();
                                history.push('/dashboard');
                            });
                        } else {
                            handleGetUsersWithRole(() => handleClose());
                        }
                        return null;
                    },
                },
            ]);
        },
        [
            access,
            email,
            fetchDeleteRole,
            handleGetUserOrg,
            handleGetUsersWithRole,
            history,
            handleClose,
            id,
        ]
    );

    useEffect(() => handleGetUsersWithRole(), [handleGetUsersWithRole]);

    return (
        <Switch>
            <Route exact path={path}>
                <Paper sx={{ maxWidth: '54rem', margin: '1rem auto' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '1.6rem',
                        }}
                    >
                        <Typography variant='h5'>
                            Korisnici ovlašteni za upravljanje udrugom u sustavu
                        </Typography>
                        <div
                            style={{
                                flex: '0 0 20%',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                component={Link}
                                to={`${url}/add-role`}
                                variant='outlined'
                            >
                                <AddIcon />
                            </Button>
                            <Button
                                color='info'
                                variant='outlined'
                                onClick={() =>
                                    setShowModal({
                                        show: true,
                                        Comp: Help,
                                        dialogSize: 'md',
                                    })
                                }
                            >
                                <HelpOutlineIcon />
                            </Button>
                        </div>
                    </div>
                    <Divider />
                    <TableComp
                        btns
                        btnsArr={btnsArr}
                        head={userRolesTableInfo}
                        body={usersWithRole}
                    >
                        <TableCollapse>
                            <EditUserRoleForm
                                handleGetUsersWithRole={handleGetUsersWithRole}
                            />
                        </TableCollapse>
                    </TableComp>
                </Paper>
            </Route>
            <Route exact path={`${path}/add-role`}>
                <AddUserRoleForm handleGetUsersWithRole={handleGetUsersWithRole} />
            </Route>
        </Switch>
    );
};

export default UserRolesTable;
