import { Button, Checkbox, Collapse, TableCell, TableRow, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import useToggle from '../../hooks/useToggleState';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import TableCollapse from './TableCollapse';
import TableCheckboxToolbar from './TableCheckboxToolbar';
import formatDateDmy from '../../helpers/formatDateDmy';
import { blue } from '@mui/material/colors';
import CellButton from './CellButton';

const ShowTooltip = ({ str }) => {
    if (typeof str !== 'string' || str.length === 0) {
        return <TableCell align='center'></TableCell>;
    }

    return (
        <Tooltip title={str.length <= 16 ? '' : str}>
            <TableCell align='center'>{`${str.slice(0, 16)} ${
                str.length <= 16 ? '' : '...'
            }`}</TableCell>
        </Tooltip>
    );
};

const TableRowComp = props => {
    const {
        head,
        item,
        btns,
        btnsArr,
        children,
        isItemSelected,
        labelId,
        handleClick,
        activeRow,
        rowOnClick,
    } = props;
    const [open, toggleOpen] = useToggle(false);
    const [currentRow, setCurrentRow] = useState('');

    const handleCollapse = row => {
        setCurrentRow(row);
        toggleOpen();
    };

    return (
        <React.Fragment>
            <TableRow
                sx={
                    activeRow && activeRow?.func() === item[activeRow?.id]
                        ? { backgroundColor: blue[50] }
                        : {}
                }
                hover
                role='checkbox'
                tabIndex={-1}
                onClick={e => {
                    if (e.target.nodeName === 'TD' && typeof rowOnClick === 'function') {
                        rowOnClick(item);
                    }
                }}
            >
                {children.filter(c => c.type === TableCheckboxToolbar).length !== 0 && (
                    <TableCell padding='checkbox'>
                        <Checkbox
                            color='primary'
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                            onClick={event => handleClick(event, item.id)}
                        />
                    </TableCell>
                )}
                {head.map(el => (
                    <React.Fragment key={el.id}>
                        {btns && el.btn && (
                            <CellButton btnsArr={btnsArr} item={item} el={el} />
                        )}
                        {el.collapse && (
                            <TableCell align='center'>
                                <Button onClick={() => handleCollapse(item)}>
                                    {open ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </Button>
                            </TableCell>
                        )}
                        {item[el.id] === null ? (
                            <TableCell align='center'>{<ClearIcon />}</TableCell>
                        ) : (
                            <>
                                {el?.type === 'string' && (
                                    <TableCell align='center'>{item[el.id]}</TableCell>
                                )}
                                {el?.type === 'boolean' && (
                                    <>
                                        {(typeof item[el.id] === 'string' ||
                                            typeof item[el.id] === 'number') && (
                                            <TableCell align='center'>
                                                {item[el.id]}
                                            </TableCell>
                                        )}
                                        {typeof item[el.id] === 'boolean' && (
                                            <TableCell align='center'>
                                                {item[el.id] ? (
                                                    <CheckIcon />
                                                ) : (
                                                    <ClearIcon />
                                                )}
                                            </TableCell>
                                        )}
                                    </>
                                )}
                                {el?.type === 'date' && (
                                    <TableCell align='center'>
                                        {formatDateDmy(item[el.id])}
                                    </TableCell>
                                )}
                                {el?.type === 'function' &&
                                    typeof el?.info === 'function' && (
                                        <TableCell align='center'>
                                            {el.info(item[el.id])}
                                        </TableCell>
                                    )}
                                {el?.type === 'object' &&
                                    Object.keys(item[el.id]).length !== 0 && (
                                        <ShowTooltip str={item[el.id][el?.key]} />
                                    )}
                            </>
                        )}
                    </React.Fragment>
                ))}
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={20}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        {React.Children.map(
                            children.filter(c => c.type === TableCollapse),
                            child => React.cloneElement(child, { currentRow })
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default TableRowComp;
