import formatDate from '../../../../helpers/formatDate';
import { uploadMemberFile } from '../../../../services/members/files';
import { addMembershipType } from '../../../../services/members/membershipType';
import { addRankMember } from '../../../../services/members/ranks';
import { addStatus } from '../../../../services/members/status';

export const handleFetchArr = (memberData, data, access) => {
    const { selectedFile } = data;

    let arr = [
        {
            func: addStatus,
            info: [
                access,
                {
                    clan_od: formatDate(data.clan_od),
                    clan: +memberData.id,
                },
            ],
            error: new Error('Došlo je do greške prilikom dodavanja statusa', {
                cause: 'status',
            }),
        },
        {
            func: addMembershipType,
            info: [
                access,
                {
                    clan: +memberData.id,
                    vrsta_clanstva: +data.vrsta_clanstva,
                    pocetak_clanstva: formatDate(data.pocetak_clanstva),
                },
            ],
            error: new Error('Došlo je do greške prilikom dodavanja vrste članstva', {
                cause: 'membership',
            }),
        },
    ];

    if (data?.cinovi) {
        const { cinovi, cinovi_kraj, cinovi_pocetak } = data;

        const fetchArr = cinovi.map(el => ({
            func: addRankMember,
            info: [
                access,
                {
                    cinovi_pocetak: formatDate(cinovi_pocetak) || null,
                    cinovi_kraj: formatDate(cinovi_kraj) || null,
                    cinovi: el,
                    clan: +memberData.id,
                },
            ],
            error: new Error(
                'Došlo je do greške prilikom dodavanja razine osposobljenosti',
                {
                    cause: 'rank',
                }
            ),
        }));

        arr = [...arr, ...fetchArr];
    }

    if (Array.isArray(selectedFile) && selectedFile.length !== 0) {
        return [
            ...arr,
            {
                func: uploadMemberFile,
                info: [access, { clan: +memberData.id, pdf: selectedFile[0] }],
                error: new Error('Došlo je do greške prilikom dodavanja pristupnice', {
                    cause: 'file',
                }),
            },
        ];
    }

    return arr;
};
