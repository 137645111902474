import React from 'react';
import { useParams } from 'react-router-dom';
import formatDate from '../../../../../../helpers/formatDate';
import useFetch from '../../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../../hooks/useLocalStorage';
import UpdateForm from '../../../../../FormComp/UpdateForm';
import { addFormRules } from './addFormRules';
import { addMedicalMember } from '../../../../../../services/members/medical';

const AddForm = ({ getUserMedical }) => {
    const { member_id } = useParams();

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch(/* { message: { showErrMsg: true } } */);

    const handleAddMedical = data => {
        const { datum_pregleda, vrijedi_do, status_pregleda } = data;

        doFetch([
            {
                func: addMedicalMember,
                info: [
                    access,
                    {
                        datum_pregleda: formatDate(datum_pregleda),
                        vrijedi_do: formatDate(vrijedi_do),
                        status_pregleda:
                            typeof status_pregleda === 'boolean' && status_pregleda,
                        clan: member_id,
                    },
                ],
            },
            { func: getUserMedical },
        ]);
    };

    return (
        <UpdateForm
            updateInputs={addFormRules}
            onSubmit={handleAddMedical}
            btnMessage={'Dodaj'}
            styleContainer='updateForm__container updateForm__container-3c'
            clearFields
        />
    );
};

export default AddForm;
