import React, { useEffect, useState } from 'react';
import Close from '@mui/icons-material/Close';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Paper,
    Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useOrganizationStore } from '../../../../Store/Store';
import AssemblyEditForm from './AssemblyEditForm';
import UploadFileEditForm from './UploadFileEditForm';
import useFetch from '../../../../../hooks/useFetch';
import { getAssemblyFile } from '../../../../../services/organizations/assembly';
import useLocalStorage from '../../../../../hooks/useLocalStorage';

const editAssemblyAccArr = [
    { name: 'Osnovne informacije', Comp: AssemblyEditForm },
    { name: 'Datoteke', Comp: UploadFileEditForm },
];

const EditAssembly = ({ handleOrganizationAssembly }) => {
    const { assembly_id } = useParams();
    const history = useHistory();

    const [assemblyFiles, setAssemblyFiles] = useState([]);
    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch();

    const [expanded, setExpanded] = useState(false);

    const [currentAssembly, setCurrentAssembly] = useState([]);

    const assemblyData = useOrganizationStore(state => state.assemblyData);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleGoBack = () => history.goBack();

    useEffect(() => {
        setCurrentAssembly(assemblyData.filter(a => a.id === +assembly_id)[0]);
    }, [assemblyData, assembly_id]);

    useEffect(() => {
        doFetch([
            { func: getAssemblyFile, info: [access, assembly_id] },
            { func: data => setAssemblyFiles(data) },
        ]);
    }, [doFetch, access, assembly_id]);

    return currentAssembly ? (
        <Paper sx={{ width: '48rem', padding: '0.2rem 0', margin: '1rem auto' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '1rem',
                }}
            >
                <Typography variant='h6'>
                    {`Naziv skupštine: ${currentAssembly.naziv}`}
                </Typography>
                <IconButton onClick={handleGoBack}>
                    <Close />
                </IconButton>
            </div>
            {editAssemblyAccArr.map(({ name, Comp }) => (
                <Accordion
                    style={{ margin: '1rem' }}
                    key={name}
                    expanded={expanded === name}
                    onChange={handleChange(name)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                    >
                        <Typography variant='body1'>{name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Comp
                            currentRow={currentAssembly}
                            handleOrganizationAssembly={handleOrganizationAssembly}
                            assemblyFiles={assemblyFiles}
                            setAssemblyFiles={setAssemblyFiles}
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
        </Paper>
    ) : null;
};

export default EditAssembly;
