import { Card, CardMedia } from '@mui/material';
import getFileExtension from '../../../../helpers/getFileExtension';

const ImagePreviewTooltip = ({ url, name }) => {
    const fileExtension = getFileExtension(name).pop();

    if (fileExtension !== 'jpg' && fileExtension !== 'jpeg') {
        return 'Preuzmi';
    }

    return (
        <Card>
            <CardMedia component='img' alt={name} image={url} />
        </Card>
    );
};

export default ImagePreviewTooltip;
