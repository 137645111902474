import React, { useState, useMemo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    Divider,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
} from '@mui/material';
import TableRowComp from './TableRowComp';
import TableHeadComp from './TableHeadComp';
import { getComparator, stableSort } from '../../helpers/sortTable';
import { useTableStore } from '../Store/Store';
import TableCheckboxToolbar from './TableCheckboxToolbar';

const TableComp = props => {
    const {
        maxTableHeight,
        head,
        body,
        btns,
        btnsArr,
        pagination,
        children,
        activeRow,
        rowOnClick,
    } = props;

    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const order = useTableStore(state => state.order);
    const setOrder = useTableStore(state => state.setOrder);
    const orderBy = useTableStore(state => state.orderBy);
    const setOrderBy = useTableStore(state => state.setOrderBy);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            if (selected.length !== 0) return setSelected([]);
            return setSelected(body.map(n => n.id));
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const childrenMemo = useMemo(() => {
        return children ? (Array.isArray(children) ? children : [children]) : [];
    }, [children]);

    useEffect(() => {
        if (body) {
            setSelected([]);
            setRowsPerPage(10);
            setPage(0);
        }
    }, [body]);

    return (
        <>
            {children &&
                React.Children.map(
                    childrenMemo.filter(c => c.type === TableCheckboxToolbar),
                    child => React.cloneElement(child, { selected, setSelected })
                )}
            <Divider />
            <TableContainer sx={{ maxHeight: maxTableHeight }}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHeadComp
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={head}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={body.length}
                        children={childrenMemo}
                    />
                    <TableBody>
                        {stableSort(body, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => {
                                const isItemSelected = selected.indexOf(row.id) !== -1;
                                const labelId = `enhanced-table-checkbox-${i}`;

                                return (
                                    <TableRowComp
                                        key={uuidv4()}
                                        rowOnClick={rowOnClick}
                                        btns={btns}
                                        btnsArr={btnsArr}
                                        item={row}
                                        head={head}
                                        children={childrenMemo}
                                        isItemSelected={isItemSelected}
                                        labelId={labelId}
                                        handleClick={handleClick}
                                        activeRow={activeRow}
                                    />
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {pagination && body.length > 5 && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    component='div'
                    count={body.length}
                    rowsPerPage={rowsPerPage}
                    page={page * rowsPerPage > body.length ? 0 : page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={'Redova po stranici'}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} od ${count !== -1 ? count : `više od ${to}`}`
                    }
                    showFirstButton={true}
                    showLastButton={true}
                />
            )}
        </>
    );
};

export default TableComp;
