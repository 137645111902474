import React, { useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useOrganizationStore } from '../../../Store/Store';
import TableComp from '../../../TableComp/TableComp';
import AddForm from './AddForm/AddForm';
import EditForm from './EditForm/EditForm';
import TableCollapse from '../../../TableComp/TableCollapse';

const MembershipFeeTable = ({ handleGetMembershipFee }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const membershipFee = useOrganizationStore(state => state.membershipFee);
    const currentOrganization = useOrganizationStore(state => state.currentOrganization);

    const membershipFeeTableInfo = useMemo(
        () => [
            { id: 'godina', label: 'Godina', type: 'date' },
            { id: 'iznos_redovni', label: 'Iznos za redovne', type: 'string' },
            { id: 'iznos_pridruzeni', label: 'Iznos za pridružene', type: 'string' },
            { id: 'iznos_pocasni', label: 'Iznos za počasne', type: 'string' },
            { id: 'iznos_ostalo', label: 'Iznos za ostale', type: 'string' },
            ...(!currentOrganization?.block
                ? [{ id: '', btn: true, collapse: true }]
                : []),
        ],
        [currentOrganization?.block]
    );

    return Array.isArray(membershipFee) && membershipFee.length !== 0 ? (
        <>
            <div style={{ marginBottom: '2rem' }}>
                <Accordion
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                    disabled={currentOrganization?.block}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                    >
                        <Typography variant='body1'>Dodaj članarinu</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AddForm
                            setExpanded={setExpanded}
                            handleGetMembershipFee={handleGetMembershipFee}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
            <TableComp head={membershipFeeTableInfo} body={membershipFee}>
                <TableCollapse>
                    <EditForm handleGetMembershipFee={handleGetMembershipFee} />
                </TableCollapse>
            </TableComp>
        </>
    ) : (
        <AddForm
            setExpanded={setExpanded}
            handleGetMembershipFee={handleGetMembershipFee}
        />
    );
};

export default MembershipFeeTable;
