import { BASE_URL } from '../baseUrl';

export const sendPaymentByEmail = async (
    token,
    ids,
    year,
    udruga_id,
    dodaj_stari_dug,
    signal
) => {
    return await fetch(
        `${BASE_URL}${process.env.REACT_APP_PAYMENT_EMAIL}${ids},${year},${udruga_id},${dodaj_stari_dug}`,
        {
            credentials: 'include',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'POST',
            body: JSON.stringify({}),
            signal,
        }
    );
};
