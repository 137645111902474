import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const graphSettings = {
    options: {
        plotOptions: {
            pie: {
                /* dataLabels: {
                    offset: 100,
                }, */
                expandOnClick: false,
                offsetX: 20,
                donut: {
                    size: '60',
                },
            },
        },
    },
};

const PieGraph = ({ data }) => {
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        let labels = data.map(d => d.desc);
        let series = data.map(d => d.info[0]);

        setSettings({
            ...graphSettings,
            series,
            options: { ...graphSettings.options, labels },
        });
    }, [data]);

    return (
        settings && (
            <Chart
                options={settings.options}
                series={settings.series}
                type='donut'
                width='90%'
            />
        )
    );
};

export default PieGraph;
