export const emailBodyFormRules = [
    {
        name: 'body_tekst',
        label: 'Tekstualni sadržaj email poruke za slanje uplatnice',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
        },
        defaultValue: 'body_tekst',
        multiline: true,
        rows: 10,
    },
];
