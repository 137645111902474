import React from 'react';
import { AppBar, Badge, Button, Divider, Tab, Tabs } from '@mui/material';
import {
    useDashboardTabsStore,
    useMembersStore,
    useOrganizationStore,
    useTableStore,
} from '../../Store/Store';
import { Box } from '@mui/system';
import { a11yProps, TabPanel } from '../../TabPanel/TabPanel';
import OrganizationDetails from './OrganizationDetails';
import { useRouteMatch, Link } from 'react-router-dom';
import RankAccordion from './Ranks/RankAccordion';
import OrganizationLogo from './OrganizationLogo/OrganizationLogo';
import useFetch from '../../../hooks/useFetch';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { getLogo } from '../../../services/organizations/logo';

const UsersOrganizations = ({ handleGetUserOrg }) => {
    const { url } = useRouteMatch();

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch();

    const value = useDashboardTabsStore(state => state.value);
    const setValue = useDashboardTabsStore(state => state.setValue);

    const userOrganizations = useOrganizationStore(state => state.userOrganizations);
    const setLogo = useOrganizationStore(state => state.setLogo);
    const setCurrentOrganization = useOrganizationStore(
        state => state.setCurrentOrganization
    );

    const setOpenFilter = useTableStore(state => state.setOpenFilter);

    const members = useMembersStore(state => state.members);
    const membersCopy = useMembersStore(state => state.membersCopy);
    const allMembersFunction = useMembersStore(state => state.allMembersFunction);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setOpenFilter(false);
    };

    const handleChangeOrg = id => {
        const filteredOrg = userOrganizations.filter(org => org.id === id);
        setCurrentOrganization(filteredOrg[0]);
        doFetch([{ func: getLogo, info: [access, id] }, { func: data => setLogo(data) }]);
    };

    const buttonsLink = [
        {
            linkTo: '/organization/',
            btn: 'Članovi',
            btnProps: { onClick: () => setOpenFilter(false) },
            badgeProps: { badgeContent: membersCopy?.length, showZero: true },
        },
        {
            linkTo: '/members-function/',
            btn: 'Članovi sa funkcijama',
            btnProps: { disabled: members.length === 0 },
            badgeProps: { badgeContent: allMembersFunction?.length, showZero: true },
        },
        { linkTo: '/membership-fee/', btn: 'Članarina' },
        { linkTo: '/roles/', btn: 'Ovlaštenja' },
        { linkTo: '/assembly/', btn: 'Skupština' },
        { linkTo: '/shared-folder/', btn: 'Zajedničke datoteke' },
    ];

    return (
        userOrganizations.length !== 0 && (
            <Box sx={{ bgcolor: 'background.paper' }}>
                <AppBar position='static' color='default'>
                    <Tabs
                        value={userOrganizations.length > value ? value : 0}
                        onChange={handleChange}
                        variant='scrollable'
                        scrollButtons='auto'
                        aria-label='scrollable auto tabs example'
                    >
                        {userOrganizations.map((el, i) => (
                            <Tab
                                component={Link}
                                to={`${url}/organization/${el.id}`}
                                onClick={() => handleChangeOrg(el.id)}
                                label={el.skraceni}
                                {...a11yProps(i)}
                                key={i}
                            />
                        ))}
                    </Tabs>
                </AppBar>
                {Array.isArray(userOrganizations) &&
                    userOrganizations.map((el, i) => (
                        <TabPanel value={value} index={i} key={el.id}>
                            <OrganizationLogo
                                handleGetUserOrg={handleGetUserOrg}
                                organization={el}
                            />
                            <Divider sx={{ mb: 3, mx: -3 }} />
                            <OrganizationDetails data={el} />
                            {buttonsLink.map(link => {
                                const { linkTo, btn, btnProps, badgeProps } = link;

                                return (
                                    <Box key={btn} sx={{ my: 2 }}>
                                        <Badge
                                            color='primary'
                                            style={{ width: '100%' }}
                                            {...badgeProps}
                                        >
                                            <Button
                                                variant='outlined'
                                                component={Link}
                                                to={`${url}${linkTo}${el.id}`}
                                                fullWidth
                                                {...btnProps}
                                            >
                                                {btn}
                                            </Button>
                                        </Badge>
                                    </Box>
                                );
                            })}
                            <RankAccordion />
                        </TabPanel>
                    ))}
            </Box>
        )
    );
};

export default UsersOrganizations;
