import React, { useContext } from 'react';
import { Paper, Typography } from '@mui/material';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import capitalizeFirstLetter from '../../../../helpers/capitalizeFirstLetter';
import formatDate from '../../../../helpers/formatDate';
import { useMembersStore, useOrganizationStore } from '../../../Store/Store';
import { membersRules } from './addMembersRules';
import { useHistory, useRouteMatch } from 'react-router-dom';
import StepsForm from '../../../FormComp/StepsForm';
import AddMemberFinalStep from './AddMemberFinalStep';
import { createMembers, getMembers } from '../../../../services/members/members';
import { FeedbackDispatch } from '../../../Store/Feedback/FeedbackContext';
import { handleFetchArr } from './handleFetchArr';
import MemberFiles from './MemberFiles';

const AddMembersForm = () => {
    const history = useHistory();
    const { url } = useRouteMatch();

    const dispatch = useContext(FeedbackDispatch);

    const showMessage = ({ msg, type }) => {
        dispatch({
            type: 'setMessage',
            message: { open: true, type, msg },
        });
    };

    const [{ access }] = useLocalStorage('tokens');

    const { naziv, id } = useOrganizationStore(state => state.currentOrganization);
    const ranks = useOrganizationStore(state => state.ranks);

    const members = useMembersStore(state => state.members);
    const setMembers = useMembersStore(state => state.setMembers);
    const setMembersCopy = useMembersStore(state => state.setMembersCopy);

    const handleCreateMembers = data => {
        const { oib, tel_broj, mobitel, spol, napomena, grupna_obavijest } = data;

        dispatch({ type: 'startLoading' });
        createMembers(access, {
            ime: capitalizeFirstLetter(data.ime),
            prezime: capitalizeFirstLetter(data.prezime),
            oib,
            broj_putovnice: data.broj_putovnice,
            datum_rodenja: formatDate(data.datum_rodenja),
            spol,
            napomena: [{ napomena }],
            grupna_obavijest: grupna_obavijest !== '' && grupna_obavijest,
            contact: [
                {
                    tel_broj,
                    mobitel,
                    email: data.email ? data.email : '',
                },
            ],
            address: [
                {
                    state: capitalizeFirstLetter(data.state),
                    city: capitalizeFirstLetter(data.city),
                    street_name: capitalizeFirstLetter(data.street_name),
                    street_numb: data.street_numb,
                    postal_number: data.postal_number,
                },
            ],
            udruga: +id,
        })
            .then(res => Promise.all([res.json(), res]))
            .then(([json, res]) => {
                if (!res.ok) {
                    return Promise.reject(new Error(json, { cause: 'member' }));
                }
                return json;
            })
            .then(memberData => {
                const fetchArr = handleFetchArr(memberData, data, access);

                return fetchArr.reduce((promiseChain, currentFunc) => {
                    return promiseChain.then(() => {
                        const { func, info, error } = currentFunc;

                        return func(...info).then(res => {
                            if (!res.ok) {
                                return Promise.reject(error);
                            }
                            return Promise.resolve();
                        });
                    });
                }, Promise.resolve());
            })
            .then(() => getMembers(access, naziv))
            .then(res => {
                if (!res.ok) {
                    return Promise.reject(
                        new Error('Došlo je do greške prilikom dohvaćanja članova', {
                            cause: 'members',
                        })
                    );
                }
                return res.json();
            })
            .then(data => {
                setMembersCopy(data);
                return setMembers(data);
            })
            .then(() => {
                showMessage({ msg: 'Član je uspješno dodan', type: 'info' });
                history.push(url.slice(0, -11));
            })
            .catch(err => {
                let msg = err.message;

                if (typeof err.message !== 'string') {
                    msg = 'Došlo je do greške prilikom dodavanja člana';
                }

                if (err.cause !== 'member') {
                    return getMembers(access, naziv)
                        .then(res => {
                            if (!res.ok) {
                                return Promise.reject(
                                    new Error(
                                        'Došlo je do greške prilikom dohvaćanja članova',
                                        {
                                            cause: 'members',
                                        }
                                    )
                                );
                            }
                            return res.json();
                        })
                        .then(data => {
                            showMessage({ msg, type: 'error' });
                            setMembersCopy(data);
                            setMembers(data);
                        })
                        .catch(err => {
                            showMessage({ msg: `${msg}. ${err.message}`, type: 'error' });
                        })
                        .finally(() => history.push(url.slice(0, -11)));
                } else {
                    showMessage({ msg, type: 'error' });
                }
            })
            .finally(() => dispatch({ type: 'stopLoading' }));
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '1rem',
                flexDirection: 'column',
            }}
        >
            <Typography mb={4} variant='h4' align='center' className='form__title'>
                Unos člana
            </Typography>
            <Paper sx={{ width: '40rem', mb: 3 }}>
                <StepsForm
                    updateInputs={[
                        ...membersRules,
                        {
                            step: 'Ostalo',
                            name: 'cinovi',
                            label: 'Razina osposobljenosti',
                            input: 'multiSelect',
                            data: {
                                data: ranks,
                                value: 'id',
                                label: 'naziv_cina',
                            },
                            helperText:
                                ranks.length === 0
                                    ? 'Udruga nema definirane razine osposobljenosti'
                                    : '',
                            defaultValue: 'cinovi',
                        },
                        {
                            step: 'Ostalo',
                            name: 'cinovi_pocetak',
                            label: 'Početak',
                            input: 'date',
                            defaultValue: 'cinovi_pocetak',
                            disabled: ranks.length === 0,
                        },
                        {
                            step: 'Ostalo',
                            name: 'cinovi_kraj',
                            label: 'Kraj',
                            input: 'date',
                            defaultValue: 'cinovi_kraj',
                            disabled: ranks.length === 0,
                        },
                        {
                            step: 'Ostalo',
                            name: 'napomena',
                            label: 'Napomena',
                            rules: {
                                maxLength: {
                                    value: 250,
                                },
                                minLength: {
                                    value: 1,
                                },
                            },
                            defaultValue: 'napomena',
                            multiline: true,
                        },
                    ]}
                    styleContainer='updateForm__container updateForm__container-2c'
                    onSubmit={handleCreateMembers}
                    hideBackLink={members?.length === 0}
                >
                    <AddMemberFinalStep />
                    <MemberFiles />
                </StepsForm>
            </Paper>
        </div>
    );
};

export default AddMembersForm;
