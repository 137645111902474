import React, { useState } from 'react';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import UploadFile from '@mui/icons-material/UploadFile';
import DeleteForever from '@mui/icons-material/DeleteForever';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useMembersStore, useShowModalOptions } from '../../../../Store/Store';
import TableComp from '../../../../TableComp/TableComp';
import ConfirmDeleteModal from '../../../ConfirmDeleteModal';
import { Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import {
    uploadMemberFile,
    deleteMemberFile,
} from '../../../../../services/members/files';

const EditMemberFIles = () => {
    const { member_id } = useParams();

    const [{ access }] = useLocalStorage('tokens');
    const [selectedFile, setSelectedFile] = useState([]);
    const { doFetch } = useFetch({ loading: true });

    const setShowModal = useShowModalOptions(state => state.setShowModal);
    const memberFiles = useMembersStore(state => state.memberFiles);
    const setMemberFiles = useMembersStore(state => state.setMemberFiles);

    const handleChange = ({ target }) => setSelectedFile([...target.files]);
    const handleClose = () => setShowModal({ show: false, Comp: '' });

    const handleDelete = () => {
        doFetch([
            { func: deleteMemberFile, info: [access, memberFiles.id] },
            {
                func: () => {
                    setMemberFiles([]);
                    handleClose();
                },
            },
        ]);
    };

    const handleUploadFile = () => {
        doFetch([
            {
                func: uploadMemberFile,
                info: [access, { clan: +member_id, pdf: selectedFile[0] }],
            },
            {
                func: data => {
                    setMemberFiles(data);
                    setSelectedFile([]);
                },
            },
        ]);
    };

    const btnsArr = [
        {
            name: 'delete',
            onClick: () =>
                setShowModal({
                    show: true,
                    Comp: ConfirmDeleteModal,
                    dialogSize: 'xs',
                    info: { handleDelete },
                }),
            icon: <DeleteForever />,
        },
        {
            name: 'show',
            onClick: ({ url }) => window.open(url, '_blank'),
            icon: <FileDownloadIcon />,
        },
    ];

    const btnsArrSelected = [
        {
            name: 'obriši',
            onClick: file =>
                setSelectedFile(prevFiles =>
                    prevFiles.filter(
                        ({ name, size }) => name !== file.name && size !== file.size
                    )
                ),
            icon: <DeleteForever />,
        },
    ];

    return (
        <div style={{ margin: '0 4rem' }}>
            {Array.isArray(memberFiles) && memberFiles.length !== 0 && (
                <TableComp
                    head={[
                        { id: 'name', label: 'Ime dokumenta', type: 'string' },
                        { id: 'delete', label: 'Obriši', btn: true },
                        { id: 'show', label: 'Skinuti', btn: true },
                    ]}
                    body={[memberFiles]}
                    btns
                    btnsArr={btnsArr}
                />
            )}
            <div style={{ margin: '2rem', display: 'flex', justifyContent: 'center' }}>
                <Button
                    disabled={
                        (Array.isArray(memberFiles) && memberFiles.length !== 0) ||
                        selectedFile.length !== 0
                    }
                    variant='contained'
                    component='label'
                >
                    <Typography variant='body2' mr={1}>
                        Dodaj pristupnicu
                    </Typography>
                    <UploadFile />
                    <input
                        accept='.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        onChange={handleChange}
                        type='file'
                        hidden
                    />
                </Button>
            </div>
            {selectedFile.length !== 0 && (
                <>
                    <TableComp
                        head={[
                            { id: 'name', label: 'Ime datoteke', type: 'string' },
                            { id: 'obriši', label: 'Obriši', btn: true },
                        ]}
                        body={selectedFile}
                        btns
                        btnsArr={btnsArrSelected}
                    />
                    <div
                        style={{
                            marginTop: '2rem',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            endIcon={<DownloadDoneIcon />}
                            variant='outlined'
                            onClick={() => handleUploadFile()}
                        >
                            Potvrdi
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default EditMemberFIles;
