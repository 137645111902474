import React from 'react';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { useAdminStore, useShowModalOptions } from '../../../Store/Store';
import { CircularProgress, Paper, Stack, Typography } from '@mui/material';
import TableComp from '../../../TableComp/TableComp';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useFetch from '../../../../hooks/useFetch';
import {
    activateOrganization,
    getInactiveOrganization,
} from '../../../../services/admin';
import ConfirmModal from './ConfirmModal';

const inactiveOrgTableInfo = [
    { id: 'naziv', label: 'Naziv', type: 'string' },
    { id: 'skraceni', label: 'Skraćeni naziv', type: 'string' },
    { id: 'reg_broj', label: 'Registracijski broj', type: 'string' },
    { id: 'oib', label: 'OIB', type: 'string' },
    { id: 'iban', label: 'IBAN', type: 'string' },
    { id: 'ljecnicki_pregled', label: 'Liječnićki pregled', type: 'boolean' },
    { id: 'sjediste', label: 'Naziv ulice', type: 'object', key: 'street_name' },
    { id: '', label: 'Aktiviraj udrugu', btn: true },
    { id: 'refuse', label: 'Odbij aktivaciju udruge', btn: true },
];

const InactiveOrganizationsTable = ({
    handleGetInactiveOrg,
    handleGetUserOrg,
    isLoading,
}) => {
    const [{ access }] = useLocalStorage('tokens');

    const inactiveOrganization = useAdminStore(state => state.inactiveOrganization);
    const setInactiveOrganization = useAdminStore(state => state.setInactiveOrganization);

    const setShowModal = useShowModalOptions(state => state.setShowModal);
    const handleClose = () => {
        setShowModal({ show: false, Comp: '' });
    };

    const { doFetch } = useFetch();

    const handleActivation = id => {
        doFetch([
            { func: activateOrganization, info: [access, id, 'true'] },
            { func: () => handleGetInactiveOrg() },
            { func: () => handleGetUserOrg(() => handleClose()) },
        ]);
    };

    const handleRefuseActivation = id => {
        doFetch([
            { func: activateOrganization, info: [access, id, 'false'] },
            { func: getInactiveOrganization, info: [access] },
            {
                func: data => {
                    setInactiveOrganization(data);
                    handleClose();
                },
            },
        ]);
    };

    const btnsArr = [
        {
            name: '',
            onClick: org =>
                setShowModal({
                    show: true,
                    Comp: ConfirmModal,
                    dialogSize: 'xs',
                    info: {
                        handleConfirm: handleActivation,
                        submitParams: [org.id],
                        title: `Jeste li sigurni da želite prihvatiti zahtjev za aktivaciju udruge ${org.naziv} ?`,
                    },
                }),
            icon: <CheckIcon />,
        },
        {
            name: 'refuse',
            onClick: org =>
                setShowModal({
                    show: true,
                    Comp: ConfirmModal,
                    dialogSize: 'xs',
                    info: {
                        handleConfirm: handleRefuseActivation,
                        submitParams: [org.id],
                        title: `Jeste li sigurni da želite odbiti zahtjev za aktivaciju udruge ${org.naziv} ?`,
                    },
                }),
            icon: <CloseIcon color='error' />,
        },
    ];

    if (isLoading) {
        return (
            <Stack direction='row' justifyContent='center' my={6}>
                <CircularProgress size={60} />
            </Stack>
        );
    }

    return inactiveOrganization.length !== 0 ? (
        <Paper sx={{ margin: '1rem 3vw' }}>
            <TableComp
                head={inactiveOrgTableInfo}
                body={inactiveOrganization}
                btnsArr={btnsArr}
                btns
            />
        </Paper>
    ) : (
        <Typography variant='h5' align='center'>
            Nema neaktivnih udruga
        </Typography>
    );
};

export default InactiveOrganizationsTable;
