import { BASE_URL } from '../baseUrl';

export const editAddressMember = (token, id, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_MEMBER_ADDRESS}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};

export const editContactMember = (token, id, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_MEMBER_CONTACT}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};

export const editMember = async (token, id, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_MEMBER}${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};
