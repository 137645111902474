import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../../helpers/editHelpers';
import useFetch from '../../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../../hooks/useLocalStorage';
import {
    addSpecificPayment,
    getPayment,
    removeSpecificPayment,
} from '../../../../../../services/members/payment';
import UpdateForm from '../../../../../FormComp/UpdateForm';
import { useMembersStore } from '../../../../../Store/Store';

const EditFormSpecific = ({ currentRow }) => {
    const { member_id } = useParams();

    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const { doFetch } = useFetch();
    const [{ access }] = useLocalStorage('tokens');

    const setMemberPayment = useMembersStore(state => state.setMemberPayment);

    const handleEditPayment = data => {
        let { iznos_clanarine } = data;
        let fetchArr;

        if (typeof iznos_clanarine === 'string' && iznos_clanarine.length !== 0) {
            fetchArr = {
                func: addSpecificPayment,
                info: [
                    access,
                    {
                        clan: +member_id,
                        iznos_clanarine: +iznos_clanarine,
                        razdoblje_uplate: +currentRow.razdoblje_uplate,
                    },
                ],
            };
        } else if (typeof iznos_clanarine === 'boolean' && !iznos_clanarine) {
            fetchArr = {
                func: removeSpecificPayment,
                info: [
                    access,
                    { clan: +member_id, razdoblje_uplate: +currentRow.razdoblje_uplate },
                ],
            };
        }

        doFetch([
            fetchArr,
            { func: getPayment, info: [access, member_id] },
            { func: data => setMemberPayment(data) },
        ]);
    };

    useEffect(() => {
        if (currentRow) {
            let field = currentRow?.proizvoljno_definirana
                ? {
                      name: 'iznos_clanarine',
                      label: 'Članarina proizvoljno definirana',
                      input: 'checkbox',
                      defaultValue: 'proizvoljno_definirana',
                  }
                : {
                      name: 'iznos_clanarine',
                      label: 'Proizvoljan iznos članarine',
                      type: 'number',
                      rules: {
                          required: 'Ovo polje je obavezno',
                          maxLength: {
                              value: 2147483647,
                          },
                          minLength: {
                              value: 0,
                          },
                      },
                      defaultValue: 'proizvoljno_definirana',
                  };

            setEditFields(
                addDefaultValues([field], {
                    proizvoljno_definirana: currentRow?.proizvoljno_definirana
                        ? currentRow?.proizvoljno_definirana
                        : '',
                })
            );
        }
    }, [currentRow]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        editFields.length !== 0 && (
            <div style={{ flex: '0 0 36%' }}>
                <UpdateForm
                    updateInputs={editFields}
                    onSubmit={handleEditPayment}
                    defaultValues={defaultValues}
                    updateDisable={true}
                    btnMessage='Promijeni'
                    styleContainer='updateForm__container updateForm__container-1c'
                />
            </div>
        )
    );
};

export default EditFormSpecific;
