import React, { useCallback, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import TableCollapse from '../../../../TableComp/TableCollapse';
import TableComp from '../../../../TableComp/TableComp';
import AddMemberRankForm from './AddForm/AddMemberRankForm';
import {
    useMembersStore,
    useOrganizationStore,
    useShowModalOptions,
} from '../../../../Store/Store';
import EditMemberRankForm from './EditForm/EditMemberRankForm';
import { getMemberRanks, deleteRankMember } from '../../../../../services/members/ranks';
import RankDialog from '../../../Organizations/Ranks/RankDialog';

const MemberRank = () => {
    const [expanded, setExpanded] = useState(false);
    const { member_id } = useParams();

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const { doFetch: fetchMemberRanks } = useFetch();
    const { doFetch: fetchDeleteMemberRank } = useFetch({
        message: { showErrMsg: true },
    });

    const [{ access }] = useLocalStorage('tokens');

    const ranks = useOrganizationStore(state => state.ranks);
    const deleteMemberRank = useOrganizationStore(state => state.deleteMemberRank);

    const memberRanks = useMembersStore(state => state.memberRanks);
    const setMemberRanks = useMembersStore(state => state.setMemberRanks);

    const setShowModal = useShowModalOptions(state => state.setShowModal);

    const handleGetUserRanks = useCallback(
        cb => {
            fetchMemberRanks([
                { func: getMemberRanks, info: [access, member_id] },
                {
                    func: data => {
                        setMemberRanks(data);
                    },
                },
                { func: cb },
            ]);
        },
        [access, member_id, fetchMemberRanks, setMemberRanks]
    );

    const handleDeleteRank = info => {
        //console.log(info.cinovi.naziv_cina);

        fetchDeleteMemberRank([
            { func: deleteRankMember, info: [access, info.id] },
            { func: getMemberRanks, info: [access, member_id] },
            {
                func: data => {
                    setMemberRanks(data);
                    deleteMemberRank(info.cinovi.naziv_cina, member_id, data);
                },
            },
        ]);
    };

    const btnsArr = [
        {
            name: 'obrisi',
            icon: <DeleteForeverIcon color='error' />,
            onClick: handleDeleteRank,
        },
    ];

    return (
        <>
            {Array.isArray(ranks) && ranks.length !== 0 ? (
                Array.isArray(memberRanks) && memberRanks.length !== 0 ? (
                    <Accordion
                        expanded={expanded === 'add'}
                        onChange={handleChange('add')}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1bh-content'
                            id='panel1bh-header'
                        >
                            <Typography variant='body1'>
                                Dodaj razinu osposobljenosti
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AddMemberRankForm
                                handleGetUserRanks={handleGetUserRanks}
                                setExpanded={setExpanded}
                            />
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <AddMemberRankForm handleGetUserRanks={handleGetUserRanks} />
                )
            ) : (
                <>
                    <Typography mt={3} mb={2} variant='h6' align='center'>
                        Potrebno je udruzi dodati moguće razine osposobljenosti
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant='outlined'
                            onClick={() =>
                                setShowModal({
                                    show: true,
                                    Comp: RankDialog,
                                    dialogSize: 'sm',
                                })
                            }
                        >
                            Dodaj razine osposobljenosti
                        </Button>
                    </div>
                </>
            )}
            {Array.isArray(memberRanks) && memberRanks.length !== 0 && (
                <div style={{ marginTop: '2rem' }}>
                    <TableComp
                        maxTableHeight='40vh'
                        head={[
                            {
                                id: 'cinovi',
                                key: 'naziv_cina',
                                label: 'Razina osposobljensti',
                                type: 'object',
                            },
                            { id: 'cinovi_pocetak', label: 'Početak', type: 'date' },
                            { id: 'cinovi_kraj', label: 'Kraj', type: 'date' },
                            { id: 'obrisi', btn: true },
                            { id: '', btn: true, collapse: true },
                        ]}
                        body={memberRanks}
                        btnsArr={btnsArr}
                        btns
                    >
                        <TableCollapse>
                            <EditMemberRankForm />
                        </TableCollapse>
                    </TableComp>
                </div>
            )}
        </>
    );
};

export default MemberRank;
