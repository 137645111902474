import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Typography, RadioGroup, Radio, FormControlLabel, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../helpers/editHelpers';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import UpdateForm from '../../../../FormComp/UpdateForm';
import {
    addEmailBody,
    editEmailBody,
    getEmailBody,
} from '../../../../../services/organizations/emailBody';
import { FeedbackDispatch } from '../../../../Store/Feedback/FeedbackContext';
import { emailBodyFormRules } from './emailBodyFormRules';
import { useOrganizationStore } from '../../../../Store/Store';

const EmailBody = () => {
    const { id } = useParams();
    const [{ access }] = useLocalStorage('tokens');

    const [editForm, setEditForm] = useState(false);
    const [text, setText] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());
    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const dispatch = useContext(FeedbackDispatch);

    const handleShowMsg = msg => {
        dispatch({
            type: 'setMessage',
            message: {
                open: true,
                type: 'info',
                msg,
            },
        });
    };

    const {
        response: { emailBody },
        doFetch,
    } = useFetch({ message: { showMsg: true } });
    const { doFetch: addEditEmailBody } = useFetch();

    const membershipFee = useOrganizationStore(state => state.membershipFee);
    const currentOrganization = useOrganizationStore(state => state.currentOrganization);

    const handleChange = event => setYear(event.target.value);

    const handleTransfer = ({ body_tekst }) => {
        let promise;
        let message = '';

        let hasDefault = editFields.filter(field => field.defaultValue.length !== 0);

        promise = addEmailBody;
        message = 'Tekst za email poruku je uspješno dodan.';

        if (hasDefault.length !== 0) {
            promise = editEmailBody;
            message = 'Tekst za email poruku je uspješno promijenjen.';
        }

        addEditEmailBody([
            { func: promise, info: [access, { udruga: +id, godina: year, body_tekst }] },
            { func: handleGetEmailBody },
            {
                func: () => {
                    handleShowMsg(message);
                    setEditForm(false);
                },
            },
        ]);
    };

    const handleGetEmailBody = useCallback(() => {
        doFetch([{ func: getEmailBody, info: [access, id], id: 'emailBody' }]);
    }, [access, id, doFetch]);

    useEffect(() => handleGetEmailBody(), [handleGetEmailBody]);

    useEffect(() => {
        if (Array.isArray(emailBody)) {
            let text = emailBody.filter(t => t.godina === +year);

            if (text[0]?.body_tekst.length !== 0 && text.length !== 0) {
                setText(text[0].body_tekst);
                setEditFields(addDefaultValues(emailBodyFormRules, text[0]));
            } else {
                setText('');
                setEditFields(addDefaultValues(emailBodyFormRules, { body_tekst: '' }));
            }
        }
    }, [emailBody, year]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography align='center' variant='body1'>
                    Za godinu:
                </Typography>
                <RadioGroup
                    row
                    aria-label='gender'
                    name='controlled-radio-buttons-group'
                    value={year}
                    onChange={handleChange}
                >
                    {[...membershipFee].reverse().map(fee => {
                        return (
                            <FormControlLabel
                                key={fee.id}
                                labelPlacement='start'
                                value={fee.godina}
                                control={<Radio size='medium' />}
                                label={fee.godina}
                            />
                        );
                    })}
                </RadioGroup>
            </div>
            {!editForm && text ? (
                <div style={{ margin: '2rem 1rem 1rem 1rem' }}>
                    <Typography mb={2} style={{ display: 'block' }} variant='p'>
                        {text}
                    </Typography>
                    <Button
                        disabled={currentOrganization?.block}
                        onClick={() => setEditForm(!editForm)}
                        variant='contained'
                    >
                        Promijeni
                    </Button>
                </div>
            ) : (
                editFields.length !== 0 && (
                    <UpdateForm
                        updateDefaultValues={true}
                        updateInputs={editFields}
                        onSubmit={handleTransfer}
                        updateDisable={true}
                        defaultValues={defaultValues}
                        btnMessage={text ? 'Promijeni' : 'Dodaj'}
                    >
                        {text ? (
                            <Button
                                onClick={() => setEditForm(!editForm)}
                                variant='outlined'
                                color='error'
                            >
                                Natrag
                            </Button>
                        ) : null}
                    </UpdateForm>
                )
            )}
        </div>
    );
};

export default EmailBody;
