import React, { useMemo } from 'react';
import { Divider, Paper, Typography } from '@mui/material';
import { monthList } from '../../../../helpers/monthList';
import { useAdminStore } from '../../../Store/Store';
import StatisticItem from './StatisticItem';
import { userActivityTableHead } from './userActivityTableHead';
import OrganizationMembers from './OrganizationMembers';

const StatisticList = () => {
    const statistic = useAdminStore(state => state.statistic);

    const basicOrganizationInfo = useMemo(
        () => ({
            title: 'Broj udruga',
            options: { graph: { type: 'pie' }, additionalList: true },
            data: [
                {
                    desc: 'Broj blokiranih udruga',
                    info: [statistic?.statistic?.blocked_association_num],
                    text: true,
                },
                {
                    desc: 'Broj ne blokiranih udruga',
                    info: [
                        statistic?.statistic?.association_num -
                            statistic?.statistic?.blocked_association_num,
                    ],
                    text: true,
                },
            ],
            get graphData() {
                return [...this.data];
            },
        }),
        [statistic?.statistic]
    );

    const membersInfo = useMemo(
        () => ({
            title: 'Broj članova',
            options: { graph: { type: 'pie' }, additionalList: true },
            data: [
                {
                    desc: 'Broj aktivnih članova',
                    info: [statistic?.statistic.acive_members],
                    text: true,
                },
                {
                    desc: 'Broj ne aktivnih članova',
                    info: [statistic?.statistic.ancive_members],
                    text: true,
                },
                {
                    desc: 'Novi članovi u tekućem mjesecu',
                    info: [statistic?.statistic.new_members_at_current_month],
                    text: true,
                },
            ],
            get graphData() {
                let dataCopy = [...this.data];
                dataCopy.pop();
                return [...dataCopy];
            },
        }),
        [statistic?.statistic]
    );

    const membersActivity = useMemo(
        () => ({
            title: 'Aktivnost korisnika',
            options: { table: true },
            tableData: {
                head: userActivityTableHead,
                body: statistic?.membersActivity,
                pagination: true,
            },
        }),
        [statistic?.membersActivity]
    );

    const newMembersInfo = useMemo(
        () => ({
            title: 'Novi članovi',
            options: { graph: { type: 'column', style: { height: '20rem' } } },
            graphData: {
                xaxisData: monthList,
                yaxisData: [
                    {
                        name: 'Broj članova',
                        data: statistic?.members.map(d => d[Object.keys(d)[0]]),
                    },
                ],
            },
        }),
        [statistic?.members]
    );

    const assemblyInfo = useMemo(
        () => ({
            title: 'Skupštine',
            options: { graph: { type: 'column', style: { height: '20rem' } } },
            graphData: {
                xaxisData: monthList,
                yaxisData: [
                    {
                        name: 'Broj skupština',

                        data: statistic?.meetings.map(d => +d[Object.keys(d)[0]]),
                    },
                ],
            },
        }),
        [statistic?.meetings]
    );

    return (
        <Paper sx={{ m: '1rem auto', width: '50rem' }}>
            <Typography align='center' variant='h4' py={3} color='primary'>
                Statistika
            </Typography>
            <Divider />
            <StatisticItem info={basicOrganizationInfo} />
            <OrganizationMembers />
            <Divider />
            <StatisticItem info={membersInfo} />
            <StatisticItem info={membersActivity} />
            <StatisticItem info={newMembersInfo} />
            <StatisticItem info={assemblyInfo} />
        </Paper>
    );
};

export default StatisticList;
