import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const graphSettings = {
    options: {
        chart: {
            toolbar: { show: false },
        },
        yaxis: {
            labels: {
                formatter: val => val.toFixed(0),
            },
        },
    },
};

const ColumnChart = ({ data }) => {
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        const { yaxisData, xaxisData } = data;
        if (!yaxisData || !xaxisData) return null;

        setSettings({
            ...graphSettings,
            options: {
                ...graphSettings.options,
                xaxis: { ...graphSettings.options.xaxis, categories: xaxisData },
            },
            series: yaxisData,
        });
    }, [data]);

    return (
        settings && (
            <Chart
                options={settings.options}
                series={settings.series}
                type='bar'
                width='100%'
                height='100%'
            />
        )
    );
};

export default ColumnChart;
