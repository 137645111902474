import TransferText from './TransferText';

export const transferRules = [
    {
        name: 'uplatnica',
        label: 'Uplatnica',
        input: 'select',
        data: {
            data: [
                { label: 'Skinuti u PDF formatu', value: 2 },
                { label: 'Poslati preko email-a', value: 1 },
            ],
            value: 'value',
            label: 'label',
        },
        rules: { required: 'Ovo polje je obavezno' },
        step: 'Osnovne informacije',
        defaultValue: 'uplatnica',
    },
    {
        step: 'Osnovne informacije',
        name: 'dugovanja',
        label: 'Postojeća dugovanja',
        input: 'checkbox',
        defaultValue: 'dugovanja',
    },
    {
        step: 'Tekst za uplatnicu/email',
        Comp: TransferText,
    },
];

export const emailFormRule = {
    name: 'email_tekst',
    label: 'Tekstualni sadržaj email poruke za slanje uplatnice',
    rules: {
        required: 'Ovo polje je obavezno',
        maxLength: {
            value: 2147483647,
        },
        minLength: {
            value: 0,
        },
    },
    defaultValue: 'email_tekst',
    multiline: true,
    rows: 6,
};

export const pdfFormRule = {
    name: 'pdf_tekst',
    label: 'Tekstualni sadržaj obrasca za uplatu članarine',
    rules: {
        required: 'Ovo polje je obavezno',
        maxLength: {
            value: 2147483647,
        },
        minLength: {
            value: 0,
        },
    },
    defaultValue: 'pdf_tekst',
    multiline: true,
    rows: 6,
};
