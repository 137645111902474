import { Button, Divider, Typography } from '@mui/material';
import React from 'react';
import formatDateDmy from '../../../../../helpers/formatDateDmy';
import { assemblyStatusType } from '../../../../../helpers/options';
import LinkButton from '../../../../FormComp/LinkButton';
import ListComp from '../../../../ListComp/ListComp';
import TableComp from '../../../../TableComp/TableComp';

const FinalStep = ({ options: { finalData, handlePrevStep, handleSubmit } }) => {
    const { naziv, status, datum_odrzavanja, datum_slijedece, selectedFile } = finalData;

    const assemblyInfo = [
        { desc: 'Naziv', info: [naziv], text: true },
        {
            desc: 'Status',
            info: [assemblyStatusType.filter(el => el.pk === status)[0]?.naziv],
            text: true,
        },
        { desc: 'Datum održavanja', info: [formatDateDmy(datum_odrzavanja)], text: true },
        { desc: 'Datum slijedeće', info: [formatDateDmy(datum_slijedece)], text: true },
    ];

    return (
        <>
            <div>
                <Typography my={2} color='primary' align='center' variant='h6'>
                    Osnovni podatci
                </Typography>
                <Divider />
                <ListComp listItems={assemblyInfo} />
                {selectedFile.length !== 0 && (
                    <>
                        <Typography my={2} color='primary' align='center' variant='h6'>
                            Datoteka
                        </Typography>
                        <TableComp
                            head={[{ id: 'name', label: 'Ime datoteke', type: 'string' }]}
                            body={selectedFile}
                        />
                    </>
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    padding: '1rem',
                }}
            >
                <LinkButton linkFuncGoBack btnMessage='Odustani' />
                <Button onClick={handlePrevStep} color='error' variant='outlined'>
                    Natrag
                </Button>
                <Button onClick={handleSubmit} color='primary' variant='contained'>
                    Dodaj skupštinu
                </Button>
            </div>
        </>
    );
};

export default FinalStep;
