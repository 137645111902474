import { roles } from '../../../../helpers/options';

export const editUsersWithRoleRules = [
    {
        name: 'email',
        label: 'Email',
        rules: {
            maxLength: {
                value: 255,
            },
            minLength: {
                value: 1,
            },
            pattern: {
                value: /^\S+@\S+$/,
                message: 'Pogrešna e-mail adresa',
            },
        },
        defaultValue: 'email',
    },
    {
        name: 'role',
        label: 'Funkcija',
        input: 'select',
        data: {
            data: roles,
            value: 'pk',
            label: 'naziv',
        },
        defaultValue: 'role',
    },
    {
        name: 'pocetak',
        label: 'Početak dužnosti',
        input: 'date',
        defaultValue: 'od',
    },
    {
        name: 'kraj',
        label: 'Kraj dužnosti',
        input: 'date',
        defaultValue: 'do',
    },
];
