import React, { useCallback, useEffect, useMemo } from 'react';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { Button, Divider, Paper, Typography } from '@mui/material';
import {
    Link,
    Route,
    Switch,
    useHistory,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import {
    deleteFunctionMember,
    getAllMemberFunction,
} from '../../../../services/members/function';
import { useMembersStore, useOrganizationStore } from '../../../Store/Store';
import TableComp from '../../../TableComp/TableComp';
import TableCollapse from '../../../TableComp/TableCollapse';
import EditFunction from './EditFunction/EditFunction';
import Add from '@mui/icons-material/Add';
import AddFunction from './AddFunction/AddFunction';

const MembersWithFuncTable = () => {
    const { id } = useParams();
    const { path, url } = useRouteMatch();
    const history = useHistory();

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch({ loading: true });
    const { doFetch: deleteFunction } = useFetch();

    const currentOrganization = useOrganizationStore(state => state.currentOrganization);

    const membersCopy = useMembersStore(state => state.membersCopy);
    const allMembersFunction = useMembersStore(state => state.allMembersFunction);
    const setAllMemberFunction = useMembersStore(state => state.setAllMemberFunction);

    const handleGetAllMemberFunction = useCallback(() => {
        doFetch([
            { func: getAllMemberFunction, info: [access, id] },
            {
                func: data =>
                    data.length !== 0
                        ? setAllMemberFunction(data)
                        : history.push(`${url}/add-function`),
            },
        ]);
    }, [doFetch, access, id, setAllMemberFunction, history, url]);

    const btnsArr = [
        {
            name: 'delete',
            onClick: ({ id }) =>
                deleteFunction([
                    { func: deleteFunctionMember, info: [access, id] },
                    { func: handleGetAllMemberFunction },
                ]),
            icon: <DeleteForever />,
            disabled: currentOrganization?.block,
        },
    ];

    const tableInfo = useMemo(
        () => [
            {
                id: 'clan',
                label: 'Član',
                info: id => {
                    const m = membersCopy.find(m => m.id === id);
                    if (!m?.ime && !m?.prezime) return '';
                    return `${m?.ime} ${m?.prezime}`;
                },
                type: 'function',
            },
            { id: 'funkcija', label: 'Funkcija', type: 'string' },
            { id: 'pocetak_duznosti', label: 'Početak dužnosti', type: 'date' },
            { id: 'kraj_duznosti', label: 'Kraj dužnosti', type: 'date' },
            { id: 'delete', label: '', btn: true },
            ...(!currentOrganization?.block
                ? [{ id: '', btn: true, collapse: true }]
                : []),
        ],
        [currentOrganization?.block, membersCopy]
    );

    useEffect(() => handleGetAllMemberFunction(), [handleGetAllMemberFunction]);

    return (
        <Switch>
            <Route exact path={path}>
                <Paper sx={{ maxWidth: '54rem', margin: '1rem auto' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            padding: '1.6rem',
                        }}
                    >
                        <Typography variant='h5'>Popis članova sa funkcijama</Typography>
                        <Button
                            component={Link}
                            to={`${url}/add-function`}
                            variant='outlined'
                            disabled={currentOrganization?.block}
                        >
                            <Add />
                        </Button>
                    </div>
                    <Divider />
                    {tableInfo && (
                        <TableComp
                            head={tableInfo}
                            body={allMembersFunction}
                            pagination
                            btnsArr={btnsArr}
                            btns
                        >
                            <TableCollapse>
                                <EditFunction />
                            </TableCollapse>
                        </TableComp>
                    )}
                </Paper>
            </Route>
            <Route exact path={`${path}/add-function`}>
                <AddFunction />
            </Route>
        </Switch>
    );
};

export default MembersWithFuncTable;
