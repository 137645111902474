import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../helpers/editHelpers';
import formatDate from '../../../../../helpers/formatDate';
import useFetch from '../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import {
    editFunction,
    getAllMemberFunction,
} from '../../../../../services/members/function';
import UpdateForm from '../../../../FormComp/UpdateForm';
import { useMembersStore } from '../../../../Store/Store';
import { editFunctionAllMembersRules } from './editFunctionAllMembersRules';

const EditFunction = ({ currentRow }) => {
    const { id } = useParams();
    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch();

    const setAllMemberFunction = useMembersStore(state => state.setAllMemberFunction);

    const handleEditFunction = data => {
        doFetch([
            {
                func: editFunction,
                info: [
                    access,
                    currentRow.id,
                    {
                        funkcija: data.funkcija,
                        pocetak_duznosti: formatDate(data.pocetak_duznosti),
                        kraj_duznosti: formatDate(data.kraj_duznosti),
                    },
                ],
            },
            { func: getAllMemberFunction, info: [access, id] },
            { func: data => setAllMemberFunction(data) },
        ]);
    };

    useEffect(() => {
        setEditFields(addDefaultValues(editFunctionAllMembersRules, currentRow));
    }, [currentRow]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        editFields.length !== 0 && (
            <UpdateForm
                updateInputs={editFields}
                onSubmit={handleEditFunction}
                defaultValues={defaultValues}
                updateDisable={true}
                btnMessage='Promijeni'
                styleContainer='updateForm__container updateForm__container-3c'
            />
        )
    );
};

export default EditFunction;
