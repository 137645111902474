import { BASE_URL } from '../baseUrl';

export const getMemberRanks = (token, memberId, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_GET_MEMBER_RANK}${memberId}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const addRankMember = (token, data, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_ADD_MEMBER_RANK}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(data),
        signal,
    });
};

export const editRankMember = (token, data, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_EDIT_MEMBER_RANK}${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PATCH',
        body: JSON.stringify(data),
        signal,
    });
};

export const deleteRankMember = (token, id, signal) => {
    return fetch(`${BASE_URL}${process.env.REACT_APP_DELETE_MEMBER_RANK}${id}/`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
        signal,
    });
};
