import React, { useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { loginRules } from './loginRules';
import { Redirect, useLocation } from 'react-router-dom';
import useToggle from '../../hooks/useToggleState';
import { useUserStore } from '../Store/Store';
import useLocalStorage from '../../hooks/useLocalStorage';
import UpdateForm from '../FormComp/UpdateForm';
import ResetPassForm from '../ResetPassFinal/ResetPassForm/ResetPassForm';
import useFetch from '../../hooks/useFetch';
import { loginUser } from '../../services/user';
import { useRef } from 'react';
import handleDebounce from '../../helpers/handleDebounce';

const LoginForm = ({ showResetPass, containerStyle, handleGetUserData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [openReset, toggleReset] = useToggle(false);

    const location = useLocation();

    const {
        doFetch,
        error: { error },
    } = useFetch({
        message: {
            showErrMsg: true,
            errMsg: 'Došlo je do pogreške pri prijavljivanju. Provjerite unešene podatke.',
        },
    });

    const userData = useUserStore(state => state.userData);

    const [token, setToken] = useLocalStorage('tokens', '');
    const [, setUserEmail] = useLocalStorage('email', '');
    const tokenRef = useRef(token);

    const handleLoginUser = ({ email, password }) => {
        setIsLoading(true);
        const handleFetchLogin = handleDebounce(
            () =>
                doFetch([
                    { func: loginUser, info: [{ email, password }] },
                    {
                        func: data => {
                            if (data?.access) {
                                setToken(data);
                                setUserEmail(email);
                                handleGetUserData(email, data.access);
                            }
                            return null;
                        },
                    },
                ]),
            1000
        );
        handleFetchLogin();
    };

    useEffect(() => {
        if (error) {
            setIsLoading(false);
        }
    }, [error]);

    if (userData?.id) {
        let backupPathname = userData.is_superuser
            ? '/dashboard/admin-organization/inactive'
            : '/dashboard';
        let to = location?.state?.location?.pathname || backupPathname;

        if (tokenRef.current === '0-0') {
            to = backupPathname;
        }

        return <Redirect to={to} />;
    }

    return (
        <div className={containerStyle ? containerStyle : 'form'}>
            <Paper sx={{ width: '24rem' }}>
                <div className='form__titleContainer'>
                    <h3 className='form__title'>{openReset ? '' : 'Prijava'}</h3>
                </div>
                {openReset && (
                    <h4 className='form__titleReset'>
                        Unesite e-mail adresu s kojom<span>{<br />}</span> ste se
                        registrirali
                    </h4>
                )}
                {showResetPass ? (
                    openReset ? (
                        <ResetPassForm toggleReset={toggleReset} />
                    ) : (
                        <UpdateForm updateInputs={loginRules} onSubmit={handleLoginUser}>
                            <Button variant='outlined' onClick={() => toggleReset()}>
                                Zaboravili ste lozinku?
                            </Button>
                            <LoadingButton
                                variant='contained'
                                type='submit'
                                disabled={isLoading}
                                loading={isLoading}
                            >
                                Prijavite se
                            </LoadingButton>
                        </UpdateForm>
                    )
                ) : (
                    <UpdateForm updateInputs={loginRules} onSubmit={handleLoginUser}>
                        <LoadingButton
                            variant='contained'
                            type='submit'
                            disabled={isLoading}
                            loading={isLoading}
                        >
                            Prijavite se
                        </LoadingButton>
                    </UpdateForm>
                )}
            </Paper>
        </div>
    );
};

export default LoginForm;
