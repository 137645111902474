import transformToFormData from '../../helpers/transformToFormData';
import { BASE_URL } from '../baseUrl';

export const getSharedFolders = (token, id, signal) => {
    return fetch(`${BASE_URL}/shared_folder/getlist/${id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
        signal,
    });
};

export const addFile = (token, data, signal) => {
    return fetch(`${BASE_URL}/shared_folder/post`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: transformToFormData(data),
        signal,
    });
};

export const deleteFile = (token, file_id, signal) => {
    return fetch(`${BASE_URL}/shared_folder/details/${file_id}`, {
        credentials: 'include',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
        signal,
    });
};
