import React from 'react';
import UpdateForm from '../../FormComp/UpdateForm';
import { resetPassRules } from './resetPassRules';
import { Button } from '@mui/material';
import useFetch from '../../../hooks/useFetch';
import { resetUserPass } from '../../../services/user';

const ResetPassForm = ({ toggleReset }) => {
    const { doFetch } = useFetch({
        message: {
            showSuccessMsg: true,
            successMsg: 'E-mail je poslan za promjenu šifre.',
        },
    });

    return (
        <UpdateForm
            updateInputs={resetPassRules}
            onSubmit={({ email }) =>
                doFetch([{ func: resetUserPass, info: [{ email }] }])
            }
            btnMessage='Pošalji'
            clearFields
        >
            <Button color='error' variant='outlined' onClick={() => toggleReset()}>
                Poništi
            </Button>
        </UpdateForm>
    );
};

export default ResetPassForm;
