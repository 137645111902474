import React, { useState } from 'react';
import { Button, Divider, Tooltip, Typography } from '@mui/material';
import DeleteForever from '@mui/icons-material/DeleteForever';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TableComp from '../../../TableComp/TableComp';
import LinkButton from '../../../FormComp/LinkButton';

const MemberFiles = ({ options: { handlePrevStep, handleNext, finalData } }) => {
    const [selectedFile, setSelectedFile] = useState(finalData.selectedFile || []);

    const handleChange = ({ target }) => {
        setSelectedFile([...selectedFile, ...target.files]);
    };

    const btnsArr = [
        {
            name: 'obriši',
            onClick: file =>
                setSelectedFile(prevFiles =>
                    prevFiles.filter(
                        ({ name, size }) => name !== file.name && size !== file.size
                    )
                ),
            icon: <DeleteForever />,
        },
    ];

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '1rem',
                    margin: '2rem 0',
                }}
            >
                <Tooltip
                    title={
                        selectedFile.length !== 0
                            ? 'Moguća je jedino jedna pristupnica'
                            : ''
                    }
                >
                    <span>
                        <Button
                            disabled={selectedFile.length !== 0}
                            variant='contained'
                            component='label'
                        >
                            <Typography variant='body2' mr={1}>
                                Dodaj datoteku
                            </Typography>
                            <UploadFileIcon />
                            <input
                                onChange={handleChange}
                                accept='.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                type='file'
                                hidden
                            />
                        </Button>
                    </span>
                </Tooltip>
            </div>
            {selectedFile.length !== 0 && (
                <TableComp
                    head={[
                        { id: 'name', label: 'Ime datoteke', type: 'string' },
                        { id: 'obriši', label: 'Obriši', btn: true },
                    ]}
                    body={selectedFile}
                    btns
                    btnsArr={btnsArr}
                />
            )}
            <Divider />
            <div
                style={{
                    margin: '1rem',
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <LinkButton linkFuncGoBack btnMessage='Odustani' />
                <Button color='error' onClick={handlePrevStep} variant='outlined'>
                    Natrag
                </Button>
                <Button onClick={() => handleNext({ selectedFile })} variant='contained'>
                    Dalje
                </Button>
            </div>
        </>
    );
};

export default MemberFiles;
