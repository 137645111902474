import React, { useState } from 'react';
import { Box, Button, Divider, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import DeleteForever from '@mui/icons-material/DeleteForever';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import {
    addFile,
    getSharedFolders,
} from '../../../../services/organizations/sharedFolder';
import TableComp from '../../../TableComp/TableComp';

export const AddFileField = ({ setOpenAddForm, setSharedFolders }) => {
    const [selectedFile, setSelectedFile] = useState([]);

    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch({
        loading: true,
        message: { showSuccessMsg: true, successMsg: 'Datoteka je uspješno dodana' },
    });

    const handleCloseAddFile = () => setOpenAddForm(false);

    const { id } = useParams();

    const handleChange = ({ target }) => {
        setSelectedFile([...selectedFile, ...target.files]);
    };

    const handleUploadFile = () => {
        doFetch([
            { func: addFile, info: [access, { file: selectedFile, udruga: id }] },
            { func: getSharedFolders, info: [access, id] },
            {
                func: data => {
                    setSharedFolders(data);
                    handleCloseAddFile();
                },
            },
        ]);
    };

    const btnsArr = [
        {
            name: 'obriši',
            onClick: file =>
                setSelectedFile(prevFiles =>
                    prevFiles.filter(
                        ({ name, size }) => name !== file.name && size !== file.size
                    )
                ),
            icon: <DeleteForever />,
        },
    ];

    return (
        <Box p={1}>
            <Divider />
            <Stack justifyContent='center' direction='row' p={2}>
                <Button variant='outlined' component='label'>
                    {/* <Typography variant='body2' mr={1}>
                        Dodaj datoteku
                    </Typography> */}
                    <UploadFileIcon />
                    <input multiple onChange={handleChange} type='file' hidden />
                </Button>
            </Stack>
            {selectedFile.length !== 0 && (
                <TableComp
                    head={[
                        { id: 'name', label: 'Ime datoteke', type: 'string' },
                        { id: 'obriši', label: 'Obriši', btn: true },
                    ]}
                    body={selectedFile}
                    btnsArr={btnsArr}
                    btns
                />
            )}
            <Stack justifyContent='center' direction='row' p={2} gap={4}>
                <Button
                    onClick={handleUploadFile}
                    disabled={selectedFile.length === 0}
                    variant='contained'
                >
                    Dodaj
                </Button>
                <Button color='error' variant='outlined' onClick={handleCloseAddFile}>
                    Zatvori
                </Button>
            </Stack>
        </Box>
    );
};
