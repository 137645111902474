import React from 'react';
import { Toolbar } from '@mui/material';
import HeaderImg from '../images/network.jpg';
import ErrorImg from '../images/404-error.png';
import { Link } from 'react-router-dom';

const Error404 = () => {
    return (
        <div className='error404'>
            <Toolbar
                sx={{
                    height: '10vh',
                    width: '100vw',
                    background: `url(${HeaderImg}) no-repeat center center fixed`,
                    backgroundSize: 'cover',
                    justifyContent: 'space-between',
                }}
            />
            <div className='error404__main'>
                <div>
                    <img height='350px' src={ErrorImg} alt={'Error 404'} />
                </div>
                <div className='error404__main__text'>
                    <h1>Stranica nije pronađena</h1>
                    <h2>Žao nam je, nismo uspjeli pronaći stranicu koju ste tražili.</h2>
                    <h3>
                        Idi na <Link to={'/'}>početnu stranicu</Link>
                    </h3>
                    <h3>
                        Ako smatrate da nešto nedostaje što bi trebalo biti ovdje,{' '}
                        <Link to={'/contact'}>kontaktirajte nas</Link>.
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default Error404;
