import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import React from 'react';

const TableCollapse = ({ children, currentRow }) => {
    if (!children) return null;

    return (
        <Box sx={{ backgroundColor: grey[50] }}>
            {React.Children.map(children, child =>
                React.cloneElement(child, { currentRow })
            )}
        </Box>
    );
};

export default TableCollapse;
