export const approvedOrgTableInfo = [
    { id: 'naziv', label: 'Naziv', type: 'string' },
    { id: 'skraceni', label: 'Skraćeni naziv', type: 'string' },
    { id: 'reg_broj', label: 'Registracijski broj', type: 'string' },
    { id: 'oib', label: 'OIB', type: 'string' },
    { id: 'iban', label: 'IBAN', type: 'string' },
    { id: 'ljecnicki_pregled', label: 'Liječnićki pregled', type: 'boolean' },
    { id: 'sjediste', label: 'Naziv ulice', type: 'object', key: 'street_name' },
    { id: 'block', label: 'Blokiraj udrugu', btn: true },
    { id: 'delete', label: 'Obriši udrugu', btn: true },
];
