import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import formatDate from '../../../../../helpers/formatDate';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import UpdateForm from '../../../../FormComp/UpdateForm';
import TableComp from '../../../../TableComp/TableComp';
import { membershipStatusRules } from './membershipStatusRules';
import useFetch from '../../../../../hooks/useFetch';
import {
    addMembershipType,
    deleteMembershipType,
} from '../../../../../services/members/membershipType';
import { getPayment } from '../../../../../services/members/payment';
import { useParams } from 'react-router-dom';
import { useMembersStore } from '../../../../Store/Store';
import DeleteForever from '@mui/icons-material/DeleteForever';

const MembershipStatusForm = ({ currentMember, filterMember }) => {
    const { id, vrsta_clanstva_povijest } = currentMember;
    const [{ access }] = useLocalStorage('tokens');
    const { member_id } = useParams();

    const [expanded, setExpanded] = useState(false);

    const { doFetch } = useFetch();
    const { doFetch: deleteTypeFetch } = useFetch({ message: { showErrMsg: true } });

    const setMemberPayment = useMembersStore(state => state.setMemberPayment);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleAddMembershipStatus = ({ pocetak_clanstva, vrsta_clanstva }) => {
        doFetch([
            {
                func: addMembershipType,
                info: [
                    access,
                    {
                        clan: id,
                        vrsta_clanstva,
                        pocetak_clanstva: formatDate(pocetak_clanstva),
                    },
                ],
            },
            { func: getPayment, info: [access, member_id] },
            { func: data => setMemberPayment(data) },
            { func: filterMember },
        ]);
    };

    const btnsArr = [
        {
            name: 'delete',
            onClick: ({ id }) =>
                deleteTypeFetch([
                    { func: deleteMembershipType, info: [access, id, currentMember.id] },
                    { func: getPayment, info: [access, member_id] },
                    { func: data => setMemberPayment(data) },
                    { func: filterMember },
                ]),
            icon: <DeleteForever />,
            disabled: vrsta_clanstva_povijest.length === 1,
        },
    ];

    return currentMember['status(Aktivan/Neaktivan)'] === 'Aktivan' ? (
        vrsta_clanstva_povijest.length !== 0 ? (
            <>
                <Accordion
                    style={{ marginBottom: '2rem' }}
                    expanded={expanded === 'vrsta_clanstva'}
                    onChange={handleChange('vrsta_clanstva')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                    >
                        <Typography variant='body1'>Promijeni vrstu članstva</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UpdateForm
                            updateInputs={membershipStatusRules}
                            onSubmit={handleAddMembershipStatus}
                            btnMessage='Promijeni'
                            styleContainer='updateForm__container updateForm__container-2c'
                            clearFields
                        />
                    </AccordionDetails>
                </Accordion>
                <TableComp
                    maxTableHeight='24vh'
                    head={[
                        { id: 'vrsta_clanstva', label: 'Vrsta članstva', type: 'string' },
                        {
                            id: 'pocetak_clanstva',
                            label: 'Početak članstva',
                            type: 'date',
                        },
                        { id: 'delete', btn: true },
                    ]}
                    body={vrsta_clanstva_povijest}
                    btns
                    btnsArr={btnsArr}
                    activeRow={{
                        func: () => {
                            let today = new Date();
                            let item = {};

                            vrsta_clanstva_povijest.forEach(f => {
                                let date = new Date(f.pocetak_clanstva);

                                if (date <= today) {
                                    if (Object.keys(item).length === 0) {
                                        item = f;
                                    } else if (new Date(item?.pocetak_clanstva) <= date) {
                                        item = f;
                                    }
                                }
                            });

                            return item.id;
                        },
                        id: 'id',
                    }}
                />
            </>
        ) : (
            <UpdateForm
                updateInputs={membershipStatusRules}
                onSubmit={handleAddMembershipStatus}
                btnMessage='Promijeni'
                styleContainer='updateForm__container updateForm__container-2c'
                clearFields
            />
        )
    ) : (
        <Typography my={3} variant='h6' align='center'>
            Član mora biti aktivan
        </Typography>
    );
};

export default MembershipStatusForm;
