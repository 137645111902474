import React from 'react';
import { Box, Button, TableCell, Tooltip } from '@mui/material';

const CellButton = ({ btnsArr, el, item }) => {
    return btnsArr?.map(
        ({
            name,
            tooltipTitle,
            tooltipOptions,
            onClick,
            disabled,
            icon,
            TooltipTitleComp,
            IconComp,
        }) => {
            return (
                name === el.id && (
                    <TableCell key={name} align='center'>
                        <Tooltip
                            title={
                                tooltipTitle ? (
                                    typeof tooltipTitle === 'string' ? (
                                        tooltipTitle
                                    ) : (
                                        <TooltipTitleComp {...item} />
                                    )
                                ) : (
                                    ''
                                )
                            }
                            {...tooltipOptions}
                        >
                            <Box component='span'>
                                <Button disabled={disabled} onClick={() => onClick(item)}>
                                    {icon || <IconComp {...item} />}
                                </Button>
                            </Box>
                        </Tooltip>
                    </TableCell>
                )
            );
        }
    );
};

export default CellButton;
