export const resetPassFinalRules = [
    {
        name: 'password',
        label: 'Šifra',
        type: 'password',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 128,
            },
            minLength: {
                value: 6,
                message: 'Šifra mora imati najmanje 6 znakova',
            },
        },
    },
    {
        name: 'passwordConfirm',
        label: 'Potvrdite šifru',
        type: 'password',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 128,
            },
            minLength: {
                value: 6,
                message: 'Šifra mora imati najmanje 6 znakova',
            },
        },
    },
];
