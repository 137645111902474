import { Button } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const LinkButton = ({ btnMessage, linkFuncGoBack, linkPath }) => {
    const history = useHistory();

    return (
        <>
            {linkFuncGoBack && (
                <Button color='error' variant='outlined' onClick={() => history.goBack()}>
                    {btnMessage}
                </Button>
            )}
            {linkPath && (
                <Button color='error' variant='outlined' component={Link} to={linkPath}>
                    {btnMessage}
                </Button>
            )}
        </>
    );
};

export default LinkButton;
