import React from 'react';
import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router-dom';
import { useMembersStore, useOrganizationStore } from '../../../Store/Store';
import TableComp from '../../../TableComp/TableComp';

const debtorNumber = arr => {
    switch (arr) {
        case 1:
            return `Najveći dužnik`;
        case 5:
            return `${arr} najvećih dužnika`;
        default:
            return `${arr} najveća dužnika`;
    }
};

const DebtorTable = ({ top5 }) => {
    const history = useHistory();

    const members = useMembersStore(state => state.members);
    const { id } = useOrganizationStore(state => state.currentOrganization);

    const btnsArr = [
        {
            name: 'edit',
            onClick: ({ name }) => {
                let member = members.find(m => {
                    return `${m.ime} ${m.prezime}` === name;
                });
                member?.id &&
                    history.push(
                        `/dashboard/organization/${id}/edit-member/${member.id}`,
                        { call: true }
                    );
            },
            icon: <EditIcon />,
        },
    ];

    return Array.isArray(top5) && top5.length !== 0 ? (
        <>
            <Typography align='center' color='primary' variant='h6' mt={2} mb={1}>
                {debtorNumber(top5.length)}
            </Typography>
            <TableComp
                body={top5}
                head={[
                    { id: 'name', label: 'Ime', type: 'string' },
                    { id: 'debt', label: 'Ukupan dug', type: 'string' },
                    {
                        id: 'unpaid',
                        label: 'Broj neplaćenih članarina',
                        type: 'string',
                    },
                    { id: 'edit', label: '', btn: true },
                ]}
                btnsArr={btnsArr}
                btns
            />
        </>
    ) : (
        <Typography align='center' color='primary' variant='h6' mt={2} mb={1}>
            Udruga nema dužnika
        </Typography>
    );
};

export default DebtorTable;
