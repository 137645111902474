export const addTransferFormRules = [
    {
        name: 'tekst',
        label: 'Tekstualni sadržaj obrasca za uplatu članarine',
        rules: {
            required: 'Ovo polje je obavezno',
            maxLength: {
                value: 2147483647,
            },
            minLength: {
                value: 0,
            },
        },
        defaultValue: 'tekst',
        multiline: true,
        rows: 10,
    },
];
