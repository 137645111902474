import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useUserStore } from '../Store/Store';

const ProtectedRoute = ({ children, ...rest }) => {
    const [token] = useLocalStorage('tokens');
    const userData = useUserStore(state => state.userData);

    const location = useLocation();

    if (token === '0-0') {
        return <Redirect to={{ pathname: '/login', state: { location: null } }} />;
    }

    return (
        <Route
            {...rest}
            render={() => {
                return userData?.id ? (
                    children
                ) : (
                    <Redirect to={{ pathname: '/login', state: { location } }} />
                );
            }}
        />
    );
};

export default ProtectedRoute;
