import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    addDefaultValues,
    createDefaultValues,
} from '../../../../../../helpers/editHelpers';
import formatDate from '../../../../../../helpers/formatDate';
import useFetch from '../../../../../../hooks/useFetch';
import useLocalStorage from '../../../../../../hooks/useLocalStorage';
import {
    editMedicalMember,
    getMedicalList,
} from '../../../../../../services/members/medical';
import UpdateForm from '../../../../../FormComp/UpdateForm';
import { useMembersStore } from '../../../../../Store/Store';
import { editFormRules } from './editFormRules';

const EditForm = ({ currentRow }) => {
    const { member_id } = useParams();

    const [editFields, setEditFields] = useState([]);
    const [defaultValues, setDefaultValues] = useState(false);

    const { doFetch } = useFetch();
    const [{ access }] = useLocalStorage('tokens');

    const setMemberMedical = useMembersStore(state => state.setMemberMedical);

    const handleEditMedical = data => {
        const { datum_pregleda, vrijedi_do, status_pregleda } = data;
        doFetch([
            {
                func: editMedicalMember,
                info: [
                    access,
                    currentRow.id,
                    {
                        datum_pregleda: formatDate(datum_pregleda),
                        vrijedi_do: formatDate(vrijedi_do),
                        status_pregleda: status_pregleda !== '' && status_pregleda,
                    },
                ],
            },
            { func: getMedicalList, info: [access, member_id], id: 'medical' },
            { func: data => setMemberMedical(data) },
        ]);
    };

    useEffect(() => {
        setEditFields(addDefaultValues(editFormRules, currentRow));
    }, [currentRow]);

    useEffect(() => {
        setDefaultValues(createDefaultValues(editFields));
    }, [editFields]);

    return (
        editFields.length !== 0 && (
            <UpdateForm
                updateInputs={editFields}
                onSubmit={handleEditMedical}
                defaultValues={defaultValues}
                updateDisable={true}
                btnMessage='Promijeni'
                styleContainer='updateForm__container updateForm__container-3c'
            />
        )
    );
};

export default EditForm;
