import React from 'react';
import formatDate from '../../../../../helpers/formatDate';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import UpdateForm from '../../../../FormComp/UpdateForm';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import { removeStatus } from '../../../../../services/members/status';

const addStatusRoles = [
    {
        name: 'clan_od',
        label: 'Kraj statusa',
        input: 'date',
    },
];

const Deactivate = ({ filterMember, handleGetMemberStatus }) => {
    const { member_id } = useParams();
    const [{ access }] = useLocalStorage('tokens');
    const { doFetch } = useFetch({ message: { showErrMsg: true } });

    const handeEditStatus = ({ clan_od }) => {
        doFetch([
            {
                func: removeStatus,
                info: [
                    access,
                    {
                        clan_od: formatDate(clan_od),
                        clan: +member_id,
                    },
                ],
                id: 'remove',
            },
            { func: filterMember },
            { func: handleGetMemberStatus },
        ]);
    };

    return (
        <UpdateForm
            updateInputs={addStatusRoles}
            onSubmit={handeEditStatus}
            btnMessage='Promijeni'
            clearFields
            styleContainer='updateForm__container updateForm__container-1c'
        />
    );
};

export default Deactivate;
